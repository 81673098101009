import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as SiteUsersListStore from '../store/SiteUsersList';
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type ListProps =
    SiteUsersListStore.SiteUsersListState // ... state we've requested from the Redux store
    & typeof SiteUsersListStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ Active: string }>; // ... plus incoming routing parameters


class ListData extends React.PureComponent<ListProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {

        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    
                    <div className="card-body">

                        {this.renderTable()}
                        {this.renderPagination()}
                     
                    </div>
                </div>

            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        const startDateIndex = parseInt(this.props.match.params.Active, 10) || 0;
        this.props.requestLists(startDateIndex);
    }
    
    private renderTable() {

      const  columns = [
            {
                name: "price",
                label: "Price",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip title="Site User View">
                                <Link className='btn btn-icon btn-outline-success btn-circle btn-sm mr-2' to={{
                                    pathname: '/SiteUsers_View',
                                    search: `?ID=${this.props.SiteUsersList[tableMeta.rowIndex].UserID}&r=ul`,
                                }}> <VisibilityIcon /> </Link>
                            </Tooltip>
                            
                        )

                    },

                },
            },
            {
                name: "Fullname",
                label: "Full Name",
                options: {
                    filter: true,
                    sort: true,
                },

            }, {
                name: "SiteCount",
                label: "Site Count",
                options: {
                    filter: true,
                    sort: true,
                },

            },


        ];


        const options = {
            filterType: 'checkbox' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this != undefined && this.props.isLoading == false) {
            return (
                
                <MUIDataTable
                    title={'Site Users List'}
                    data={this.props.SiteUsersList}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {

        return (
            <div className="">

                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}

            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.siteUsersList, // Selects which state properties are merged into the component's props
    SiteUsersListStore.actionCreators // Selects which action creators are merged into the component's props
)(ListData as any);
