
import React, { useState} from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'
import AgentList from './AgentList'



function AgentListModalPopUp(props) {
   
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button className="btn btn-primary mr-2" onClick={handleShow}>
                Agent
                </Button>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
              
                <Modal.Body className="p-0">
                    <AgentList />

                </Modal.Body>
                <Modal.Footer className="p-2">
                    <Button variant="secondary" className="closepopupbtn" onClick={handleClose}>
                        Close
                    </Button>
                  
                </Modal.Footer>
            </Modal>
        </>
    );


}

export default AgentListModalPopUp;
