import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as XrefTypesSetDefaultStore from '../store/XrefTypesSetDefault';
import * as QueryString from "query-string"
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import { authHeader } from '../components/AuthHeader';
import Backdrop from '@material-ui/core/Backdrop';
import { ApiUrl } from "../components/Constant";
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type XrefTypesSetDefaultProps =
    XrefTypesSetDefaultStore.XrefTypesSetDefaultState // ... state we've requested from the Redux store
    & typeof XrefTypesSetDefaultStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ x: string, xt: string, n: string, ReturnUrl: string }>; // ... plus incoming routing parameters


class XrefTypesSetDefaultData extends React.PureComponent<XrefTypesSetDefaultProps> {
    constructor(props: Readonly<XrefTypesSetDefaultProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.props.XrefTypesSetDefault.siteOverrideList[event.target.name].intValue = event.target.value;
        this.props.XrefTypesSetDefault.siteOverrideList[event.target.name].state = "Modified";
    }
    handleSubmit(event: { preventDefault: () => void; }) {

        var data = this.props.XrefTypesSetDefault;
        data.sites_List = [];

        data.siteOverrideList = data.siteOverrideList.filter(i => i.state == 'Modified');
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: authHeader(JSON.stringify(data)) as string[][],
            body: JSON.stringify("ok")
        };
        fetch(ApiUrl+'xref/xref_Types_SetDefault_Post', requestOptions).catch(error => {
            console.error('Error during request:', error);
        });
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {

        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                        <h3 className='card-label'>
                            {this.props.XrefTypesSetDefault == null ? "" : this.props.XrefTypesSetDefault.pageHeader}

                        </h3>
                        </div>
                        <div className='card-toolbar'>
                            {this.renderButton()}
                    </div>
                    </div>
                    <div className="card-body">

                       
                        {this.renderTable()}
                        {this.renderPagination()}
                    </div>
                </div>
                <h1 id="tabelLabel"></h1>
                <p></p>

                
                
            </React.Fragment>
        );
    }

    private ensureDataFetched() {

        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined) {

            var x = parseInt(parsed.x == null ? "0" : parsed.x.toString()) || 0;
            var xt = parseInt(parsed.xt == null ? "0" : parsed.xt.toString()) || 0;
            var n = Boolean(parsed.n) || false;
            var ReturnUrl = parsed.ReturnUrl == null ? "" : parsed.ReturnUrl.toString();
            this.props.requestXrefTypesSetDefault(x, xt, n, ReturnUrl);
        }
    }
    private renderButton() {

        if (this != undefined && this.props.XrefTypesSetDefault != undefined) {

            return (
                <div>
                    <div className='' >
                        <button type="submit" onClick={this.handleSubmit} className='btn btn-success text-white mr-2'>
                            Save
                        </button>
                            <a className='btn btn-danger text-white' href={`/xref_Types_Items?x=${this.props.XrefTypesSetDefault.xrefID}&xt=${this.props.XrefTypesSetDefault.xrefTypeID}`}>Exit</a>
                    </div>
                   
                </div>
            );
        }
    }
   
    private renderTable() {
        const columns = [
            
            {
                name: "extValue",
                label: "External Value",

            },
            {
                name: "extName",
                label: "External Description",

            },

            {
                name: "",
                label: "Map to",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        //console.log(tableMeta)
                        return (

                            <select onChange={e => this.onInputchange(e)} name={tableMeta.rowIndex.toString()} value={this.props.XrefTypesSetDefault.siteOverrideList[tableMeta.rowIndex].intValue}>
                                        {this.props.XrefTypesSetDefault.sites_List.map((e, key) => {
                                            return <option key={key} value={e.Value}>{e.ValueDesc}</option>;
                                        })}
                                    </select>

                        )
                    }
                }

            },

         

        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        
        if (this != undefined && this.props.XrefTypesSetDefault != undefined && this.props.isLoading == false) {

            return (
                
                <MUIDataTable
                    title={this.props.XrefTypesSetDefault == null ? "" : this.props.XrefTypesSetDefault.pageHeader}
                    data={this.props.XrefTypesSetDefault.siteOverrideList}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {

        return (
            <div className="">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.xrefTypesSetDefault,
    // Selects which state properties are merged into the component's props
    XrefTypesSetDefaultStore.actionCreators // Selects which action creators are merged into the component's props
)(XrefTypesSetDefaultData as any);
