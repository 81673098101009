import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as PricemassUpdateListStore from '../store/CollectorPriceMassUpdateData';
import $ from 'jquery';
import MUIDataTable from "../MUI";
import 'pc-bootstrap4-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import { compareAsc, format } from 'date-fns';
import Backdrop from '@material-ui/core/Backdrop';
import { authHeader } from '../components/AuthHeader';
import { ApiUrl } from "../components/Constant";
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type ListProps =
    PricemassUpdateListStore.PricemassUpdateListState // ... state we've requested from the Redux store
    & typeof PricemassUpdateListStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ Active: string }>; // ... plus incoming routing parameters


class ListData extends React.PureComponent<ListProps> {

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }
    constructor(props: Readonly<ListProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();

    }

    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });

        if (event.target.placeholder == "cashPrice") {
            this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select[event.target.name].cashPrice = event.target.value
        } else if (event.target.placeholder == "cImmediate") {
            this.props.PriceMassUpdate.immediate = event.target.checked
        }
        else if (event.target.placeholder == "effectiveDate") {
            this.props.PriceMassUpdate.effectiveDate = event.target.value
        }
        else {
            this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select[event.target.name].creditPrice = event.target.value
        }
        $(event.target).focusout(function () {
            $(this).addClass('text-danger');
        });
        this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select[event.target.name].state = "Modified";
    }
    handleSubmit(event: { preventDefault: () => void; }) {
       
        var data1 = this.props.PriceMassUpdate;
        var data = this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select.filter(i => i.state == 'Modified');
        data1.fuelPrices_PriceMassUpdate_Select = data;
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            
            headers: authHeader(JSON.stringify(data1)) as string[][]
        };
       
        fetch(ApiUrl +'collector/Price_MassUpdate?isvalid=1&value=1', requestOptions)
            .then(response => response)
            .then(

                data => {
                   
                    if (data.status == 401)
                    {
                        $("#btnLogout").click();
                        return;
                    } 

                    if (data.ok === true) {
                        $('.price').removeClass('text-danger');
                    }
                }).catch(error => {
                    console.error('Error during request:', error);
                });
    }


    public render() {
        ($('.datepicker') as any).datetimepicker({

            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'far fa-trash-alt',
                close: 'far fa-times-circle'
            },
        });
        if (this.props.PriceMassUpdate != undefined) {


            return (

                <React.Fragment>
                    <div className='card card-custom gutter-b'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3 className='card-label'>
                                    Price Mass Update

                                </h3>
                            </div>
                            <div className='card-toolbar'>
                                <button type="submit" className='btn btn-primary' onClick={this.handleSubmit}>Save</button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='row mb-3'>
                                <div className='col-md-3'>
                                    <div className='d-flex'>
                                        <div className="form-group mr-4 mb-3">
                                            <label>Effective Date:</label>
                                            <input type="text" placeholder="effectiveDate" defaultValue={this.props.PriceMassUpdate.effectiveDate} onChange={e => this.onInputchange(e)} className='datepicker w-100 form-control' />

                                        </div>
                                        <label className='checkbox mt-5'>
                                            <input type='checkbox' placeholder="cImmediate" defaultChecked={true} onChange={e => this.onInputchange(e)} />
                                            <span className='mr-3'></span>

                                       Immediate
                                   </label>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="form-group">

                                    </div>
                                </div>
                                <div className='col-md-3 align-self-end'>
                                    <div className="form-group">

                                    </div>
                                </div>
                            </div>
                            {this.renderTable()}
                            {this.renderPagination()}
                        </div>
                    </div>
                </React.Fragment>

            );
        }
    }

    private ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.Active, 10) || 0;
        this.props.requestLists(0);
    }

    private renderTable() {
        const columns = [
            {
                name: "siteNumber",
                label: "Store",
                options: {
                    filter: true,
                    sort: true,
                },

            },
            {
                name: "productNumber",
                label: "ID",
                options: {
                    filter: true,
                    sort: true,
                },

            }, {
                name: "productName",
                label: "Item",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "dteLastPriceChange",
                label: "Last Price Change",
                options: {
                    filter: true,
                    sort: false,
                    //customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    //    return (
                    //        <label>
                    //            {this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select[tableMeta.rowIndex].dteLastPriceChange == undefined ? "" : format(new Date(this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select[tableMeta.rowIndex].dteLastPriceChange), 'MM/dd/yyyy')}
                    //        </label>

                    //    )
                    //}
                }
            },
            {
                name: "cashPrice",
                label: "Price",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <input type="text" placeholder="cashPrice" name={tableMeta.rowIndex.toString()} defaultValue={this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select[tableMeta.rowIndex].cashPrice} onChange={e => this.onInputchange(e)} className="form-control price w-25" />

                        )
                    }
                }
            },

            {
                name: "cashPriceVar",
                label: "Var",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "creditPrice",
                label: "Credit",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <input type="text" placeholder="creditPrice" name={tableMeta.rowIndex.toString()} defaultValue={this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select[tableMeta.rowIndex].creditPrice} onChange={e => this.onInputchange(e)} className="form-control price" />

                        )
                    }
                }
            },
            {
                name: "creditPriceVar",
                label: "Credit Var",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "fuelType",
                label: "Export Type",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "dteBatchEnd",
                label: "Exported",
                options: {
                    filter: true,
                    sort: false,
                    //customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    //    return (
                    //        <label>
                    //            {this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select[tableMeta.rowIndex].dteBatchEnd == undefined ? "" : format(new Date(this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select[tableMeta.rowIndex].dteBatchEnd), 'MM/dd/yyyy')}
                    //        </label>

                    //    )
                    //}
                }
            },
            
        ];


        const options = {
            filter: true,
            filterType: 'dropdown' as any,
            pagination: false,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),
            draggableColumns: {
                enabled: true,
            },
            
            grouping: { columnIndexes: [0] },//[0, 1,2, 10]

        };
        if (this != undefined && this.props.isLoading == false && this.props.PriceMassUpdate != undefined) {
            if (this != undefined && this.props.isLoading == false) {
                if (this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select.length > 0) {
                    return (

                        <MUIDataTable
                            title={""}
                            data={this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select}
                            columns={columns}
                            options={options}
                        />
                    );
                } else {
                    const optionsnew = {
                        filter: true,
                        fixedHeader: true,
                        filterType: 'dropdown' as any,
                        pagination: false,
                        selectableRows: 'none' as any,
                        setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),


                    };
                    return (
                        <>
                            <MUIDataTable
                                title={""}
                                data={this.props.PriceMassUpdate.fuelPrices_PriceMassUpdate_Select}
                                columns={columns}
                                options={optionsnew}
                            />

                        </>
                    );
                }


            }
            
        }
    }

    private renderPagination() {
        return (
            <div className="">

                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.collectorPriceUpdateList, // Selects which state properties are merged into the component's props
    PricemassUpdateListStore.actionCreators // Selects which action creators are merged into the component's props
)(ListData as any);
