import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PricemassUpdateListState {
    isLoading: boolean;
    startIndex?: number;
    PriceMassUpdate: PricemassUpdateItem;
}
export interface PricemassUpdateItem {
    immediate: boolean;
    effectiveDate: string;
    fuelPrices_PriceMassUpdate_Select: PricemassUpdateList[];

}

export interface PricemassUpdateList {
    siteID: string;
    siteNumber: string;
    productID: string;
    productNumber: string;
    productName: string;
    dteLastPriceChange: string;
    cashPrice: string;
    cashPriceVar: string;
    creditPrice: string;
    creditPriceVar: string;
    fuelType: string;
    dteBatchEnd: string;
    state: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestListsAction {
    type: 'REQUEST_Data';
    startIndex: number;
}

interface ReceiveListsAction {
    type: 'RECEIVE_Data';
    startIndex: number;
    PriceMassUpdate: PricemassUpdateItem;
}
interface UpdateStateAction {
    type: 'payload_Data';
    PriceMassUpdate: PricemassUpdateItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestListsAction | ReceiveListsAction ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    requestLists: (startIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
      
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.collectorPriceUpdateList && startIndex !== appState.collectorPriceUpdateList.startIndex) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`${ApiUrl}collector/pricemassupdate`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                        
                        dispatch({ type: 'RECEIVE_Data', startIndex: startIndex,PriceMassUpdate: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_Data', startIndex: startIndex });
        }
        
       
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PricemassUpdateListState = {
    PriceMassUpdate: { fuelPrices_PriceMassUpdate_Select: [], effectiveDate:"",immediate:false }, isLoading: false
};

export const reducer: Reducer<PricemassUpdateListState> = (state: PricemassUpdateListState | undefined, incomingAction: Action): PricemassUpdateListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    
    switch (action.type) {
        case 'REQUEST_Data':
            return {
                startIndex: action.startIndex,
                PriceMassUpdate: state.PriceMassUpdate,
                isLoading: true
            };
        
        case 'RECEIVE_Data':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.startIndex === state.startIndex) {
                return {
                    startIndex: action.startIndex,
                    PriceMassUpdate: action.PriceMassUpdate,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
