import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as SiteListsStore from '../store/SiteList';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import MUIDataTable from "mui-datatables";
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from '@material-ui/core/Backdrop';
import { compareAsc, format } from 'date-fns';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type SiteListProps =
    SiteListsStore.SiteListsState // ... state we've requested from the Redux store
    & typeof SiteListsStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ Active: string }>; // ... plus incoming routing parameters

class SiteListData extends React.PureComponent<SiteListProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        //this.componentWillUnmount()
        //this.ensureDataFetched();
    }
    constructor(props: Readonly<SiteListProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });

        this.props.requestSiteLists(event.target.value);
    }

    public render() {

        return (
            <div>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className='card-label'>
                                Site List by

                        </h3>
                        </div>
                        <div className="d-flex justify-content-center my-3">   <label className="radio mr-4"><input type="radio" name="Active" defaultChecked={(this.props.Active == -1 ? 1 : this.props.Active) == 1 ? true : false} value="1" onChange={e => this.onInputchange(e)} /> <span className="mr-2"></span>  Active</label>
                            <label className="radio"><input type="radio" name="Active" defaultChecked={this.props.Active == 0 ? true : false} value="0" onChange={e => this.onInputchange(e)} />   <span className="mr-2"></span>  InActive</label>
                        </div>
                        <div className='card-toolbar'>

                        </div>
                    </div>
                    <div className="card-body">
                     
                        {this.renderTable()}
                        {this.renderPagination()}

                    </div>
                </div>



            </div>
        );

    }

    private ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.Active, 10) || 0;
        this.props.requestSiteLists(1);
        
    }


    public componentWillUnmount() {

    }


    private renderTable() {
        const columns = [
            {
                name: " ",
                label: " ",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip title="Site View">
                                <Link className='btn btn-icon btn-outline-success btn-circle btn-sm mr-2' to={{
                                    pathname: '/site_View',
                                    search: `?ID=${this.props.sitelists[tableMeta.rowIndex].SiteID}&SiteNum=${this.props.sitelists[tableMeta.rowIndex].SiteNumber}`,
                                }}><EditIcon className="avoid-clicks" /></Link>
                            </Tooltip>

                        )
                    }
                }
            }, {
                name: "SiteID",
                label: "Site ID",
                options: {
                    filter: false,
                    sort: true,
                },

            }, {
                name: "SiteNumber",
                label: "Site Number",
                options: {
                    filter: false,
                    sort: true,
                },

            }, {
                name: "EmailList",
                label: "Email",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "dteLastCheckIn",
                label: "Last Check In",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <label>
                                {this.props.sitelists[tableMeta.rowIndex].dteLastCheckIn == undefined ? "" : format(new Date(this.props.sitelists[tableMeta.rowIndex].dteLastCheckIn), 'MM/dd/yyyy kk:mm:ss')}
                            </label>

                        )
                    }
                }
            },
            {
                name: "dteCreated",
                label: "Created",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <label>
                                {this.props.sitelists[tableMeta.rowIndex].dteCreated == undefined ? "" : format(new Date(this.props.sitelists[tableMeta.rowIndex].dteCreated), 'MM/dd/yyyy kk:mm:ss')}
                            </label>

                        )
                    }
                }
            },

            {
                name: "Active",
                label: "Active",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <label>
                                {this.props.sitelists[tableMeta.rowIndex].Active == true ? "True" : "False"}
                            </label>

                        )
                    }
                }
            },


        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };

        if (this != undefined && this.props.isLoading == false) {
            
            return (
                <MUIDataTable
                    title={""}
                    data={this.props.sitelists}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {
       
        return (
            <div className="">

                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
               
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.siteLists, // Selects which state properties are merged into the component's props
    SiteListsStore.actionCreators // Selects which action creators are merged into the component's props
)(SiteListData as any);
