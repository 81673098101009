import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as XrefTypesItemsSiteOverrideStore from '../store/XrefTypesItemsSiteOverride';
import * as QueryString from "query-string";
import { Button } from 'reactstrap';
import $ from 'jquery';
import { authHeader } from '../components/AuthHeader';
import MUIDataTable from "mui-datatables";
import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
import { ApiUrl } from "../components/Constant";

// At runtime, Redux will merge together...
type XrefTypesItemsSiteOverrideProps =
    XrefTypesItemsSiteOverrideStore.XrefTypeItemsSiteOverrideState // ... state we've requested from the Redux store
    & typeof XrefTypesItemsSiteOverrideStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ x: string, xt: string, ev: string }>; // ... plus incoming routing parameters


class XrefTypesItemsSiteOverrideData extends React.PureComponent<XrefTypesItemsSiteOverrideProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }
    //need to add/update/delete
    public render() {
        if (this != undefined && this.props.XrefTypeItemsSiteOverride != undefined) {
           
            return (
                <React.Fragment>
                    <div className='card card-custom gutter-b'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3 className='card-label'>
                                    {this.props.XrefTypeItemsSiteOverride.header}

                                </h3>
                            </div>
                            <div className='card-toolbar'>
                                <div className=''  >
                                    <button type="submit" onClick={this.handleSubmit} className='btn btn-primary text-white mr-2'>
                                        Save
                                </button>
                                    
                                    <a className='btn btn-danger text-white' href={`/xref_Types_Items?x=${this.props.XrefTypeItemsSiteOverride.xrefID}&xt=${this.props.XrefTypeItemsSiteOverride.xrefTypeID}`}>Exit</a>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">

                            {this.renderElement()}
                            {this.renderTable()}
                            {this.renderPagination()}

                        </div>
                    </div>

                </React.Fragment>
            );
        }
    }

    private ensureDataFetched() {
        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined) {

            const x = parseInt(parsed.x == null ? "0" : parsed.x.toString()) || 0;
            const xt = parseInt(parsed.xt == null ? "0" : parsed.xt.toString()) || 0;
            const ev = parsed.ev == null ? "0" : parsed.ev.toString();
            this.props.requestXrefTypeItemsSiteOverride(x, xt, ev);
        }
    }
    constructor(props: Readonly<XrefTypesItemsSiteOverrideProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.placeholder == "SiteID") {
            this.props.XrefTypeItemsSiteOverride.typeJson[event.target.name].siteID = event.target.value
        } else {
            this.props.XrefTypeItemsSiteOverride.typeJson[event.target.name].intValue = event.target.value
        }
        this.props.XrefTypeItemsSiteOverride.typeJson[event.target.name].state = "Modified";
    }
    handleSubmit(event: { preventDefault: () => void; }) {

        var data = this.props.XrefTypeItemsSiteOverride;
        data.sites_List = [];
        data.queryInternal = [];
        data.typeJson = data.typeJson.filter(i => i.state == 'Modified');
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            //headers: {
            //    Accept: 'application/json',
            //    'Content-Type': 'application/json',
            //    'data': JSON.stringify(data)
            //},
            headers: authHeader(JSON.stringify(data)) as string[][],
            body: JSON.stringify("ok")
        };
        fetch(ApiUrl+'xref/xref_types_Site_Override_Post', requestOptions).catch(error => {
            console.error('Error during request:', error);
        });
    }

    private renderElement() {

        if (this != undefined && this.props.XrefTypeItemsSiteOverride != undefined) {

            return (
                <div className='m-3'>
                    <h4 className='mb-3'>Summary</h4>
                    <ul className="list-group w-50">
                        <li className="list-group-item d-flex justify-content-between align-items-center">External Value<span className="">
                            {this.props.XrefTypeItemsSiteOverride.extValue}
                        </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">External Description<span className="">
                            {this.props.XrefTypeItemsSiteOverride.extName}
                        </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">Internal Value<span className="">
                            {this.props.XrefTypeItemsSiteOverride.intValue}
                        </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">Internal Description<span className="">
                            {this.props.XrefTypeItemsSiteOverride.agility_Desc}
                        </span>
                        </li>
                    </ul>

                    
                  
                    

                </div>
            );
        }
    }
    
    private renderTable() {
        const columns = [
  
            {
                name: "XrefName",
                label: "Site",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <select onChange={e => this.onInputchange(e)} placeholder="Site" name={tableMeta.rowIndex.toString()} value={this.props.XrefTypeItemsSiteOverride.typeJson[tableMeta.rowIndex].siteID}>
                                {this.props.XrefTypeItemsSiteOverride.queryInternal.map((e, key) => {
                                            return <option key={key} value={e.SiteID}>{e.Description}</option>;
                                        })}
                                    </select>
                        )
                    },

                }
            },{
                name: "XrefDesc",
                label: "Override Mapping To",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <select onChange={e => this.onInputchange(e)} placeholder="IntValue" name={tableMeta.rowIndex.toString()} value={this.props.XrefTypeItemsSiteOverride.typeJson[tableMeta.rowIndex].intValue}>
                                        {this.props.XrefTypeItemsSiteOverride.sites_List.map((e, key) => {
                                            return <option key={key} value={e.Value}>{e.ValueDesc}</option>;
                                        })}
                                    </select>
                        )
                },

                }
            },


        ];


        const options = {
            filterType: 'checkbox' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this.props.XrefTypeItemsSiteOverride != undefined && this.props.XrefTypeItemsSiteOverride.typeJson != undefined && this.props.isLoading == false) {

            return (
               
                <MUIDataTable
                    title={this.props.XrefTypeItemsSiteOverride.header}
                    data={this.props.XrefTypeItemsSiteOverride.typeJson}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {
      
        return (
            <div className="d-flex justify-content-between">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.xrefTypesItemsSiteOverride,
    // Selects which state properties are merged into the component's props
    XrefTypesItemsSiteOverrideStore.actionCreators // Selects which action creators are merged into the component's props
)(XrefTypesItemsSiteOverrideData as any);
