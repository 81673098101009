import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PriceHistoryState {
    isLoading: boolean;

    PriceHistory: PriceHistoryItem;
}
export interface PriceHistoryItem {
    start: string;
    end: string;
    confirmed: boolean;
    pending: boolean;
    jsonType: PriceHistory[];
}

export interface PriceHistory {
    FuelPriceID?: number;
    SiteID?: number;
    SiteNumber: string;
    CashPrice: string;
    LastCashPrice: string;
    CashPriceVar: string;
    CreditPrice: string;
    CreditPriceVar: string;
    dteCreated: string;
    ProductName: string;
    RequestName: string;
    ProductID?: number;
    RequestID?: number;
    ProductNumber?: number;
    Num?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestPriceHistoryAction {
    type: 'REQUEST_DATA';
    s: string;
    e: string;
    c: boolean;
    p: boolean;
}

interface ReceivePriceHistoryAction {
    type: 'RECEIVE_DATA';
    s: string;
    e: string;
    c: boolean;
    p: boolean;
    PriceHistory: PriceHistoryItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestPriceHistoryAction | ReceivePriceHistoryAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestPriceHistory: (c: boolean, s: string, e: string, p: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
       
        
        if (appState && appState.priceHistory && (c !== appState.priceHistory.PriceHistory.confirmed ||p !== appState.priceHistory.PriceHistory.pending || e !== appState.priceHistory.PriceHistory.end || s !== appState.priceHistory.PriceHistory.start)) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`${ApiUrl}collector/PriceHistory?start=${s}&end=${e}&confirmed=${c}&pending=${p}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                        if (data.jsonType == null) {
                            data.jsonType = [];
                        } else {

                            data.jsonType = JSON.parse(data.jsonType);
                        }
                        
                        dispatch({ type: 'RECEIVE_DATA', c: c, s: s, e: e, p: p, PriceHistory: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', c: c, s: s, e: e, p: p });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PriceHistoryState = { PriceHistory: { jsonType: [], confirmed: false, start: "", end: "", pending: false }, isLoading: false };

export const reducer: Reducer<PriceHistoryState> = (state: PriceHistoryState | undefined, incomingAction: Action): PriceHistoryState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    
    switch (action.type) {
        case 'REQUEST_DATA':
            return {

                PriceHistory: state.PriceHistory,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.PriceHistory !== state.PriceHistory) {
                
                return {

                    PriceHistory: action.PriceHistory,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
