import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SiteCompetitorAddEditState {
    isLoading: boolean;
    SID: number;
    ID: number;
    SiteNum: string;
    SiteCompetitorAddEdit: SiteCompetitorAddEditItem;
}
export interface SiteCompetitorAddEditItem {
    siteID: number;
    competitorID: number;
    siteNum: string;
    title: string;
    tCompetitorName: string;
    cActive: boolean;
    tAddress1: string;
    tAddress2: string;
    tSiteID: string;
    tCity: string;
    tState: string;
    tZip: string;
    rcbOurProduct: string;
    tCompProduct: string;
    cCompFollow: boolean;
    tAlertLow: string;
    tAlertHigh: string;
    site_Competitor_AvailableGrades_Select: ourProduct[];
    site_Competitor_Grades_Select: site_Competitor_Grades_Select[];
}
export interface ourProduct {
    ProductID?: number;
    ItemName: string;
}
export interface site_Competitor_Grades_Select {
    compGradeID?: number;
    productID?: number;
    our_Prod: string;
    comp_Prod_Name: string;
    compFollow: boolean;
    follow?: boolean;
    alertLow: string;
    alertMedium: string;
    alertHigh: string;
    state: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestSiteCompetitorAddEditAction {
    type: 'REQUEST_DATA';
    SID: number;
    ID: number;
    SiteNum: string;
}

interface ReceiveSiteCompetitorAddEditAction {
    type: 'RECEIVE_DATA';
    SID: number;
    ID: number;
    SiteNum: string;
    SiteCompetitorAddEdit: SiteCompetitorAddEditItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestSiteCompetitorAddEditAction | ReceiveSiteCompetitorAddEditAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestSiteCompetitorAddEdit: (SID: number, ID: number, SiteNum:string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.siteCompetitorAddEdit && (SID !== appState.siteCompetitorAddEdit.SID || ID !== appState.siteCompetitorAddEdit.ID || SiteNum !== appState.siteCompetitorAddEdit.SiteNum)) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`sites/site_Competitor_AddEdit?SID=${SID}&ID=${ID}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                        if (data.site_Competitor_AvailableGrades_Select == null) {
                            data.site_Competitor_AvailableGrades_Select = [];
                        } else {

                            data.site_Competitor_AvailableGrades_Select = JSON.parse(data.site_Competitor_AvailableGrades_Select);
                        }
                        dispatch({ type: 'RECEIVE_DATA', SID: SID, ID: ID, SiteNum: SiteNum, SiteCompetitorAddEdit: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', SID: SID, ID: ID, SiteNum: SiteNum });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SiteCompetitorAddEditState = { SiteCompetitorAddEdit: { tAddress1: "", cActive: false, cCompFollow: false, competitorID: -1, tCompProduct: "", rcbOurProduct: "", site_Competitor_AvailableGrades_Select: [], siteID: 0, site_Competitor_Grades_Select: [], title: "", siteNum: "", tAddress2: "", tAlertHigh: "", tAlertLow: "", tCity: "", tCompetitorName: "", tSiteID: "", tState:"",tZip:"" }, SID: -1, ID: -1, isLoading: false,SiteNum:"" };

export const reducer: Reducer<SiteCompetitorAddEditState> = (state: SiteCompetitorAddEditState | undefined, incomingAction: Action): SiteCompetitorAddEditState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                ID: action.ID,
                SiteNum: action.SiteNum,
                SID: action.SID,
                SiteCompetitorAddEdit: state.SiteCompetitorAddEdit,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.SID === state.SID && action.ID === state.ID) {
                return {
                    ID: action.ID,
                    SiteNum: action.SiteNum,
                    SID: action.SID,
                    SiteCompetitorAddEdit: action.SiteCompetitorAddEdit,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
