import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as SiteCompetitorAddEditStore from '../store/SiteCompetitorAddEdit';
import { Input } from 'reactstrap';
import * as QueryString from "query-string"
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import { authHeader } from '../components/AuthHeader';
import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type SiteCompetitorAddEditProps =
    SiteCompetitorAddEditStore.SiteCompetitorAddEditState // ... state we've requested from the Redux store
    & typeof SiteCompetitorAddEditStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ SID: string }>; // ... plus incoming routing parameters


class SiteCompetitorAddEditData extends React.PureComponent<SiteCompetitorAddEditProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {

        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
       // this.ensureDataFetched();
    }
    constructor(props: Readonly<SiteCompetitorAddEditProps>) {
        super(props);
       
        this.onInputchange = this.onInputchange.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleGradeAdd = this.handleGradeAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });
        switch (event.target.placeholder) {
            case "alertHigh":
                this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].alertHigh = event.target.value;
                if (this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].compGradeID as number > 0) {

                    this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].state = "Modified";
                }
                break;
            case "alertLow":
                this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].alertLow = event.target.value;
                if (this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].compGradeID as number > 0) {

                    this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].state = "Modified";
                }
                break;
            case "compFollow":
                this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].compFollow = event.target.value;
                if (this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].compGradeID as number > 0) {

                    this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].state = "Modified";
                }
                break;
            case "comp_Prod_Name":
                this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].comp_Prod_Name = event.target.value;
                if (this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].compGradeID as number > 0) {

                    this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].state = "Modified";
                }
                break;
            case "tCompetitorName":
                this.props.SiteCompetitorAddEdit.tCompetitorName = event.target.value;
                break;
            case "cActive":
                this.props.SiteCompetitorAddEdit.cActive = Boolean(event.target.value);
                break;
            case "tCompProduct":
                this.props.SiteCompetitorAddEdit.tCompProduct = event.target.value;
                break;
            case "cCompFollow":
                this.props.SiteCompetitorAddEdit.cCompFollow = event.target.value;
                break;
            case "rcbOurProduct":
                this.props.SiteCompetitorAddEdit.rcbOurProduct = event.target.value;
                break;
            case "tAddress1":
                this.props.SiteCompetitorAddEdit.tAddress1 = event.target.value;
                break;
            case "tAddress2":
                this.props.SiteCompetitorAddEdit.tAddress2 = event.target.value;
                break;
            case "tAlertHigh":
                this.props.SiteCompetitorAddEdit.tAlertHigh = event.target.value;
                break;
            case "tAlertLow":
                this.props.SiteCompetitorAddEdit.tAlertLow = event.target.value;
                break;
            case "tCity":
                this.props.SiteCompetitorAddEdit.tCity = event.target.value;
                break;
            case "tState":
                this.props.SiteCompetitorAddEdit.tState = event.target.value;
                break;
            case "tZip":
                this.props.SiteCompetitorAddEdit.tZip = event.target.value;
                break;

            default:
        }

    }
    handleDelete(event: any) {
        $(event).parent('tr').addClass('d-none')

        event.preventDefault();
        this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[event.target.name].state = "Deleted";

    }
    handleGradeAdd(event: { preventDefault: () => void; }) {
        let data = Object() as SiteCompetitorAddEditStore.site_Competitor_Grades_Select;

        data.alertHigh = $("#tAlertHigh").val() as string;
        data.alertLow = $("#tAlertLow").val() as string;
        data.comp_Prod_Name = $("#tCompProduct").val() as string;
        data.compFollow = $("#cCompFollow").is(":checked") as boolean;
        data.productID = $("#ddlProduct").val() as number;
        data.our_Prod = $("#ddlProduct option:selected").text() as string;
       
        data.state = "Added";
        this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select.push(data);

        alert('need to update list');
    }
    handleSubmit(event: { preventDefault: () => void; }) {

        var data = this.props.SiteCompetitorAddEdit;

        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            //headers: {
            //    Accept: 'application/json',
            //    'Content-Type': 'application/json',
            //    'data': JSON.stringify(data)
            //},
            headers: authHeader(JSON.stringify(data)) as string[][],
            body: JSON.stringify("ok")
        };
        fetch('sites/Site_Competitor_Update', requestOptions).catch(error => {
            console.error('Error during request:', error);
        });
    }
    public render() {

        return (
            <React.Fragment>
                <div className='card card-custom '>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 id="tabelLabel" className='card-label'>
                                Site : {this.props.SiteCompetitorAddEdit != undefined ? this.props.SiteCompetitorAddEdit.siteID : ""}
                            </h3>
                        </div>
                        <div className='card-toolbar'>
                            <button className='btn btn-primary mr-2' type="submit" onClick={this.handleSubmit} >Save</button>
                            <Link className='mr-2 btn btn-danger' to={{
                                pathname: '/site_View',
                                search: `?ID=${this.props.SiteCompetitorAddEdit != undefined ? this.props.SiteCompetitorAddEdit.siteID : ""}`
                            }}> Exit </Link>
                        </div>
                    </div>
                    <div className="card-body">

                        {this.renderSiteCompetitorAddEditElement()}

                        {this.renderSiteCompetitorAddEditTable()}
                        {/*this.renderPagination()*/}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        //debugger
        if (this.props.location === undefined) {
            this.props.requestSiteCompetitorAddEdit(1, 1,"");
        } else {
            const parsed = QueryString.parse(this.props.location.search)
            if (parsed != null && parsed != undefined) {

                var SiteNum = parsed.SiteNum == null ? "" : parsed.SiteNum.toString() || "";
                var ID = parseInt(parsed.ID == null ? "0" : parsed.ID.toString()) || 0;
                var SID = parseInt(parsed.SID == null ? "0" : parsed.SID.toString()) || 0;
                this.props.requestSiteCompetitorAddEdit(SID, ID, SiteNum);
            }
        }
    }
    private renderSiteCompetitorAddEditElement() {

        if (this != undefined && this.props.SiteCompetitorAddEdit != undefined) {

            return (
                <div>
                    <div className='row p-3'>
                        <div className='col-md-6'>
                            <div className='form-group row'>
                                <label className='col-lg-2 col-form-label text-right'>Competitor Name</label>
                                <div className='col-lg-9'>
                                    <input type="text" placeholder="tCompetitorName" defaultValue={this.props.SiteCompetitorAddEdit.tCompetitorName} onBlur={e => this.onInputchange(e)} className="form-control" />
                                </div>
                            </div>


                            <div className='form-group row'>
                                <label className='col-lg-2 col-form-label text-right'>Address 1</label>
                                <div className='col-lg-9'>
                                    <input type="text" placeholder="tAddress1" defaultValue={this.props.SiteCompetitorAddEdit.tAddress1} onBlur={e => this.onInputchange(e)} className="form-control" />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-lg-2 px-0 col-form-label text-right'>City, State, Zip</label>
                                <div className='col-lg-9'>
                                    <div className="row">
                                        <div className="col-lg-5">

                                            <input type="text" placeholder="tCity" defaultValue={this.props.SiteCompetitorAddEdit.tCity} onBlur={e => this.onInputchange(e)} className="form-control" />
                                        </div>

                                        <div className="col-lg-5">
                                            <input type="text" placeholder="tState" defaultValue={this.props.SiteCompetitorAddEdit.tState} onBlur={e => this.onInputchange(e)} className="form-control" />

                                        </div>
                                        <div className="col-lg-2">


                                            <input type="text" placeholder="tZip" defaultValue={this.props.SiteCompetitorAddEdit.tZip} onBlur={e => this.onInputchange(e)} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-6'>
                            <div className='form-group row'>
                                <label className='col-lg-2 col-form-label text-right'>Active</label>
                                <div className='col-lg-9 align-self-center'>
                                    <label className='checkbox'>
                                        <input type="checkbox" placeholder="cActive" defaultChecked={this.props.SiteCompetitorAddEdit.cActive} onChange={e => this.onInputchange(e)} />
                                        <span className='mr-3'></span>

                                    </label>
                                </div>

                            </div>
                            <div className='form-group row'>
                                <label className='col-lg-2 col-form-label text-right'>Address 2</label>
                                <div className='col-lg-9'>
                                    <input type="text" placeholder="tAddress2" defaultValue={this.props.SiteCompetitorAddEdit.tAddress2} onBlur={e => this.onInputchange(e)} className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='row p-3'>
                        <div className='col-md-12'>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className='form-group row'>
                                        <div className='col-lg-12'>
                                            <label className='col-form-label text-right'>Our Product</label>
                                            <select className="form-control" id="ddlProduct">
                                                {this.props.SiteCompetitorAddEdit.site_Competitor_AvailableGrades_Select.map((e, key) => {
                                                    return <option key={key} value={e.ProductID}>{e.ItemName}</option>;
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className='form-group row'>
                                        <div className='col-lg-12'>
                                            <label className='col-form-label text-right'>Comp Product</label>
                                            <input type="hidden" id="hdnCompGradeID" />
                                            <input type="text" id="tCompProduct" placeholder="tCompProduct" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1">
                                    <div className='form-group row'>
                                        <div className='col-lg-12'>
                                            <label className='col-form-label'>Follow</label><br />
                                            <input type="checkbox" id="cCompFollow" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className='form-group row'>
                                        <div className='col-lg-12'>
                                            <label className='col-form-label text-right'>Alert Low</label>
                                            <input type="text" className="form-control" placeholder="tAlertLow" id="tAlertLow" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className='form-group row'>
                                        <div className='col-lg-12'>
                                            <label className='col-form-label text-right'>Alert High</label>
                                            <input type="text" className="form-control" placeholder="tAlertHigh" id="tAlertHigh" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1">
                                    <div className='form-group row'>
                                        <div className='col-lg-12'>
                                            <label className='col-form-label text-right'><br /></label><br />
                                            <button className='btn btn-primary' type="submit" onClick={this.handleGradeAdd} >Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            );
        }
    }

    private renderSiteCompetitorAddEditTable() {



        const columns = [
            {
                name: " ",
                label: " ",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

                        return (
                            <button type="button" name={tableMeta.rowIndex.toString()} onClick={this.handleDelete} className='btn btn-danger text-white'>
                                Delete
                            </button>
                        )
                    }
                }
            }, {
                name: "our_Prod",
                label: "Our Prod",
                options: {
                    filter: true,
                    sort: true,
                }
            }, {
                name: "comp_Prod_Name",
                label: "Comp Prod Name",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

                        return (
                            <input type="text" placeholder="comp_Prod_Name" name={tableMeta.rowIndex.toString()} defaultValue={this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[tableMeta.rowIndex].comp_Prod_Name} onBlur={e => this.onInputchange(e)} className="form-control" />

                        )
                    }
                }

            },
            {
                name: "compFollow",
                label: "Comp Follow",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

                        return (
                            <input type="checkbox" placeholder="compFollow" name={tableMeta.rowIndex.toString()} checked={this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[tableMeta.rowIndex].compFollow} onBlur={e => this.onInputchange(e)} />

                        )
                    }
                }
            },
            {
                name: "alertLow",
                label: "Alert Low",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

                        return (
                            <input type="text" placeholder="alertLow" name={tableMeta.rowIndex.toString()} defaultValue={this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[tableMeta.rowIndex].alertLow} onBlur={e => this.onInputchange(e)} className="form-control" />

                        )
                    }
                }
            },
            {
                name: "alertHigh",
                label: "Alert High",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

                        return (
                            <input type="text" placeholder="alertHigh" name={tableMeta.rowIndex.toString()} defaultValue={this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select[tableMeta.rowIndex].alertHigh} onChange={e => this.onInputchange(e)} className="form-control" />

                        )
                    }
                }
            },

        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this != undefined && this.props.isLoading == false && this.props.SiteCompetitorAddEdit != undefined && this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select != undefined) {

            return (

                <MUIDataTable
                    title={""}
                    data={this.props.SiteCompetitorAddEdit.site_Competitor_Grades_Select.filter(i => i.state != "Deleted")}
                    columns={columns}
                    options={options}
                />
            );
        }

    }

    private renderPagination() {

        return (
            <div className="d-flex justify-content-between">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.siteCompetitorAddEdit,
    // Selects which state properties are merged into the component's props
    SiteCompetitorAddEditStore.actionCreators // Selects which action creators are merged into the component's props
)(SiteCompetitorAddEditData as any);
