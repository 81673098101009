import * as React from 'react';
import CompetitorPrices from './CompetitorPrices';
import SiteCompetitorPriceChk from './SiteCompetitorPriceChk';
import PriceChangeHistory from './PriceChangeHistory';
import 'pc-bootstrap4-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import "./dashboard.css";
//import $ from 'jquery';

class Dashboard extends React.PureComponent {
    //state = {
    //    reload: true,
    //}
    constructor(props: Readonly<{}>) {
        super(props);

        //let user = JSON.parse(localStorage.getItem('user') as string);

        //if (user && user.Token) {
        //    debugger
        //    if (user.Header == false) {
        //        this.setState({ reload: false });
        //        user.Header = true;
        //        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //        localStorage.setItem('user', JSON.stringify(user));
               
        //    }

        //}
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
       
    }
 
    // This method is called when the route parameters change
    public componentDidUpdate() {
        
    }

    
    public render() {
        
        //const { reload } = this.state;
        //if (reload==false) {
        //    debugger
        //    return <></>
        //}
        return (
            <>
               
            <React.Fragment>
                    <div className="row">
                        <div className="col-md-4 px-3 w-40here">
                        <SiteCompetitorPriceChk />
                        <div className="height100">
                            <PriceChangeHistory />
                        </div>

                        </div>
                        <div className="col-md-8 px-1 height100">
                            <CompetitorPrices />

                        </div>

                    </div>
            </React.Fragment>
                </>
        );
    }

}

export default (Dashboard as any);
