import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface XrefTypesSiteItemsState {
    isLoading: boolean;
    x: number;
    xt: number;
    ev: number;
    ReturnUrl: string;
    XrefTypesSiteItems: XrefTypesSiteItemsItem;
}
export interface XrefTypesSiteItemsItem {
    xrefID: number;
    xrefTypeID: number;
    pageHeader: string;
    returnUrl: string;
    siteID: number;
    siteDescription: string;
    xrefTypeName: string;
    typeJson: XrefTypesSiteItems[];
    sites_List: Xref_Site_QueryInternal_List[];
}
export interface Xref_Site_QueryInternal_List {
    Value: string;
    ValueDesc: string;
}

export interface XrefTypesSiteItems {
    intValue: string;
    extValue?: number;
    extName: string;
    state: string;
    xrefDetailID: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestXrefTypesSiteItemsAction {
    type: 'REQUEST_DATA';
    x: number;
    xt: number;
    ev: number;
    ReturnUrl: string;
}

interface ReceiveXrefTypesSiteItemsAction {
    type: 'RECEIVE_DATA';
    x: number;
    xt: number;
    ev: number;
    ReturnUrl: string;
    XrefTypesSiteItems: XrefTypesSiteItemsItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestXrefTypesSiteItemsAction | ReceiveXrefTypesSiteItemsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestXrefTypesSiteItems: (x: number, xt: number, ev: number, ReturnUrl: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.xrefTypesSiteItems && (x !== appState.xrefTypesSiteItems.x || xt !== appState.xrefTypesSiteItems.xt || ev !== appState.xrefTypesSiteItems.ev || ReturnUrl !== appState.xrefTypesSiteItems.ReturnUrl)) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`xref/xref_Types_SiteItems?x=${x}&xt=${xt}&ev=${ev}&ReturnUrl=${ReturnUrl}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(
                    data => {
                        if (data.sites_List == null) {
                            data.sites_List = [];
                        } else {

                            data.sites_List = JSON.parse(data.sites_List);
                        }
                        dispatch({ type: 'RECEIVE_DATA', x: x, xt: xt, ev: ev, ReturnUrl: ReturnUrl, XrefTypesSiteItems: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', x: x, xt: xt, ev: ev, ReturnUrl: ReturnUrl });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: XrefTypesSiteItemsState = { XrefTypesSiteItems: { xrefID: 0, xrefTypeID: 0, sites_List:[], returnUrl:"",xrefTypeName:"", typeJson: [], pageHeader: "", siteDescription: "", siteID: 0 }, isLoading: false, x: -1, xt: -1, ev: -1, ReturnUrl: "" };

export const reducer: Reducer<XrefTypesSiteItemsState> = (state: XrefTypesSiteItemsState | undefined, incomingAction: Action): XrefTypesSiteItemsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                x: action.x,
                xt: action.xt,
                ev: action.ev,
                ReturnUrl: action.ReturnUrl,
                XrefTypesSiteItems: state.XrefTypesSiteItems,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.x === state.x && action.xt === state.xt && action.ev === state.ev && action.ReturnUrl === state.ReturnUrl) {
                return {
                    x: action.x,
                    xt: action.xt,
                    ev: action.ev,
                    ReturnUrl: action.ReturnUrl,
                    XrefTypesSiteItems: action.XrefTypesSiteItems,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
