import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as PriceHistoryStore from '../store/PriceHistory';
import * as QueryString from "query-string"
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { compareAsc, format } from 'date-fns';
import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type PriceHistoryProps =
    PriceHistoryStore.PriceHistoryState // ... state we've requested from the Redux store
    & typeof PriceHistoryStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ confirmed: string, start: string, end: string, pending: string}>; // ... plus incoming routing parameters


class PriceHistoryData extends React.PureComponent<PriceHistoryProps> {
    constructor(props: Readonly<PriceHistoryProps>) {
        super(props);

        this.onchange = this.onchange.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.handleEvent = this.handleEvent.bind(this);

    }
    onchange(event: any) {
        
        this.setState({
            [event.target.name]: event.target.checked
        });
        if (event.target.name == "Confirmed") {
            
            this.props.requestPriceHistory(event.target.checked, this.props.PriceHistory.start, this.props.PriceHistory.end, this.props.PriceHistory.pending);
            this.props.PriceHistory.confirmed = event.target.checked;
        } else {
            this.props.requestPriceHistory(this.props.PriceHistory.confirmed, this.props.PriceHistory.start, this.props.PriceHistory.end, event.target.checked);
            this.props.PriceHistory.pending = event.target.checked;
        }
        
    }
    handleEvent(event: any, picker: any) {
       
        
    }
    handleCallback(start: any, end: any, label: any) {
        
        if (this.props != undefined) {
            this.props.requestPriceHistory(this.props.PriceHistory.confirmed, start._d.toLocaleDateString(), end._d.toLocaleDateString(), this.props.PriceHistory.pending);
            this.props.PriceHistory.start = start._d.toLocaleDateString();
            this.props.PriceHistory.end = end._d.toLocaleDateString();
            //console.log(start._d.toLocaleDateString(), end._d.toLocaleDateString());
        }
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {

        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        //this.ensureDataFetched();
    }

    public render() {
        if (this.props.PriceHistory != null) {
            
            let eDate = new Date().setDate(new Date().getDate() + 3)
            let eeDate = new Date(eDate).toLocaleDateString()
           

            return (
                <React.Fragment>
                    <div className='card card-custom gutter-b'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3 className='card-label'>
                                Price History
                            </h3>
                            </div>
                            <div className='row  w-75 justify-content-center'>
                                <div className='col-md-2 align-self-center'>
                                    <div className='form-group mb-0'>
                                        <DateRangePicker initialSettings={{ startDate: new Date().toLocaleDateString(), endDate: eeDate }} onEvent={this.handleEvent} onCallback={this.handleCallback}>
                                            <input type="text" className="form-control" />
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <div className='col-md-3 align-self-center'>
                                    <div className='d-flex mb-0'>
                                        <label className='checkbox mr-3'>
                                            <input type='checkbox' id="chkPending" name="Pending" onChange={e => this.onchange(e)} checked={this.props.PriceHistory.pending} />
                                            <span className="mr-2"></span>
                                            Pending
                                         </label>
                                        <label className='checkbox'>
                                            <input type='checkbox' id="chkConfirmed" name="Confirmed" onChange={e => this.onchange(e)} checked={this.props.PriceHistory.confirmed} />
                                            <span className="mr-2"></span>
                                            Confirmed
                                    </label>
                                    </div>
                                </div>

                            </div>
                            <div className='card-toolbar'>
                               
                            </div>
                        </div>
                        <div className="card-body">

                            
                             
                                {this.renderTable()}
                                {this.renderPagination()}



                        </div>
                    </div>

                </React.Fragment>
            );
        }
    }

    private ensureDataFetched() {
        let sDate = new Date();
        let eDate = new Date().setDate(new Date().getDate() + 3)
        let eeDate = new Date(eDate).toLocaleDateString()
        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined) {
            
            var confirmed = parsed.confirmed == null ? true : Boolean(parsed.confirmed.toString());
            var pending = parsed.pending == null ? true : Boolean(parsed.pending.toString());
            var start = parsed.start == null ? sDate.toLocaleDateString() : parsed.start.toString() || sDate.toLocaleDateString();
            var end = parsed.end == null ? eeDate : parsed.end.toString() || eeDate;
            this.props.requestPriceHistory(confirmed, start, end, pending);
        }
    }
    
    
    private renderTable() {
       const columns = [
            {
                name: "SiteNumber",
                label: "Site Number",
                options: {
                    filter: true,
                    sort: true,
                },

            }, {
                name: "ProductNumber",
                label: "Product Number",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "ProductName",
                label: "Product Name",
                options: {
                    filter: true,
                    sort: false,

                }
            },
            {
                name: "dteCreated",
                label: "Created",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <label>
                                {this.props.PriceHistory.jsonType[tableMeta.rowIndex].dteCreated == undefined ? "" : format(new Date(this.props.PriceHistory.jsonType[tableMeta.rowIndex].dteCreated), 'MM/dd/yyyy kk:mm')}
                            </label>

                        )
                    }
                }
            },
            {
                name: "CashPrice",
                label: "Price",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "CashPriceVar",
                label: "Var",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "CreditPrice",
                label: "Credit",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "CreditPriceVar",
                label: "Credit Var",
                options: {
                    filter: true,
                    sort: false,
                }
            },


        ];


     const   options = {
         filterType: 'checkbox' as any,
         selectableRows: 'none' as any,
         setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this.props.PriceHistory != undefined && this.props.PriceHistory.jsonType != undefined && this.props.isLoading == false) {
            return (
                <MUIDataTable
                    title={""}
                    data={this.props.PriceHistory.jsonType}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {
       

        return (
            <div className="">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.priceHistory,
    // Selects which state properties are merged into the component's props
    PriceHistoryStore.actionCreators // Selects which action creators are merged into the component's props
)(PriceHistoryData as any);
