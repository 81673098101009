import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as SiteViewStore from '../store/SiteView';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import * as QueryString from "query-string"
import { instanceOf } from "prop-types";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import MUIDataTable from "mui-datatables";
import EditIcon from '@material-ui/icons/Edit';
import Backdrop from '@material-ui/core/Backdrop';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { compareAsc, format } from 'date-fns';
import { authHeader } from '../components/AuthHeader';
//import SiteCompetitorAddEditPopup from './SiteCompetitorAddEditPopup';
import Crazytags from './Crazytags'
import { ApiUrl } from "../components/Constant";
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
//@ts-ignore
import Tags from "@yaireo/tagify/dist/react.tagify"
import "@yaireo/tagify/dist/tagify.css"
import Tooltip from '@material-ui/core/Tooltip';
import RemoveIcon from '@material-ui/icons/Remove';

// At runtime, Redux will merge together...
type ListProps =
    SiteViewStore.SiteViewState // ... state we've requested from the Redux store
    & typeof SiteViewStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ startIndex: string }>; // ... plus incoming routing parameters


class ListData extends React.PureComponent<ListProps> {

    constructor(props: Readonly<ListProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.onProductChange = this.onProductChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddUser = this.handleAddUser.bind(this);
        this.handleAddNotes = this.handleAddNotes.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onUserActivate = this.onUserActivate.bind(this);
        this.handleProductDone = this.handleProductDone.bind(this);
        this.handleNotesEdit = this.handleNotesEdit.bind(this);
        this.onCompetitorPriceChange = this.onCompetitorPriceChange.bind(this);
        this.onAddPriceCheck = this.onAddPriceCheck.bind(this);
        this.onAddCompetitor = this.onAddCompetitor.bind(this);

        this.state = {
            inputvalues: [],
            redirectPriceCheck: false,
            redirectAddCompetitor: false,
            TabName: localStorage.getItem("Tabname") || 'Alerts',
        }
    }

    state = {
        inputvalues: [],
        redirectPriceCheck: false,
        redirectAddCompetitor: false,
        TabName: localStorage.getItem("Tabname") || 'Alerts',
    }
    onAddPriceCheck(event: any) {


        confirmAlert({
            title: 'Confirm to Save changes',
            message: 'Please make sure you have saved the changes?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ redirectPriceCheck: true });

                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('no')
                }
            ]
        });


    }
    onAddCompetitor(event: any) {


        confirmAlert({
            title: 'Confirm to Save changes',
            message: 'Please make sure you have saved the changes?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ redirectAddCompetitor: true });

                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('no')
                }
            ]
        });


    }
    onCompetitorPriceChange(event: any) {
        this.setState({
            [event.target.name]: event.target.checked
        });
        const requestOptions = {
            method: 'GET',
            headers: authHeader('ok') as string[][]
        };
        fetch(ApiUrl+'sites/FilterSite_Competitors_Grades_TT?siteID=' + this.props.SiteView.siteID + '&IsActive=' + event.target.value, requestOptions)
            .then(response => response)
            .then(

                data => {
                    if (data.status == 401) {
                        $("#btnLogout").click(); return;
                    }
                    if (data.ok) {

                    }
                }).catch(error => {
                    console.error('Error during request:', error);
                });


    }

    onUserActivate(event: any) {
        var index = event.currentTarget.accessKey as number;
        let acData = this.props.SiteView.site_Users_AllUsers_Select[index] as SiteViewStore.SiteUsersAllUsersSelectModel;

        confirmAlert({
            title: acData.active == true ? 'Confirm to activate' : 'Confirm to deactivate',
            message: acData.active == true ? 'Are you sure you wish to activate the user for this site/responsibility?' : 'Are you sure you wish to deactivate the user for this site/responsibility?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const requestOptions = {
                            method: 'GET',
                            headers: authHeader('ok') as string[][]
                        };
                        fetch(ApiUrl+'sites/Site_Users_ActivateDeactive_Operation?SiteUserID=' + acData.siteUserID, requestOptions)
                            .then(response => response)
                            .then(

                                data => {
                                    if (data.status == 401) {
                                        $("#btnLogout").click(); return;
                                    }
                                    if (data.ok === true) {
                                        this.setState({
                                            sites_SiteUser_Sites_Select: this.props.SiteView.site_Users_AllUsers_Select.splice(index, 1),
                                        })
                                    }
                                }).catch(error => {
                                    console.error('Error during request:', error);
                                });
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('no')
                }
            ]
        });


    }
    onProductChange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });

        if (event.target.placeholder == "extProductID") {
            this.props.SiteView.site_Products_Select[event.target.name].extProductID = event.target.value;
        } else {
            this.props.SiteView.site_Products_Select[event.target.name].active = event.target.checked;
        }

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });
        switch (event.target.name) {

            case "tSiteID":
                this.props.SiteView.tSiteID = event.target.value;
                break;
            case "tSiteNumber":
                this.props.SiteView.tSiteNumber = event.target.value;
                break;
            case "racbEmail":
                this.props.SiteView.email = event.target.value;
                break;
            case "cActive":
                this.props.SiteView.cActive = event.target.checked;
                break;
            case "tSiteName":
                this.props.SiteView.tSiteName = event.target.value;
                break;
            case "tAddress1":
                this.props.SiteView.tAddress1 = event.target.value;
                break;
            case "tAddress2":
                this.props.SiteView.tAddress2 = event.target.value;
                break;
            case "racbFpAlertLow":
                this.props.SiteView.fpAlertLow = event.target.value;
                break;
            case "racbFpAlertMedium":
                this.props.SiteView.fpAlertMedium = event.target.value;
                break;
            case "racbFpAlertHigh":
                this.props.SiteView.fpAlertHigh = event.target.value;
                break;
            case "tCity":
                this.props.SiteView.tCity = event.target.value;
                break;
            case "tState":
                this.props.SiteView.tState = event.target.value;
                break;
            case "tZip":
                this.props.SiteView.tZip = event.target.value;
                break;

            default:
        }

    }
    handleDelete(event: any) {


        event.preventDefault();
        //this.props.SiteView.site_Competitor_Grades_Select[event.target.name].state = "Deleted";

    }
    handleEdit(event: { preventDefault: () => void; }) {


        event.preventDefault();
        //this.props.SiteView.site_Competitor_Grades_Select[event.target.name].state = "Deleted";

    }
    handleNotesEdit(event: any) {


        event.preventDefault();
        var data = this.props.SiteView.site_Note_Select[event.currentTarget.getAttribute('name')];
        $("#txtNotes").val(data.note);
        $("#hdnNoteID").val(data.noteID);
    }
    handleProductEdit(event: any) {
        event.preventDefault();
        if (!$(event.target).hasClass('d-none')) {
            $(event.target).addClass('d-none');
            $(event.target).siblings().removeClass('d-none');
            $(event.target).parents('tr').find('.rowinputbox').removeAttr('disabled');
            $(event.target).parents('tr').find('.rowcheckbox').removeAttr('disabled');
        }
    }
    handleProductCancel(event: any) {
        event.preventDefault();
        if (!$(event.target).hasClass('d-none')) {
            $(event.target).addClass('d-none');
            $(event.target).siblings().removeClass('d-none');
            $(event.target).siblings('.rowcheckBtn').addClass('d-none');
            $(event.target).parents('tr').find('.rowinputbox').attr('disabled', 'disabled');
            $(event.target).parents('tr').find('.rowcheckbox').attr('disabled', 'disabled');
        }
    }
    handleProductDone(event: any) {
        event.preventDefault();

        var data = this.props.SiteView.site_Products_Select[event.currentTarget.getAttribute('name')];
        const requestOptions = {
            method: 'POST',
            //headers: {
            //    Accept: 'application/json',
            //    'Content-Type': 'application/json',
            //    'data': JSON.stringify(data)
            //},

            headers: authHeader(JSON.stringify(data)) as string[][],
            body: JSON.stringify(this.props.SiteView.siteID)
        };
        fetch(ApiUrl+'sites/Site_Product_Update', requestOptions)
            .then(response => response)
            .then(

                data => {
                    if (data.status == 401) {
                        $("#btnLogout").click(); return;
                    }
                    if (data.ok) {
                        if (!$(event.target).hasClass('d-none')) {
                            $(event.target).addClass('d-none');
                            $(event.target).siblings().removeClass('d-none');
                            $(event.target).siblings('.rowdelBtn').addClass('d-none');
                            $(event.target).parents('tr').find('.rowinputbox').attr('disabled', 'disabled');
                            $(event.target).parents('tr').find('.rowcheckbox').attr('disabled', 'disabled');
                        }
                    }
                }).catch(error => {
                    console.error('Error during request:', error);
                });


    }
    handleAddNotes(event: { preventDefault: () => void; }) {

        var notes = $("#txtNotes").val() as string;
        var id = $("#hdnNoteID").val() as number;
        event.preventDefault();
        const requestOptions = {
            method: 'GET',
            headers: authHeader('ok') as string[][]
        };
        fetch(ApiUrl+'sites/Site_Note_Insert_Update?SiteID=' + this.props.SiteView.siteID + '&NoteID=' + id + '&notes=' + notes, requestOptions)
            .then((response) => {

                if (response.status == 401) {
                    $("#btnLogout").click();
                    return false;
                }
                else if (!response.ok) {
                    alert(response.text);
                    return false;
                }
                else
                    return response.json();
            })
            .then(

                data => {
                    if (data.status == 401) { $("#btnLogout").click(); return; }
                    if (id > 0) {
                        //updat
                        var old = this.props.SiteView.site_Note_Select.filter(i => i.noteID == id)[0];

                        old.note = notes;
                    } else {
                        //push
                        this.props.SiteView.site_Note_Select.push(data)
                    }
                    //this.props.SiteView.site_Note_Select = data;
                    this.setState({
                        site_Note_Select: data,
                    })
                    $("#txtNotes").val('');
                    $("#hdnNoteID").val('');
                }).catch(error => {
                    console.error('Error during request:', error);
                });

    }
    handleAddUser(event: { preventDefault: () => void; }) {

        var ddlResponsibility = $("#ddlResponsibility").val();
        var ddlUser = $("#ddlUser").val() as number;
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: authHeader('ok') as string[][]
        };
        fetch(ApiUrl+'sites/Site_Users_User_Insert?SiteUserID=' + this.props.SiteView.siteID + '&UserID=' + ddlUser + '&ResponsibilityTypeID=' + ddlResponsibility, requestOptions)
            .then(response => response)
            .then(

                data => {
                    if (data.status == 401) { $("#btnLogout").click(); return; }
                    if (data.ok === true) {
                        let data2 = new Object as SiteViewStore.SiteUsersAllUsersSelectModel;
                        data2.type = $("#ddlResponsibility option:selected").text() as string;
                        data2.user_Name = $("#ddlUser option:selected").text() as string;

                        data2.siteUserID = this.props.SiteView.siteID;
                        data2.userID = ddlUser;
                        data2.active = true;

                        this.setState({
                            sites_SiteUser_Sites_Select: this.props.SiteView.site_Users_AllUsers_Select.push(data2),
                        })
                        $("#ddlResponsibility").val('');

                        $("#ddlUser").val('');
                    }
                }).catch(error => {
                    console.error('Error during request:', error);
                });

    }

    handleSubmit(event: { preventDefault: () => void; }) {

        var data = this.props.SiteView;
        data.racbEmail = [];
        data.racbFpAlertHigh = [];
        data.racbFpAlertLow = [];
        data.racbFpAlertMedium = [];
        data.site_Competitors_TT = [];
        data.site_CurrentPrices_Select = [];
        data.site_EmailCache_Select = [];
        data.site_Note_Select = [];
        data.site_Prices_Select = [];
        data.site_Products_Select = [];
        data.site_Users_AllUsers_Select = [];
        data.site_Users_Responsibility_List = [];

        data.users_List = [];
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            //headers: {
            //    Accept: 'application/json',
            //    'Content-Type': 'application/json',
            //    'data': JSON.stringify(data)
            //},
            headers: authHeader(JSON.stringify(data)) as string[][],
            body: JSON.stringify('')
        };
        fetch(ApiUrl+'sites/site_Update', requestOptions).then((response) => { if (response.status == 401) { $("#btnLogout").click(); return; } }).catch(error => {
            console.error('Error during request:', error);
        });

    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {

        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className='card-label'>
                                {this.props.SiteView != undefined ? this.props.SiteView.siteNum : ""}

                            </h3>
                        </div>
                        <div className='card-toolbar'>
                            <button className='btn btn-primary mr-2' onClick={this.handleSubmit}>Save</button>
                            <a href='/site-list' className='btn btn-danger mr-2'>Exit</a>
                           
                        </div>
                    </div>
                    <div className="card-body">
                        {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
                        {this.renderSiteViewElement()}
                    </div>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} className="mb-3 p-3">
                        <Paper className="p-3">
                            {this.RadTabs()}
                        </Paper>
                    </Grid>


                </Grid>
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined) {

            var ID = parseInt(parsed.ID == null ? "0" : parsed.ID.toString()) || 0;
            var SiteNum = parsed.SiteNum == null ? "" : parsed.SiteNum.toString() || "";
            this.props.requestLists(ID, SiteNum);
        }

    }
    selecttab(key: any) {
        //console.log(key)
        localStorage.setItem("Tabname", key);
        this.setState({
            TabName: key
        })
    }

    private RadTabs() {
        let useCookie = localStorage.getItem("Tabname") || "Alerts";
        
        if (this.props.SiteView != undefined) {

            return (

                <Tabs defaultActiveKey={useCookie} id="uncontrolled-tab-example" onSelect={(e) => this.selecttab(e)} className='mb-3'>
                    <Tab eventKey="Alerts" title="Alerts">
                        {this.AlerttabContent()}
                    </Tab>
                    <Tab eventKey="CurrentPrices" title="Current Prices">
                        {this.CurrentPricestabContent()}
                    </Tab>
                    <Tab eventKey="Competitors" title="Competitors" >
                        {this.CompetitorstabContent()}
                    </Tab>
                    <Tab eventKey="Notes" title="Notes" >
                        {this.NotestabContent()}
                    </Tab>
                    <Tab eventKey="Products" title="Products" >
                        {this.ProductstabContent()}
                    </Tab>
                    <Tab eventKey="Prices" title="Prices" >
                        {this.PricestabContent()}
                    </Tab>
                    <Tab eventKey="Users" title="Users" >
                        {this.UserstabContent()}
                    </Tab>
                </Tabs>

            )
        }

    }
    onChangetags = (event: any) => {
        event.persist()
        //console.log("tag CHANGED:", event.target.value)
        this.setState({
            [event.target.name]: event.target.value
        });
        switch (event.target.name) {

            case "fpemail":
                this.props.SiteView.email = event.target.value;
                break;
            case "fpAlertLow":
                this.props.SiteView.fpAlertLow = event.target.value;
                break;
            case "fpAlertMedium":
                this.props.SiteView.fpAlertMedium = event.target.value;
                break;
            case "fpAlertHigh":
                this.props.SiteView.fpAlertHigh = event.target.value;
                break;

            default:
        }

    }
    private AlerttabContent() {
        var MessageData = this.props.SiteView.racbEmail.map((item: { emailAddr: String }) => item.emailAddr)

        var lowdata = this.props.SiteView.racbEmail.map((item: { emailAddr: String }) => item.emailAddr)

        var MediumData = this.props.SiteView.racbEmail.map((item: { emailAddr: String }) => item.emailAddr)

        var highData = this.props.SiteView.racbEmail.map((item: { emailAddr: String }) => item.emailAddr)


        return (
            //<select className="form-control" name="racbEmail" onChange={e => this.onInputchange(e)}>
            //    {this.props.SiteView.racbEmail != undefined && this.props.SiteView.racbEmail.map((e, key) => {
            //        return <option key={key} value={e.emailAddr}>{e.emailAddr}</option>;
            //    })}
            //</select>
            //low
            //< select className = "form-control" name = "racbFpAlertLow" id = "racbFpAlertLow" onChange = { e => this.onInputchange(e) } >
            //{
            //    this.props.SiteView.racbFpAlertLow != undefined && this.props.SiteView.racbFpAlertLow.map((e, key) => {
            //        return <option key={key} value={e.emailAddr}>{e.emailAddr}</option>;
            //    })
            //}
            //                            </select >
            //meduim 
            //< select className = "form-control" id = "racbFpAlertMedium" name = "racbFpAlertMedium" onChange = { e => this.onInputchange(e) } >
            //{
            //    this.props.SiteView.racbFpAlertMedium != undefined && this.props.SiteView.racbFpAlertMedium.map((e, key) => {
            //        return <option key={key} value={e.emailAddr}>{e.emailAddr}</option>;
            //    })
            //}
            //                            </select >
            //high
            //< select className = "form-control" id = "racbFpAlertHigh" name = "racbFpAlertHigh" onChange = { e => this.onInputchange(e) } >
            //{
            //    this.props.SiteView.racbFpAlertHigh != undefined && this.props.SiteView.racbFpAlertHigh.map((e, key) => {
            //        return <option key={key} value={e.emailAddr}>{e.emailAddr}</option>;
            //    })
            //}
            //                            </select >




            <div className='mt-5 w-75'>

                <div className="bg-white rounded  mb-3" >
                    <div className="card card-custom card-border">

                        <div className="card-body py-5 bg-lightgrey">
                            <div>
                                <h4 className='mb-3'>General:</h4>
                                <div className="d-flex w-50">
                                    <div className="w-25 align-self-center mr-2 mb-0">
                                        <h5 className="text-right mb-0">Messages:</h5>
                                    </div>
                                    <div className="w-75">
                                        <Crazytags def={MessageData} setval={this.onChangetags} name="fpemail" value={this.props.SiteView.email} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded  mb-3">
                    <div className="card card-custom card-border">

                        <div className="card-body py-5 bg-lightgrey">
                            <div>
                                <h4 className='mb-3'>Compepetitor Pricing Alert:</h4>
                                <div className="d-flex w-50 mb-2">
                                    <div className="w-25 align-self-center mr-2 mb-0">
                                        <h5 className="text-right mb-0">Low:</h5>
                                    </div>
                                    <div className="w-75">
                                        <Crazytags def={lowdata} setval={this.onChangetags} name="fpAlertLow" value={this.props.SiteView.fpAlertLow} />
                                    </div>
                                </div>
                                <div className="d-flex w-50 mb-2">
                                    <div className="w-25 align-self-center mr-2 mb-0">
                                        <h5 className="text-right mb-0">Medium:</h5>
                                    </div>
                                    <div className="w-75">
                                        <Crazytags def={MediumData} setval={this.onChangetags} name="fpAlertMedium" value={this.props.SiteView.fpAlertMedium} />
                                    </div>
                                </div>
                                <div className="d-flex w-50 mb-2">
                                    <div className="w-25 align-self-center mr-2 mb-0">
                                        <h5 className="text-right mb-0">High:</h5>
                                    </div>
                                    <div className="w-75">
                                        <Crazytags def={highData} setval={this.onChangetags} name="fpAlertHigh" value={this.props.SiteView.fpAlertHigh} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>




            </div>

        )

    }
    private CurrentPricestabContent() {

        const columns = [
            {
                name: "ProductNumber",
                label: "Number",

            },
            {
                name: "Item_Name",
                label: "Name",

            },
            {
                name: "Date_Last_Price_Change",
                label: "Date",
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <label>
                            {format(new Date(value), 'MM/dd/yyyy kk:mm')}
                        </label>

                    )
                }
            },
            {
                name: "Cash_Price",
                label: "Cash",

            },
            {
                name: "Cash_Price_Var",
                label: "Chg Var",

            },
            {
                name: "Credit_Price",
                label: "Credit Price",

            },
            {
                name: "Credit_Price_Var",
                label: "Chg Var",

            },

        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })


        };


        return (

            <div className='mt-5'>


                <MUIDataTable
                    title={" "}
                    data={this.props.SiteView.site_CurrentPrices_Select}
                    columns={columns}
                    options={options}
                />

            </div>

        )

    }
    private NotestabContent() {

        const columns = [
            {
                name: "ImageButton",
                label: "Image Button",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

                        return (
                            <button type="button" name={tableMeta.rowIndex.toString()} onClick={this.handleNotesEdit} className='btn btn-icon btn-light-primary btn-square btn-sm'>
                                <EditIcon className="avoid-clicks" />
                            </button>
                        )
                    }
                }

            },
            {
                name: "name",
                label: "Name",

            },
            {
                name: "created",
                label: "Created",

            },
            {
                name: "note",
                label: "Note",

            },

        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };


        return (

            <div className='mt-5'>
                <Grid container spacing={3} className="mb-1">
                    <Grid item xs={12} className="mb-0 p-3">
                        <Paper className=" shadow-none pl-0 pr-3 py-3">
                            <Grid spacing={3} className="d-flex">
                                <Grid item xs={2} className="pr-3">
                                    <div className="form-group mb-0">
                                        <label>Note:</label>
                                        <input type='text' id="txtNotes" className="form-control" />
                                        <input type='hidden' id="hdnNoteID" />

                                    </div>
                                </Grid>


                                <Grid item xs={4} className="px-3 align-self-end">
                                    <div className="form-group mb-0">
                                        <button className='btn btn-primary' onClick={this.handleAddNotes}> Save</button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>


                </Grid>

                <MUIDataTable
                    title={" "}
                    data={this.props.SiteView.site_Note_Select}
                    columns={columns}
                    options={options}

                />

            </div>

        )

    }
    private ProductstabContent() {

        const columns = [
            {
                name: "",
                label: "",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        ////console.log(tableMeta)
                        return (

                            <div>
                                <button type="button" onClick={this.handleProductEdit} className='btn btn-icon btn-light-primary btn-square btn-sm mr-2 roweditBtn'>
                                    <EditIcon className="avoid-clicks" />
                                </button>
                                <button type="button" name={tableMeta.rowIndex.toString()} onClick={this.handleProductDone} className='btn btn-icon btn-light-success btn-square btn-sm mr-2 d-none rowcheckBtn'>
                                    <CheckIcon className="avoid-clicks" />
                                </button>
                                <button type="button" onClick={this.handleProductCancel} className='btn btn-icon btn-light-danger btn-square btn-sm mr-2 d-none rowdelBtn'>
                                    <ClearIcon className="avoid-clicks" />
                                </button>
                            </div>
                        )
                    }
                }
            },
            {
                name: "productNumber",
                label: "Product Number",

            },
            {
                name: "productName",
                label: " Product Name",

            },
            {
                name: "dteCreated",
                label: "Created",
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <label>
                            {value == undefined ? "" : format(new Date(value), 'MM/dd/yyyy kk:mm')}
                        </label>

                    )
                }
            },
            {
                name: "active",
                label: "Active",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <label className="checkbox">
                                <input type="checkbox" disabled placeholder="active" className='rowcheckbox' name={tableMeta.rowIndex.toString()} defaultChecked={value} onChange={e => this.onProductChange(e)} />
                                <span></span>

                            </label>

                        )
                    }
                }
            }
            ,
            {
                name: "extProductID",
                label: "External ID",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <input type="text" disabled placeholder="extProductID" name={tableMeta.rowIndex.toString()} defaultValue={value} onChange={e => this.onProductChange(e)} className="form-control w-25 rowinputbox" />

                        )
                    }
                }
            }

        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };



        return (

            <div className='mt-5'>


                <MUIDataTable
                    title={" "}
                    data={this.props.SiteView.site_Products_Select}
                    columns={columns}
                    options={options}
                />

            </div>

        )

    }
    private PricestabContent() {
        const columns = [

            {
                name: "ProductName",
                label: "Name",

            },
            {
                name: "ProductNumber",
                label: "Num",

            },
            {
                name: "dteEffectiveRequest",
                label: "Request Dt",
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                   
                    return (
                        <label>
                            {value == undefined ? "" : format(new Date(value), 'MM/dd/yyyy kk:mm')}
                        </label>

                    )
                }
            },
            {
                name: "dteEffectiveActual",
                label: "Effective Dt",
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <label>
                            {value == undefined ? "" : format(new Date(value), 'MM/dd/yyyy kk:mm')}
                        </label>

                    )
                }
            },

            {
                name: "CashPrice",
                label: "Cash",

            },
            {
                name: "CreditPrice",
                label: "Credit Price",

            }, {
                name: "Implemented",
                label: "Implemented",
                options: {

                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        ////console.log(tableMeta)
                        return (
                            <label className='checkbox'>
                                <input type='checkbox' disabled defaultChecked={value} />
                                <span className='mr-3'></span>
                                         Implemented
                            </label>
                        )
                    }
                }

            },

        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };

        return (



            <div className='mt-5'>


                <MUIDataTable
                    title={" "}
                    data={this.props.SiteView.site_Prices_Select}
                    columns={columns}
                    options={options}
                />


            </div>

        )

    }
    private UserstabContent() {

        const columns = [

            {
                name: "active",
                label: "Active",
                options: {

                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

                        return (
                            <Tooltip title={this.props.SiteView.site_Users_AllUsers_Select[tableMeta.rowIndex].active == true ? "Deactivate" : "Activate"}>
                                <button onClick={this.onUserActivate} itemID={tableMeta.rowIndex} accessKey={tableMeta.rowIndex} className='btn btn-icon btn-outline-success btn-circle btn-sm mr-2'>
                                    <RemoveIcon />
                                </button>
                            </Tooltip>

                        )
                    }
                }
            },
            {
                name: "num",
                label: "Num",

            },
            {
                name: "user_Name",
                label: "User Name",

            },
            {
                name: "type",
                label: "Type",

            }
        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };

        return (


            <div className='mt-5'>
                <Grid container spacing={3}>
                    <Grid item xs={12} className="mb-3 p-3">
                        <Paper className="pr-3 py-3 pl-1 shadow-none">
                            <Grid spacing={3} className="d-flex">
                                <Grid item xs={2} className="px-3">
                                    <div className="form-group">
                                        <label>User:</label>
                                        <select className="form-control" id="ddlUser" onChange={e => this.onInputchange(e)}>
                                            {this.props.SiteView.users_List != undefined && this.props.SiteView.users_List.map((e, key) => {
                                                return <option key={key} value={e.UserID}>{e.FullName}</option>;
                                            })}
                                        </select>
                                    </div>
                                </Grid>
                                <Grid item xs={2} className="px-3">
                                    <div className="form-group">
                                        <label>Responsibility:</label>
                                        <select className="form-control" id="ddlResponsibility" onChange={e => this.onInputchange(e)}>
                                            {this.props.SiteView.site_Users_Responsibility_List != undefined && this.props.SiteView.site_Users_Responsibility_List.map((e, key) => {
                                                return <option key={key} value={e.ResponsibilityTypeID}>{e.ResponsibilityType}</option>;
                                            })}
                                        </select>
                                    </div>
                                </Grid>

                                <Grid item xs={2} className="px-3 align-self-end">
                                    <div className="form-group">
                                        <button className='btn btn-primary' onClick={this.handleAddUser}> Add</button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>


                </Grid>

                <MUIDataTable
                    title={" "}
                    data={this.props.SiteView.site_Users_AllUsers_Select}
                    columns={columns}
                    options={options}
                />
            </div>

        )

    }
    private CompetitorstabContent() {
        const { redirectPriceCheck } = this.state;
        if (redirectPriceCheck) {
            return <Redirect to={`/site_Competitor_PriceCheck?r=Setup&SID=${this.props.SiteView.siteID}&SiteNum=${this.props.SiteView.siteNum}`} />;
        }
        const { redirectAddCompetitor } = this.state;
        if (redirectAddCompetitor) {
            return <Redirect to={`/site_Competitor_AddEdit?SID=${this.props.SiteView.siteID}&SiteNum=${this.props.SiteView.siteNum}`} />;
        }
        //<Link className='mr-2 btn btn-primary' to={{
        //    pathname: '/site_Competitor_AddEdit',
        //    search: `?SID=${this.props.SiteView.siteID}&SiteNum=${this.props.SiteView.siteNum}`
        //}}> Add Competitor </Link>
        //<Link className='mr-2 btn btn-primary' to={{
        //    pathname: '/site_Competitor_PriceCheck',
        //    search: `?r=Setup&SID=${this.props.SiteView.siteID}&SiteNum=${this.props.SiteView.siteNum}`
        //}}> Add Price Check </Link>
        //<SiteCompetitorAddEditPopup siteID={this.props.SiteView.siteID} siteNum={this.props.SiteView.siteNum} />
        return (
            <div className='mt-5'>
                <Grid container spacing={3}>
                    <Grid item xs={12} className="mb-3 p-3">
                        <Paper className="p-3 shadow-none">
                            <Grid spacing={3} className="d-flex">
                                <Grid item xs={1} className="px-3 align-self-center">
                                    <div className=" d-flex">
                                        <label className="radio mr-4">
                                            <input type="radio" onClick={this.onCompetitorPriceChange} className="" defaultChecked={true} value="1" name="Active" />
                                            <span className="mr-3"></span>
                                            Active
                                        </label>
                                        <label className="radio mr-6">
                                            <input type="radio" onClick={this.onCompetitorPriceChange} className="" value="0" name="Active" />
                                            <span className="mr-3"></span>
                                            All
                                        </label>

                                    </div>
                                </Grid>
                                <Grid item xs={4} className="px-3 align-self-center">
                                    <div className="form-group mb-0">
                                        <a href="#" onClick={this.onAddPriceCheck} className="mr-2 btn btn-primary">Add Price Check</a>
                                        <a href="#" onClick={this.onAddCompetitor} className="mr-2 btn btn-primary">Add Competitor</a>

                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>


                </Grid>

                <table className='table table-bordered datatble mt-3' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zip</th>
                            <th>Last_Used</th>

                        </tr>
                    </thead>
                    <tbody>
                        {this.props.SiteView.site_Competitors_TT != undefined && this.props.SiteView.site_Competitors_TT.map((item: SiteViewStore.SiteCompetitorsTTModel, i) =>
                            <tr>
                                <td colSpan={6}>
                                    <table className='table table-bordered datatble mt-3' aria-labelledby="tabelLabel">
                                        <tbody>
                                            <tr key={item.competitorID}>

                                                <td>{item.name}</td>
                                                <td>{item.address}</td>
                                                <td>{item.city}</td>
                                                <td>{item.state}</td>
                                                <td>{item.zip}</td>
                                                <td>{item.last_Used}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={6}>
                                                    <table className='table table-bordered datatble mt-3' aria-labelledby="tabelLabel">
                                                        <thead>
                                                            <tr>
                                                                <th>Our_Prod</th>
                                                                <th>Comp_Prod_Name</th>
                                                                <th>Last Check</th>
                                                                <th>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.site_Competitors_Grades_TT != undefined && item.site_Competitors_Grades_TT.map((item2: SiteViewStore.SiteCompetitorsGradesTTModel, j) =>

                                                                <tr key={j}>
                                                                    <td>{item2.our_Prod}</td>
                                                                    <td>{item2.comp_Prod_Name}</td>
                                                                    <td>{item2.last_Check}</td>
                                                                    <td>{item2.price}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>



            </div>

        )


    }

    private renderSiteViewElement() {
        if (this.props.SiteView != undefined) {
            return (
                <div>
                    <div className='row border mx-0 justify-content-between'>
                        <div className='col-md-6 pl-0'>
                            <div className='form-group d-flex mb-1px'>
                                <label className='w-25 col-form-label text-right pr-3 bg-lightgrey mr-1'>Site Name</label>
                                <div className='w-50'>
                                    <input type='text' className='form-control rounded-0' onBlur={e => this.onInputchange(e)} defaultValue={this.props.SiteView.tSiteName} id='tSiteName' name='tSiteName' />
                                </div>
                            </div>
                            <div className='form-group d-flex mb-1px'>
                                <label className='w-25 px-0 col-form-label text-right pr-3 bg-lightgrey mr-1'>Address 1</label>
                                <div className='w-50'>
                                    <input type='text' className='form-control rounded-0' onBlur={e => this.onInputchange(e)} defaultValue={this.props.SiteView.tAddress1} id='tAddress1' name='tAddress1' />
                                </div>
                            </div>
                            <div className='form-group d-flex mb-1px'>
                                <label className='w-25 col-form-label text-right pr-3 bg-lightgrey mr-1'>Address 2</label>
                                <div className='w-50'>
                                    <input type='text' className='form-control rounded-0' onBlur={e => this.onInputchange(e)} defaultValue={this.props.SiteView.tAddress2} id='tAddress2' name='tAddress2' />
                                </div>
                            </div>
                            <div className='form-group d-flex mb-0'>
                                <label className='w-25 px-0 col-form-label text-right pr-3 bg-lightgrey mr-1'>City, State, Zip</label>
                                <div className='w-50'>
                                    <div className="row">
                                        <div className="col-lg-5">

                                            <input type='text' className='form-control rounded-0' onBlur={e => this.onInputchange(e)} defaultValue={this.props.SiteView.tCity} id='tCity' name='tCity' />
                                        </div>
                                        <div className="col-lg-2 px-0">
                                            <input type='text' className='form-control rounded-0' maxLength={2} onBlur={e => this.onInputchange(e)} defaultValue={this.props.SiteView.tState} id='tState' name='tState' />
                                        </div>
                                        <div className="col-lg-5">
                                            <input type='text' className='form-control rounded-0' onBlur={e => this.onInputchange(e)} defaultValue={this.props.SiteView.tZip} id='tZip' name='tZip' />

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='col-md-5 pr-0'>
                            <div className='form-group d-flex mb-1px  ml-auto'>
                                <label className='w-25 col-form-label text-right pr-3 bg-lightgrey mr-1'>Active</label>
                                <div className='w-75 align-self-center'>
                                    <label className='checkbox'>
                                        <input type='checkbox' className='mr-2' name='cActive' onBlur={e => this.onInputchange(e)} defaultChecked={this.props.SiteView.cActive} />
                                        <span className='mr-3'></span>

                                    </label>
                                </div>

                            </div>
                            <div className='form-group d-flex mb-1px ml-auto'>
                                <label className='w-25  col-form-label px-0 text-right pr-3 bg-lightgrey mr-1'>Last Check In</label>
                                <div className='w-75 align-self-center'>

                                    {this.props.SiteView.dteLastCheckin}
                                </div>
                            </div>
                            <div className='form-group d-flex mb-1px  ml-auto'>
                                <label className='w-25 col-form-label text-right pr-3 bg-lightgrey mr-1'>Site ID</label>
                                <div className='w-25 '>
                                    <input type='text' className='form-control  rounded-0' onBlur={e => this.onInputchange(e)} defaultValue={this.props.SiteView.tSiteID} id='tSiteID' name='tSiteID' />
                                </div>
                            </div>
                            <div className='form-group d-flex mb-0  ml-auto'>
                                <label className='w-25 px-0 col-form-label text-right pr-3 bg-lightgrey mr-1'>Site Number</label>
                                <div className='w-25'>
                                    <input type='text' className='form-control  rounded-0' onBlur={e => this.onInputchange(e)} defaultValue={this.props.SiteView.tSiteNumber} id='tSiteNumber' name='tSiteNumber' />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }
    }



}

export default connect(
    (state: ApplicationState) => state.siteView, // Selects which state properties are merged into the component's props
    SiteViewStore.actionCreators // Selects which action creators are merged into the component's props
)(ListData as any);
