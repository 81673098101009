import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as PriceChangeHistoryStore from '../store/PriceChangeHistory';
import $ from 'jquery';
import MUIDataTable from "../MUI";
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { compareAsc, format } from 'date-fns';
import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type ListProps =
    PriceChangeHistoryStore.PriceChangeHistoryState // ... state we've requested from the Redux store
    & typeof PriceChangeHistoryStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ Active: string }>; // ... plus incoming routing parameters


class ListData extends React.PureComponent<ListProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }
    constructor(props: Readonly<ListProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });
        
        this.props.requestLists(event.target.value);

    }
    // This method is called when the route parameters change
    public componentDidUpdate() {
        //this.ensureDataFetched();
    }

    public render() {


        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <h3 className='card-title'>
                            Price Change History

                        </h3>
                    </div>
                    <div className="card-body">

                        {this.renderTable()}
                        {this.renderPagination()}

                    </div>
                </div>

            </React.Fragment>
        );
    }

    private ensureDataFetched() {

        this.props.requestLists(0);
    }

    private renderTable() {
        const columns = [
            {
                name: "SiteNumber",
                label: "Site Number",
                options: {
                    visible: false,
                    sort: false,

                }

            }, {
                name: "dteEffectiveRequest",
                label: "Effective Rt",
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <label>
                            {this.props.PriceChangeHistory[tableMeta.rowIndex].dteEffectiveRequest == undefined ? "" : format(new Date(this.props.PriceChangeHistory[tableMeta.rowIndex].dteEffectiveRequest), 'MM/dd/yyyy kk:mm')}
                        </label>

                    )
                }
            },
            {
                name: "ProductName",
                label: "Product Name",

            },
            {
                name: "CashPrice",
                label: "CashPrice",
                options: {
                    filter: true,
                    sort: false,

                }
            }


        ];


        const options = {
            filter: true,
            expandableRowsHeader: true,
            filterType: 'dropdown' as any,
            pagination: false,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),
            draggableColumns: {
                enabled: true,
            },
            onTableChange: (action: any, state: any) => {
                console.log(action);
                //console.dir(state);
            },
            onGroupExpansionChange: (group: any, expanded: any) => {
                console.dir(group);
                console.dir(expanded);
            },
            grouping: { columnIndexes: [0,1] },
            tableBodyHeight: '34vh',
        };
        if (this != undefined && this.props.isLoading == false) {
            if (this.props.PriceChangeHistory.length > 0) {
                return (

                    <MUIDataTable
                        title={""}
                        data={this.props.PriceChangeHistory}
                        columns={columns}
                        options={options}
                    />
                );
            } else {
                const optionsnew = {
                    filter: true,
                    fixedHeader: true,
                    filterType: 'dropdown' as any,
                    pagination: false,
                    selectableRows: 'none' as any,
                    setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),


                };
                return (
                    <>
                    <MUIDataTable
                        title={""}
                        data={this.props.PriceChangeHistory}
                        columns={columns}
                            options={optionsnew}
                        />
                        
                 </>
                );
            }

           
        }
    }

    private renderPagination() {

        return (
            <div className="">

                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}

            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.priceChangeHistory, // Selects which state properties are merged into the component's props
    PriceChangeHistoryStore.actionCreators // Selects which action creators are merged into the component's props
)(ListData as any);
