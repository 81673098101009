
/* eslint-disable no-console */
import React, { Component } from 'react';
import $ from 'jquery';
import { authHeader } from '../components/AuthHeader';
import Apphistoryshow from './history';
import MUIDataTable from "../MUI";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PluginAgent from "../components/PluginAgentPopUp"
import { ApiUrl } from '../components/Constant';
import "bootstrap-touchspin";
import * as QueryString from "query-string"
import { compareAsc, format } from 'date-fns';
import { Link, Redirect, useHistory } from 'react-router-dom';

// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';

const columns = [
    {
        name: "AgentID",
        label: "ID",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "AgentName",
        label: "Agent Name",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "isValidCheckDate",
        label: "Valid Check Date",

        options: {
            //customBodyRender: (value, tableMeta, updateValue) => {

            //    return (
            //        <label>
            //            <label className="d-none">
            //                {tableMeta.tableData[tableMeta.rowIndex][tableMeta.columnIndex]}
            //            </label>
            //            {tableMeta.tableData[tableMeta.rowIndex][tableMeta.columnIndex] == undefined ? "" : format(new Date(tableMeta.tableData[tableMeta.rowIndex][tableMeta.columnIndex]), 'MM/dd/yyyy kk:mm')}
            //        </label>
            //    )
            //},
            filter: true,
            sort: true,
        }
    },
    {
        name: "isValid",
        label: "IsValid",

        options: {
            customBodyRender: (value, tableMeta, updateValue) => {

                return (
                    <label>
                        {value == true ? "Yes" : "No"}
                    </label>
                )
            },
            filter: true,
            sort: true,
        }
    },
    {
        name: "dteLastRun",
        label: "dteLastRun",
        options: {
            //customBodyRender: (value, tableMeta, updateValue) => {

            //    return (
            //        <label>
            //            {tableMeta.tableData[tableMeta.rowIndex][tableMeta.columnIndex] == undefined ? "" : format(new Date(tableMeta.tableData[tableMeta.rowIndex][tableMeta.columnIndex]), 'MM/dd/yyyy kk:mm')}
            //        </label>
            //    )
            //},
            filter: true,
            sort: true,
        }
    }

];


const options = {
    filterType: 'dropdown',
    selectableRows: 'none',
    setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
};
const customePorlet = {

    padding: "10px",
    borderRadius: " 5px",
    marginBottom: "10px",
    border: "1px solid #eee",
}
const formSection = {

    marginBottom: "3px",
    borderBottom: "1px solid #e7ecf1",
    margin: " 5px 0 20px",
    paddingBottom: "5px",
    color: "#36c6d3",
    fontSize: "15px",
}
const SettingHeader = {

    backgroundColor: "transparent",
    borderWidth: "0px"
}
const table = {
    width: "80%",

}

class Plugin extends Component {
    constructor() {
        super();

        this.state = {
            plugins: [

            ],
            agents: [

            ],
            ScheduleTypeBtn: "Recurring",
            disableEverything: "Date",
            FrequencyBtns: "daily",
            DailyFrequency: "OccursEvery",
            MonthlyDayRadio: "day",
            HasEndDate: "no",
            key: "Setting",
            redirect : false,


        };
        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAgent = this.handleAgent.bind(this);
        this.runtimeTypeSelected = this.runtimeTypeSelected.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onParameterchange = this.onParameterchange.bind(this);
        this.rtpStartingAt = React.createRef();
        this.rntbOccursEvery = React.createRef();
        this.rntbEveryXWeeks = React.createRef();
        this.rntbEveryXMonths = React.createRef();
        this.rntbDayXOfTheMonth = React.createRef();
        this.rntbEveryXDays = React.createRef();
        this.rtpEndingAt = React.createRef();
        this.rntbEveryXMonthsR = React.createRef();
        this.rdpActiveStartDate = React.createRef();
        this.rdpActiveEndDate = React.createRef();
        this.rtpOnce = React.createRef();
        this.rdpOneTime = React.createRef();
        this.rtpOneTime = React.createRef();
        this.blurTextInput = this.blurTextInput.bind(this);
        this.handleSelect = this.handleSelect.bind(this)

    }

    blurTextInput() {
        this.rdpOneTime.current.focus();
        this.rtpOneTime.current.focus();
        this.rtpOnce.current.focus();
        this.rdpActiveEndDate.current.focus();
        this.rdpActiveStartDate.current.focus();
        this.rtpOneTime.current.blur();
        this.rdpOneTime.current.blur();
        this.rdpActiveStartDate.current.blur();
        this.rtpOnce.current.blur();
        this.rdpActiveEndDate.current.blur();
        this.rntbEveryXMonthsR.current.blur();
        this.rntbEveryXMonthsR.current.focus();
        this.rntbEveryXMonths.current.focus();
        this.rntbOccursEvery.current.focus();
        this.rtpStartingAt.current.focus();
        this.rntbEveryXWeeks.current.focus();
        this.rntbDayXOfTheMonth.current.focus();
        this.rntbEveryXDays.current.focus();
        this.rtpEndingAt.current.focus();
        this.rntbEveryXMonths.current.blur();
        this.rntbDayXOfTheMonth.current.blur();
        this.rtpStartingAt.current.blur();
        this.rntbOccursEvery.current.blur();
        this.rntbEveryXDays.current.blur();
        this.rntbEveryXWeeks.current.blur();
        this.rtpEndingAt.current.blur();
    }
    componentDidMount() {

        const parsed = QueryString.parse(this.props.location.search)
        var ID = null;
        if (parsed != null && parsed != undefined) {

            ID = parseInt(parsed.ID == null ? "0" : parsed.ID.toString()) || 0;

        }
        const requestOptions = {
            method: 'GET',
            headers: authHeader('ok'),
        };

        fetch(ApiUrl + 'Plugin/AddEditPlugin?ID=' + ID, requestOptions)
            .then((response) => {

                if (response.status == 401) {
                    $("#btnLogout").click();
                    return false;
                }
                else if (!response.ok) {
                    alert(response.text);
                    return false;
                }
                else
                    return response.json();
            })
            .then(

                data => {

                    if (data.lstAssemblyPlugin != null) {
                        data.lstAssemblyPlugins = JSON.parse(data.lstAssemblyPlugin);
                    }
                    else {
                        data.lstAssemblyPlugins = [];
                    }

                    if (data.lstAgent != null) {
                        data.lstAgents = JSON.parse(data.lstAgent);
                    }
                    else {
                        data.lstAgents = [];
                    }
                    this.setState({
                        plugins: data
                    })
                    if (data.scheduler.freqInterval === "1") {
                        this.setState({
                            FrequencyBtns: "daily",
                        })

                    }
                    else if (data.scheduler.freqInterval === "2") {
                        this.setState({
                            FrequencyBtns: "weekly",
                        })

                    }
                    else {
                        this.setState({
                            FrequencyBtns: "monthly",
                        })

                    }

                    if (data.scheduler.dailyFrequency === "OccursEvery") {

                        this.setState({
                            DailyFrequency: "OccursEvery",
                        })

                    } else {
                        this.setState({
                            DailyFrequency: "OccursonceAt",
                        })
                    }
                    if (data.scheduler.scheduleType === "1") {
                        this.setState({
                            ScheduleTypeBtn: "Recurring",
                            disableEverything: "Date",
                            //event.target.name:1
                        })

                    } else {
                        this.setState({
                            ScheduleTypeBtn: "OneTime",
                            disableEverything: "All",
                            //event.target.name: 2
                        })
                    }
                    if (data.scheduler.theDay === "day") {

                        this.setState({
                            MonthlyDayRadio: "day",
                        })

                    } else {
                        this.setState({
                            MonthlyDayRadio: "The",
                        })
                    }

                    if (data.scheduler.durationEndDateType === false) {

                        this.setState({
                            HasEndDate: "no",
                        })

                    }
                    else {

                        this.setState({
                            HasEndDate: "yes",
                        })

                    }
                    if (data.pluginConfigID > 0) {

                        if (data.runtimeTypeID == 2) {
                            // sched
                            this.handleSelect('Schedules');
                            $('[data-rb-event-key="Schedules"]').show();

                        } else {
                            $('[data-rb-event-key="Schedules"]').hide();
                            this.handleSelect('Setting');
                        }
                    }



                }).catch(

                    data => {

                        this.setState({
                            plugins: []
                        })

                    }
                );
    }

    pluginSelected() {

        this.runtimeTypeSelected();

    }
    onChangeValue = (event) => {

        switch (event.target.id) {
            case 'rbtnRecurring':
                this.setState({
                    ScheduleTypeBtn: "Recurring",
                    disableEverything: "Date",
                    //event.target.name:1
                })
                this.state.plugins.scheduler.scheduleType = 1;
                break;
            case "rbtnOneTime":
                this.setState({
                    ScheduleTypeBtn: "OneTime",
                    disableEverything: "All",
                    //event.target.name: 2
                })

                this.state.plugins.scheduler.scheduleType = 2;
                break;
            case "rbtnDaily":
                this.setState({
                    FrequencyBtns: "daily",
                })
                this.state.plugins.scheduler.freqInterval = 1;
                break;
            case "rntbEveryXDays":

                this.state.plugins.scheduler.recursEveryDay = event.target.value;
                break;
            case "rntbEveryXWeeks":

                this.state.plugins.scheduler.recursEveryWeek = event.target.value;

                break;
            case "rbtnWeekly":
                this.setState({
                    FrequencyBtns: "weekly",
                })
                this.state.plugins.scheduler.freqInterval = 2;

                break;
            case "rbtnMonthly":
                this.setState({
                    FrequencyBtns: "monthly",
                })
                this.state.plugins.scheduler.freqInterval = 3;

                break;
            case "chkMonday":

                this.state.plugins.scheduler.monday = event.target.checked;

                break;
            case "chkTuesday":

                this.state.plugins.scheduler.tuesday = event.target.checked;

                break;
            case "chkWednesday":

                this.state.plugins.scheduler.wednesday = event.target.checked;

                break;
            case "chkThursday":

                this.state.plugins.scheduler.thursday = event.target.checked;

                break;
            case "chkFriday":

                this.state.plugins.scheduler.friday = event.target.checked;

                break;
            case "chkSaturday":

                this.state.plugins.scheduler.saturday = event.target.checked;

                break;
            case "chkSunday":

                this.state.plugins.scheduler.sunday = event.target.checked;


                break;
            case "rdoFreqRelativeRecur":

                this.setState({
                    DailyFrequency: "OccursEvery",
                })
                this.state.plugins.scheduler.dailyFrequency = event.target.value;

                break;
            case "rdoMoFreqRelativeOnce":

                this.setState({
                    MonthlyDayRadio: "day",
                })
                this.state.plugins.scheduler.theDay = event.target.value;

                break;
            case "rtpOneTime":

                this.state.plugins.scheduler.oneTimeOccurenceTime = event.target.value;

                break;
            case "rdpOneTime":

                this.state.plugins.scheduler.oneTimeOccurenceDate = event.target.value;

                break;
            case "rntbDayXOfTheMonth":

                this.state.plugins.scheduler.dayXOfTheMonth = event.target.value;

                break;
            case "cboMoRecFreqRelativeInterval":

                this.state.plugins.scheduler.moRecFreqRelativeInterval = event.target.value;

                break;
            case "cboMoRecFreqInterval":

                this.state.plugins.scheduler.moRecFreqInterval = event.target.value;

                break;
            case "rdpActiveEndDate":

                this.state.plugins.scheduler.durationEndDate = event.target.value;

                break;
            case "rntbEveryXMonthsR":

                this.state.plugins.scheduler.theMonths = event.target.value;

                break;
            case "rdpActiveStartDate":

                this.state.plugins.scheduler.durationStartDate = event.target.value;

                break;
            case "rtpEndingAt":

                this.state.plugins.scheduler.dailyFrequencyEnding = event.target.value;

                break;
            case "rtpStartingAt":

                this.state.plugins.scheduler.dailyFrequencyStarting = event.target.value;

                break;
            case "rntbOccursEvery":

                this.state.plugins.scheduler.dailyFrequencyEvery = event.target.value;

                break;
            case "rdoFreqRelative":
                this.setState({
                    DailyFrequency: "OccursonceAt",
                })
                this.state.plugins.scheduler.dailyFrequency = event.target.value;

                break;
            case "rntbEveryXMonths":

                this.state.plugins.scheduler.everyXMonths = event.target.value;

                break;
            case "rtpOnce":

                this.state.plugins.scheduler.dailyFrequencyOccursOnce = event.target.value;
                break;
            case "cboFreqSubdayType":

                this.state.plugins.scheduler.freqSubdayType = event.target.value;

                break;
            case "rdoMoFreqRelativeEvery":

                this.setState({
                    MonthlyDayRadio: "The",
                })
                this.state.plugins.scheduler.theDay = event.target.value;

                break;
            case "rdoEndOnDate":

                this.setState({
                    HasEndDate: "no",
                })
                this.state.plugins.scheduler.durationEndDateType = event.target.value;

                break;
            case "rdoEndOnNone":

                this.setState({
                    HasEndDate: "yes",
                })
                this.state.plugins.scheduler.durationEndDateType = event.target.value;

            default:
        }

    }
    runtimeTypeSelected() {

        var rcbRuntimeType = this.state.plugins.runtimeTypeID;
        //this.state.plugins.lstAssemblyPluginsOutput = data.lstAssemblyPluginsOutput;
        if (this.state.plugins.lstAssemblyPluginsRuntime.filter(i => i.runtimeTypeID == rcbRuntimeType).length == 0) {
            rcbRuntimeType = $('#lstAssemblyPluginsRuntime').val();
            if ($('#lstAssemblyPluginsRuntime').val() != "2") {

                // sched
                this.handleSelect('Schedules');
                $('[data-rb-event-key="Schedules"]').show();
            }
        } else {


            if (rcbRuntimeType) {
                if (rcbRuntimeType == 2) {
                    // sched
                    this.handleSelect('Schedules');
                    $('[data-rb-event-key="Schedules"]').show();


                } else {
                    $('[data-rb-event-key="Schedules"]').hide();
                    this.handleSelect('Setting');
                }
            }
        }
    }
    onParameterchange(event) {
        if (event.target.type == "checkbox") {

            this.state.plugins.lstGroups.find(i => i.pluginID == event.target.name).lstParameters.find(i => i.parameterID == event.target.id).value = event.target.checked
        }
        else {
            this.state.plugins.lstGroups.find(i => i.pluginID == event.target.name).lstParameters.find(i => i.parameterID == event.target.id).value = event.target.value
        }
    }

    onInputchange(event) {


        if (event.target.type == "checkbox") {

            this.setState({
                [event.target.name]: event.target.checked,
            });
            this.state.plugins[event.target.name] = event.target.checked
        }
        else if (event.target.type == "select-one") {

            if (event.target.id == "lstAssemblyPluginsRuntime") {
                this.state.plugins.runtimeTypeID = event.target.value;
                this.setState({
                    [this.state.plugins.runtimeTypeID]: event.target.value,
                });
                this.runtimeTypeSelected();
            }
            else if (event.target.id == "lstAssemblyPlugins") {

                if (event.target.value == "-200") {
                    return;
                }
                this.state.plugins.pluginID = event.target.value
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader('ok'),
                };

                fetch(ApiUrl + 'Plugin/GetAssemblyPluginOut?PluginID=' + event.target.value, requestOptions)
                    .then((response) => {

                        if (response.status == 401) {
                            $("#btnLogout").click();
                            return false;
                        }
                        else if (!response.ok) {
                            alert(response.text);
                            return false;
                        }
                        else
                            return response.json();
                    })
                    .then(

                        data => {

                            this.setState({
                                [this.state.plugins.lstAssemblyPluginsRuntime]: data.lstAssemblyPluginsRuntime,
                                [this.state.plugins.lstGroups]: data.lstGroups
                            });

                            ///
                            if (this.state.plugins.scheduler.scheduleType === "1") {
                                this.setState({
                                    ScheduleTypeBtn: "Recurring",
                                    disableEverything: "Date",
                                    //event.target.name:1
                                })

                            } else {
                                this.setState({
                                    ScheduleTypeBtn: "OneTime",
                                    disableEverything: "All",
                                    //event.target.name: 2
                                })


                            }

                            ///

                            this.state.plugins.lstAssemblyPluginsRuntime = data.lstAssemblyPluginsRuntime;
                            this.state.plugins.lstGroups = data.lstGroups;

                            this.setState({
                                plugins: this.state.plugins
                            });
                            this.runtimeTypeSelected();

                        }).catch(

                            data => {

                                this.setState({
                                    plugins: []
                                })

                            }
                        );
            }
            this.setState({
                [event.target.name]: event.target.value,
            });
            this.state.plugins[event.target.name] = event.target.value

        } else {

            this.setState({
                [event.target.name]: event.target.value,
            });
            this.state.plugins[event.target.name] = event.target.value
        }
    }
    handleAgent = (e) => {

        fetch(ApiUrl + 'Plugin/GetPluginAgents?pluginConfigID=' + this.state.plugins.pluginConfigID).then(response => response.json())
            .then(

                data => {

                    if (data === null || data === '[]') {
                        this.setState({
                            agents: []
                        });
                    } else {
                        this.setState({
                            agents: JSON.parse(data)
                        });
                    }

                });
    };

    handleSubmit = (e) => {
        this.blurTextInput();


        e.preventDefault();
        const { plugins } = this.state;
        plugins.SelectedIds = "";

        for (var i = 0; i < plugins.lstAgents.length; i++) {
            plugins.SelectedIds += "," + plugins.lstAgents[i].AgentID;
        }

        plugins.lstAgents = "";
        plugins.lstAssemblyPlugin = "";
        plugins.lstAgent = "";
        plugins.lstAssemblyPlugin = plugins.lstAssemblyPlugins.find(i => i.PluginID == plugins.pluginID).Name;
        var assembleyName = plugins.lstAssemblyPlugin.split(':');
        if (assembleyName.length > 2) {
            plugins.lstAssemblyPlugin = assembleyName[2];
        }
        //plugins.lstAssemblyPluginsOutput = [];
        plugins.lstAssemblyPluginsRuntime = [];
        var data = plugins;
        const requestOptions = {
            method: 'POST',
            headers: authHeader(JSON.stringify(data)),
            body: JSON.stringify('ok')
        };


        fetch(ApiUrl + 'Plugin/pluginUpdate', requestOptions).then(response => response.json())
            .then(

                data => {
                    if (data.message == "success") {

                        $('.closepopupbtn').trigger('click');
                        //const history = useHistory();

                        //history.push('/plugin_List');
                        this.setState({
                            redirect:true
                        })
                        //window.location.reload(false);
                    } else {
                        alert(data.message);
                    }

                });
    };
    changestatehere = (e) => {
        this.state.agents[e.target.name].Active = e.target.checked;
        var old = this.state.plugins.lstAgents.find(i => i.AgentID == e.target.placeholder);
        if (old != null) {
            if (e.target.checked == false) {
                //remove this.state.plugins.lstAgents
                var index = this.state.plugins.lstAgents.indexOf(old);
                if (index > -1) {
                    this.state.plugins.lstAgents.splice(index, 1);
                }
            }
        } else {
            if (e.target.checked) {
                old = {};
                old.AgentID = parseInt(e.target.placeholder);
                old.AgentName = e.target.value;
                old.Active = true;
                this.state.plugins.lstAgents.push(old);
            }
        }

        this.setState({
            plugins: this.state.plugins,
        })
    }
    
    render() {
        const { redirect } = this.state;
        if (redirect) {

            return <Apphistoryshow def="/plugin_List" />
        }
        return (
            <div>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className='card-label'>
                                Configurations

                            </h3>
                        </div>
                        <div className='card-toolbar'>
                            <button className='btn btn-primary mr-2' onClick={this.handleSubmit}>Save</button>
                            <a href='/plugin_List' className='btn btn-danger mr-2'>Exit</a>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.renderElement()}
                    </div>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} className="mb-3 p-3">
                        <Paper className="p-3">
                            {this.RadTabs()}
                        </Paper>
                    </Grid>


                </Grid>
            </div>
        )
    }
    renderElement() {
        if (this.state.plugins != undefined) {
            return (
                <div>
                    <div className='row border mx-0 justify-content-between'>
                        <div className='col-md-6 pl-0'>
                            <div className='form-group d-flex mb-1px'>
                                <label className='w-25 col-form-label text-right pr-3 bg-lightgrey mr-1'>Name</label>
                                <div className='w-50'>
                                    <input type='text' name="pluginConfigName" className='form-control rounded-0' onBlur={e => this.onInputchange(e)} defaultValue={this.state.plugins.pluginConfigName} />
                                </div>
                            </div>
                            <div className='form-group d-flex mb-1px'>
                                <label className='w-25 px-0 col-form-label text-right pr-3 bg-lightgrey mr-1'>Plugin</label>
                                <div className='w-50'>
                                    <select className="form-control" name="pluginID" id="lstAssemblyPlugins" value={this.state.plugins.pluginID} onChange={e => this.onInputchange(e)}>
                                        <option value="-200">Please Select</option>
                                        {this.state.plugins.lstAssemblyPlugins != undefined && this.state.plugins.lstAssemblyPlugins.map((e) => {
                                            return <option key={e.PluginID} value={e.PluginID}>{e.Name}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 pr-0'>
                            <div className='form-group d-flex mb-1px  ml-auto'>
                                <label className='w-25 col-form-label text-right pr-3 bg-lightgrey mr-1'>Active</label>
                                <div className='w-75 align-self-center'>
                                    <label className='checkbox'>
                                        <input type='checkbox' name="active" className='mr-2' onBlur={e => this.onInputchange(e)} defaultChecked={this.state.plugins.active} />
                                        <span className='mr-3'></span>

                                    </label>
                                </div>

                            </div>
                            <div className='form-group d-flex mb-1px ml-auto'>
                                <label className='w-25  col-form-label px-0 text-right pr-3 bg-lightgrey mr-1'>Runtime Type</label>
                                <div className='w-75 align-self-center'>
                                    <select className="form-control" name="runtimeTypeID" id="lstAssemblyPluginsRuntime" value={this.state.plugins.runtimeTypeID} onChange={e => this.onInputchange(e)}>
                                        {this.state.plugins.lstAssemblyPluginsRuntime != undefined && this.state.plugins.lstAssemblyPluginsRuntime.map((e) => {
                                            return <option key={e.runtimeTypeID} value={e.runtimeTypeID}>{e.runtimeTypeName}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            );
        }
    }
    handleSelect(key) {

        this.setState({ key });
    }
    RadTabs() {
        ($(".TouchSpin")).TouchSpin({
            buttondown_class: "btn btn-secondary",
            buttonup_class: "btn btn-secondary",
            verticalbuttons: !0,
            verticalupclass: "la la-angle-up",
            verticaldownclass: "la la-angle-down",
            step: 1,
        });
        return (

            <Tabs id="uncontrolled-tab-example" activeKey={this.state.key} className='mb-3' onSelect={this.handleSelect} >
                <Tab eventKey="Setting" title="Setting">
                    {this.SettingContent()}
                </Tab>
                <Tab eventKey="Agents" title="Agents">
                    {this.AgentsContent()}
                </Tab>
                <Tab eventKey="Schedules" title="Schedules" >
                    {this.SchedulesContent()}
                </Tab>

            </Tabs>

        )

    }
    SettingContent() {

        return (

            <div className='mt-5 w-75'>
                <div className="bg-white rounded  mb-3">
                    <div className="card-border">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <div className="customePorlet" style={customePorlet}>

                                            {this.SettingPlugins()}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

    SettingPlugin = lstGroups => {

        let content = [];
        if (lstGroups != undefined && lstGroups.length > 0) {

            for (let i = 0; i < lstGroups.length; i++) {
                const item = lstGroups[i];
                content.push(
                    <div className="customePorlet" style={customePorlet} key={i}>
                        <input type="hidden" value={item.pluginID} />
                        <h3 className="form-section" style={formSection}>{item.groupName}</h3>

                        {this.SettingPluginParameters(item.lstParameters)}


                    </div>
                );
            }
        } else {
            content.push(<div key={0}>
                No Plugin Settings Available
            </div>)
        }
        return content;
    };

    SettingPluginParameters = lstParameters => {
        let content = [];

        for (let i = 0; i < lstParameters.length; i++) {
            const item = lstParameters[i];

            content.push(
                <table className="EditFormGreyLabel" key={i} style={table}>
                    <tbody>
                        <tr id="trData">
                            <th style={SettingHeader}>
                                {item.name}
                            </th>

                            <td>
                                {this.renderSwitch(item)}

                            </td>


                        </tr>
                    </tbody>

                </table>
            );
        }
        return content;
    };

    renderSwitch(item) {

        const dataType = item.dataType;

        switch (dataType) {
            case "System.Int16":
            case "System.Int32":
            case "System.Int64":
                {//please dont change id and name because these are used during state update
                    return <input type='text' className='form-control form-control-sm rounded-0' name={item.pluginID} id={item.parameterID} onBlur={e => this.onParameterchange(e)} defaultValue={item.value} />;
                }
            case "System.Decimal":
            case "System.Double":
                {
                    return <input type='text' className='form-control form-control-sm rounded-0' name={item.pluginID} id={item.parameterID} onBlur={e => this.onParameterchange(e)} defaultValue={item.value} />;
                }
            case "System.Boolean":
                {
                    return <input type='checkbox' className='mr-2' name={item.pluginID} id={item.parameterID} onBlur={e => this.onParameterchange(e)} defaultChecked={item.value} />
                }
            default:
                {
                    if (dataType.includes('System.String(')) {
                        return <textarea rows="3" className="form-control form-control-sm " name={item.pluginID} id={item.parameterID} onBlur={e => this.onParameterchange(e)} defaultValue={item.value} />;
                    } else {
                        if (item.mask == true) {

                            return <input type="Password" className="form-control form-control-sm" name={item.pluginID} id={item.parameterID} onBlur={e => this.onParameterchange(e)} defaultValue={item.value} />;
                        } else {
                            return <input type='text' className='form-control form-control-sm rounded-0' name={item.pluginID} id={item.parameterID} onBlur={e => this.onParameterchange(e)} defaultValue={item.value} />;
                        }

                    }
                }
        }
    }

    SettingPlugins() {
        return (

            <div>
                {this.SettingPlugin(this.state.plugins.lstGroups)}
            </div>
        )
    }

    AgentsContent() {



        return (

            <div className='mt-5'>

                <div className="row  mt-3">
                    <div className="col-sm-6">
                        <div className="text-right mb-3">
                            <PluginAgent triggerdata={(e) => this.handleAgent(e)} getdata={this.state.agents} changestate={(e) => this.changestatehere(e)} />
                        </div>
                        <div className="">
                            <MUIDataTable
                                key={this.state.plugins.lstAgents !== undefined ? this.state.plugins.lstAgents.length : 0}
                                title={""}
                                data={this.state.plugins.lstAgents}
                                columns={columns}
                                options={options}
                            />


                        </div>
                    </div>
                </div>


            </div>

        )

    }

    SchedulesContent() {
        ($('.datepicker')).datetimepicker({
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'far fa-trash-alt',
                close: 'far fa-times-circle'
            },
            format: 'MM/DD/YYYY'
        });
        ($('.startDate')).datetimepicker({
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'far fa-trash-alt',
                close: 'far fa-times-circle'
            },
            format: 'MM/DD/YYYY'
        });
        ($('.endDate')).datetimepicker({
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'far fa-trash-alt',
                close: 'far fa-times-circle'
            },
            format: 'MM/DD/YYYY'
        });
        ($('.timepicker')).datetimepicker({
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'far fa-trash-alt',
                close: 'far fa-times-circle'
            },
            format: "hh:mm:ss A"
        });
        ($(".TouchSpin")).TouchSpin({
            buttondown_class: "btn btn-secondary",
            buttonup_class: "btn btn-secondary",
            verticalbuttons: !0,
            verticalupclass: "la la-angle-up",
            verticaldownclass: "la la-angle-down",
            step: 1,
        });

        if (this.state.plugins != undefined && this.state.plugins.scheduler != undefined) {


            return (


                <div className='mt-5 card card-body'>

                    <div className="row">
                        <div className="col-md-12">
                            <input type="hidden" value={this.state.plugins.scheduler.scheduleType} name="scheduleType" id="hdnScheduleType" />
                            <div className="form-group row">
                                <label className="col-3 mt-1">Schedule Type</label>
                                <div className="col-8">
                                    <div className="btn-group btn-group-toggle" data-toggle="buttons" defaultChecked={this.state.plugins.scheduler.scheduleType} onChange={this.onChangeValue} id="cboScheduleTypes">
                                        <label className={`btn btn-outline-success m-btn btn-Recurring ${this.state.ScheduleTypeBtn === "Recurring" ? 'active' : ''}`}  >
                                            <input type="radio" id="rbtnRecurring" name="scheduleType" value="1" /> Recurring
                                    </label>
                                        <label className={`btn btn-outline-success m-btn btn-OneTime ${this.state.ScheduleTypeBtn === "OneTime" ? 'active' : ''}`}>
                                            <input type="radio" id="rbtnOneTime" name="scheduleType" value="2" />
                                        One Time
                                    </label>

                                    </div>
                                </div>
                            </div>
                            <div className="OneTime">
                                <h3 className="form-section" style={formSection}>One-time occurence</h3>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="mt-1">Date</label>
                                        </div>
                                        <div className="col-3">
                                            <input type="text" id="rdpOneTime" ref={this.rdpOneTime} defaultValue={this.state.plugins.scheduler.oneTimeOccurenceDate} name="oneTimeOccurenceDate" onBlur={this.onChangeValue} disabled={this.state.disableEverything === "Date" ? "disabled" : ""} className="form-control datepicker" />

                                        </div>
                                        <div className="col-3 text-right">
                                            <label className="mt-1">Time</label>
                                        </div>
                                        <div className="col-3">
                                            <input type="text" id="rtpOneTime" ref={this.rtpOneTime} defaultValue={this.state.plugins.scheduler.oneTimeOccurenceTime} name="oneTimeOccurenceTime" onBlur={this.onChangeValue} disabled={this.state.disableEverything === "Date" ? "disabled" : ""} className="form-control timepicker" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Recurring">

                                <div className="frequency">
                                    <h3 className="form-section" style={formSection}>Frequency</h3>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-3">
                                                <label className="mt-1">Occurs</label>
                                            </div>
                                            <div className="col-8">
                                                <div className={`btn-group btn-group-toggle ${this.state.disableEverything === 'All' ? 'disabled' : ''}`} onChange={this.onChangeValue} defaultChecked={this.state.plugins.scheduler.freqInterval} data-toggle="buttons" id="cboFreqInterval">
                                                    <label className={`btn btn-outline-success m-btn btn-occurs ${this.state.FrequencyBtns === "daily" ? 'active' : ''} ${this.state.disableEverything === 'All' ? 'disabled' : ''}`} data-id="#dailyOccr">
                                                        <span></span>
                                                        {/*<input type="radio" id="rbtnDaily" value="1" defaultChecked={this.state.plugins.scheduler.freqInterval} name="freqInterval" names="FrequencyBtns" />*/}
                                                        <input type="radio" id="rbtnDaily" value="1" name="freqInterval" names="FrequencyBtns" />
                                                         Daily
                                                    </label>
                                                    <label className={`btn btn-outline-success m-btn btn-occurs ${this.state.FrequencyBtns === "weekly" ? 'active' : ''} ${this.state.disableEverything === 'All' ? 'disabled' : ''}`} data-id="#weeklyOccr">
                                                        <span></span>
                                                        {/*<input type="radio" id="rbtnWeekly" value="2" defaultChecked={this.state.plugins.scheduler.freqInterval} name="freqInterval" names="FrequencyBtns" />*/}
                                                        <input type="radio" id="rbtnWeekly" value="2" name="freqInterval" names="FrequencyBtns" />
                                                    Weekly
                                                </label>
                                                    <label className={`btn btn-outline-success m-btn btn-occurs ${this.state.FrequencyBtns === "monthly" ? 'active' : ''} ${this.state.disableEverything === 'All' ? 'disabled' : ''}`} data-id="#monthlyOccr">
                                                        <span></span>
                                                        {/*<input type="radio" id="rbtnMonthly" value="3" name="freqInterval" defaultChecked={this.state.plugins.scheduler.freqInterval} names="FrequencyBtns" />*/}
                                                        <input type="radio" id="rbtnMonthly" value="3" name="freqInterval" names="FrequencyBtns" />
                                                    Monthly
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`form-group  div-occurs ${this.state.FrequencyBtns === "daily" ? 'd-block' : 'd-none'}`} id="dailyOccr">
                                        <div className="row">
                                            <div className="col-3">
                                                <label>Recurs every</label>
                                            </div>
                                            <div className="col-8">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <input type="text" id="rntbEveryXDays" ref={this.rntbEveryXDays} name="recursEveryDay" onBlur={this.onChangeValue} defaultValue={this.state.plugins.scheduler.recursEveryDay} disabled={this.state.disableEverything === "All" ? "disabled" : ""} className="form-control bootstrap-touchspin-vertical-btn TouchSpin" />

                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <div className="mt-1">Day(s)</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`form-group  div-occurs ${this.state.FrequencyBtns === "weekly" ? 'd-block' : 'd-none'}`} id="weeklyOccr">
                                        <div className="row">
                                            <div className="col-3">
                                                <label className="mt-1">Recurs every</label>
                                            </div>
                                            <div className="col-8">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <input type="text" id="rntbEveryXWeeks" onBlur={this.onChangeValue} ref={this.rntbEveryXWeeks} name="recursEveryWeek" defaultValue={this.state.plugins.scheduler.recursEveryWeek} disabled={this.state.disableEverything === "All" ? "disabled" : ""} className="form-control bootstrap-touchspin-vertical-btn TouchSpin" />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <div className="mt-1">Week(s) on</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-3"></div>
                                            <div className="col-8">
                                                <div className="row dvDays">
                                                    <div className="col-3">
                                                        <div className="checkbox-list">
                                                            <label className="checkbox checkbox-outline">
                                                                <input type="checkbox" id="chkMonday" onChange={this.onChangeValue} defaultChecked={this.state.plugins.scheduler.monday} disabled={this.state.disableEverything === "All" ? "disabled" : ""} />

                                                                <span></span>
                                                             Monday

                                                        </label>

                                                            <label className="checkbox checkbox-outline">
                                                                <input type="checkbox" id="chkTuesday" onChange={this.onChangeValue} name="tuesday" defaultChecked={this.state.plugins.scheduler.tuesday} disabled={this.state.disableEverything === "All" ? "disabled" : ""} />
                                                                <span></span>

                                                             Tuesday
                                                        </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className="checkbox-list">
                                                            <label className="checkbox checkbox-outline">
                                                                <input type="checkbox" id="chkWednesday" onChange={this.onChangeValue} name="wednesday" defaultChecked={this.state.plugins.scheduler.wednesday} disabled={this.state.disableEverything === "All" ? "disabled" : ""} />
                                                                <span></span>
                                                          Wednesday
                                                        </label>

                                                            <label className="checkbox checkbox-outline">
                                                                <input type="checkbox" id="chkThursday" onChange={this.onChangeValue} name="wednesday" defaultChecked={this.state.plugins.scheduler.wednesday} disabled={this.state.disableEverything === "All" ? "disabled" : ""} />
                                                                <span></span>
                                                           Thursday
                                                        </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className="checkbox-list">
                                                            <label className="checkbox checkbox-outline">
                                                                <input type="checkbox" id="chkFriday" onChange={this.onChangeValue} name="friday" defaultChecked={this.state.plugins.scheduler.friday} disabled={this.state.disableEverything === "All" ? "disabled" : ""} />
                                                                <span></span>
                                                             Friday
                                                        </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className="checkbox-list">
                                                            <label className="checkbox checkbox-outline">
                                                                <input type="checkbox" id="chkSaturday" onChange={this.onChangeValue} name="saturday" defaultChecked={this.state.plugins.scheduler.saturday} disabled={this.state.disableEverything === "All" ? "disabled" : ""} />

                                                                <span></span>
                                                             Saturday
                                                        </label>

                                                            <label className="checkbox checkbox-outline">
                                                                <input type="checkbox" id="chkSunday" onChange={this.onChangeValue} name="sunday" defaultChecked={this.state.plugins.scheduler.sunday} disabled={this.state.disableEverything === "All" ? "disabled" : ""} />

                                                                <span></span>
                                                            Sunday
                                                        </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${this.state.FrequencyBtns === "monthly" ? 'd-block' : 'd-none'}`} id="monthlyOccr">
                                        <div className="form-group row">

                                            <div className="col-3">
                                                <div className="radio-list">
                                                    <label className="radio radio-success mb-1">
                                                        <input type="radio" id="rdoMoFreqRelativeOnce" defaultChecked={this.state.plugins.scheduler.theDay === "day" ? "checked" : ""} onChange={this.onChangeValue} name="theDay" value="day" disabled={this.state.disableEverything === "All" ? "disabled" : ""} />
                                                        <span className="mr-2"></span>
                                                        Day

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-9 Day">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <input type="text" id="rntbDayXOfTheMonth" ref={this.rntbDayXOfTheMonth} name="dayXOfTheMonth" defaultValue={this.state.plugins.scheduler.dayXOfTheMonth} disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.MonthlyDayRadio === "The" ? "disabled" : ""} onBlur={this.onChangeValue} className="form-control TouchSpin" />

                                                            </div>
                                                            <div className="col-5 pl-0">
                                                                <div className="mt-1">Of every</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <input type="text" id="rntbEveryXMonths" ref={this.rntbEveryXMonths} name="everyXMonths" defaultValue={this.state.plugins.scheduler.everyXMonths} disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.MonthlyDayRadio === "The" ? "disabled" : ""} onBlur={this.onChangeValue} className="form-control TouchSpin" />

                                                            </div>
                                                            <div className="col-5 pl-0">
                                                                <div className="mt-1">Month(s)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="radio-list">
                                                        <label className="radio radio-success mb-1">
                                                            <input type="radio" id="rdoMoFreqRelativeEvery" name="theDay" defaultChecked={this.state.plugins.scheduler.theDay === "The" ? "checked" : ""} onChange={this.onChangeValue} names="monthlyDayradio" value="The" disabled={this.state.disableEverything === "All" ? "disabled" : ""} />
                                                            <span className="mr-2"></span>
                                                         The


                                                    </label>
                                                    </div>
                                                </div>
                                                <div className="col-9 The">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <select id="cboMoRecFreqRelativeInterval" disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.MonthlyDayRadio === "day" ? "disabled" : ""} onChange={this.onChangeValue} className="form-control rounded-0" value={this.state.plugins.scheduler.moRecFreqRelativeInterval}>
                                                                        <option value="1">1st</option>
                                                                        <option value="2">2nd</option>
                                                                        <option value="4">3rd</option>
                                                                        <option value="8">4th</option>
                                                                        <option value="16">Last</option>
                                                                    </select>


                                                                </div>
                                                                <div className="col-5 pl-0">

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-4">
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <select id="cboMoRecFreqInterval" disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.MonthlyDayRadio === "day" ? "disabled" : ""} onChange={this.onChangeValue} className="form-control rounded-0" value={this.state.plugins.scheduler.moRecFreqInterval}>
                                                                        <option value="2">Monday</option>
                                                                        <option value="3">Tuesday</option>
                                                                        <option value="4">Wednesday</option>
                                                                        <option value="5">Thursday</option>
                                                                        <option value="6">Friday</option>
                                                                        <option value="7">Saturday</option>
                                                                        <option value="8">Day</option>
                                                                        <option value="3">Weekday</option>
                                                                        <option value="10">Weekend Day</option>
                                                                    </select>

                                                                </div>
                                                                <div className="col-5 pl-0">
                                                                    <div className="mt-1">  Of every</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">

                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <input type="text" defaultValue={this.state.plugins.scheduler.theMonths} disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.MonthlyDayRadio === "day" ? "disabled" : ""} onBlur={this.onChangeValue} className="form-control bootstrap-touchspin-vertical-btn TouchSpin" ref={this.rntbEveryXMonthsR} id="rntbEveryXMonthsR" />

                                                                </div>
                                                                <div className="col-5 pl-0">
                                                                    <div className="mt-1">  Month(s)</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="DailyFrequency">

                                    <h3 className="form-section" style={formSection}>Daily Frequency</h3>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="radio-list">
                                                    <label className="radio radio-success mb-1">


                                                        <input type="radio" disabled={this.state.disableEverything === "All" ? "disabled" : ""} defaultChecked={this.state.plugins.scheduler.dailyFrequency === "OccursonceAt" ? "checked" : ""} id="rdoFreqRelative" value="OccursonceAt" onChange={this.onChangeValue} name="checkOccurs" />
                                                        <span className="mr-2"></span>
                                                        Occurs once at

                                                </label>
                                                </div>
                                            </div>
                                            <div className="col-9 OccursonceAt">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <input type="text" defaultValue={this.state.plugins.scheduler.dailyFrequencyOccursOnce} disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.DailyFrequency === "OccursEvery" ? "disabled" : ""} ref={this.rtpOnce} onBlur={this.onChangeValue} className="form-control timepicker" id="rtpOnce" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="radio-list">
                                                <label className="radio radio-success mt-0">

                                                    <input type="radio" disabled={this.state.disableEverything === "All" ? "disabled" : ""} defaultChecked={this.state.plugins.scheduler.dailyFrequency === "OccursEvery" ? "checked" : ""} className="form-control" name="checkOccurs" onChange={this.onChangeValue} value="OccursEvery" id="rdoFreqRelativeRecur" />
                                                    <span className="mr-2"></span>
                                                 Occurs every
                                                  </label>
                                            </div>
                                        </div>
                                        <div className="col-9 OccursEvery">
                                            <div className="row">
                                                <div className="col-3">
                                                    <input type="text" defaultValue={this.state.plugins.scheduler.dailyFrequencyEvery} disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.DailyFrequency === "OccursonceAt" ? "disabled" : ""} onBlur={this.onChangeValue} className="form-control TouchSpin" ref={this.rntbOccursEvery} id="rntbOccursEvery" />
                                                </div>
                                                <div className="col-3">
                                                    <select id="cboFreqSubdayType" disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.DailyFrequency === "OccursonceAt" ? "disabled" : ""} className="form-control rounded-0" value={this.state.plugins.scheduler.freqSubdayType} onChange={this.onChangeValue}>
                                                        <option value="8">Hour(s)</option>
                                                        <option value="4">Minute(s)</option>
                                                        <option value="2">Second(s)</option>
                                                    </select>
                                                </div>
                                                <div className="col-2 text-right">
                                                    <label className="mt-1">Starting at</label>
                                                </div>
                                                <div className="col-3">
                                                    <input type="text" defaultValue={this.state.plugins.scheduler.dailyFrequencyStarting} disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.DailyFrequency === "OccursonceAt" ? "disabled" : ""} className="form-control timepicker mb-2" ref={this.rtpStartingAt} onBlur={this.onChangeValue} id="rtpStartingAt" />



                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 offset-6 text-right">
                                                    <label className="mt-1">Ending at</label>
                                                </div>
                                                <div className="col-3">
                                                    <input type="text" defaultValue={this.state.plugins.scheduler.dailyFrequencyEnding} disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.DailyFrequency === "OccursonceAt" ? "disabled" : ""} className="form-control timepicker" ref={this.rtpEndingAt} onBlur={this.onChangeValue} id="rtpEndingAt" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Duration">
                                    <h3 className="form-section" style={formSection}>Duration</h3>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-3">
                                                <label className="mt-1">Start Date</label>
                                            </div>
                                            <div className="col-9">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <input type="text" ref={this.rdpActiveStartDate} defaultValue={this.state.plugins.scheduler.durationStartDate} disabled={this.state.disableEverything === "All" ? "disabled" : ""} className="form-control startDate" onChange={this.onChangeValue} id="rdpActiveStartDate" />
                                                    </div>
                                                    <div className="col-3">

                                                        <div className="radio-list display-inline" onChange={this.onChangeValue}>
                                                            <label className="radio radio-success">

                                                                <input type="radio" defaultChecked={this.state.plugins.scheduler.durationEndDateType === false ? "checked" : ""} value="false" disabled={this.state.disableEverything === "All" ? "disabled" : ""} id="rdoEndOnDate" name="checkdate" />
                                                                <span className="mr-2"></span>
                                            End Date

                                                                    </label>

                                                            <label className="radio radio-success mt-4">

                                                                <input type="radio" defaultChecked={this.state.plugins.scheduler.durationEndDateType === true ? "checked" : ""} value="true" disabled={this.state.disableEverything === "All" ? "disabled" : ""} id="rdoEndOnNone" name="checkdate" />
                                                                <span className="mr-2"></span>
                                            No End Date

                                                                    </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <input type="text" defaultValue={this.state.plugins.scheduler.durationEndDate} disabled={this.state.disableEverything === "All" ? "disabled" : "" || this.state.plugins.scheduler.durationEndDateType === true ? "disabled" : ""} className="form-control datepicker" ref={this.rdpActiveEndDate} onBlur={this.onChangeValue} id="rdpActiveEndDate" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>

            )
        }
    }
}

export default Plugin;