import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReportedPriceState {
    isLoading: boolean;
    Active?: number;
    ReportedPrice: ReportedPriceList[];
}

export interface ReportedPriceList {
    siteID: string;
    siteNumber: string;
    dteLastPriceChange: string;
    statCode: string;
    disp: string;
    state: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestReportedPriceAction {
    type: 'REQUEST_DATA';
    Active: number;
}

interface ReceiveReportedPriceAction {
    type: 'RECEIVE_DATA';
    Active: number;
    ReportedPrice: ReportedPriceList[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestReportedPriceAction | ReceiveReportedPriceAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestReportedPrice: (Active: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.gasBuddyReportedPrice && Active !== appState.gasBuddyReportedPrice.Active) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`gasbuddy/reported_price`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                       
                        dispatch({ type: 'RECEIVE_DATA', Active: Active, ReportedPrice: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', Active: Active });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ReportedPriceState = { ReportedPrice: [], isLoading: false };

export const reducer: Reducer<ReportedPriceState> = (state: ReportedPriceState | undefined, incomingAction: Action): ReportedPriceState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                Active: action.Active,
                ReportedPrice: state.ReportedPrice,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.Active === state.Active) {
                return {
                    Active: action.Active,
                    ReportedPrice: action.ReportedPrice,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
