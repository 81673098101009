
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'
import MUIDataTable from "mui-datatables";


function PluginAgent(props) {
    
    let getdata;
    if (props.getdata != undefined) {

     getdata = props.getdata;
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const comasplitvalues = (e) => {
        
        props.changestate(e)
    }
  

    const showpopupwithdata = (e) => {
        handleShow();
        props.triggerdata(e)
    }

    const columns = [
        {
            name: " ",
            label: " ",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <label className="checkbox">
                            <input type="checkbox" onChange={comasplitvalues} value={props.getdata[tableMeta.rowIndex].AgentName} placeholder={props.getdata[tableMeta.rowIndex].AgentID} className='rowcheckbox' name={tableMeta.rowIndex.toString()} defaultChecked={props.getdata[tableMeta.rowIndex].Active} />
                            <span></span>
                        </label>
                    )
                }
            }
        }, {
            name: "AgentName",
            label: "List of Agents",
            options: {
                filter: true,
                sort: true,
            }
        }

    ];


    const options = {
        filterType: 'dropdown',
        selectableRows: 'none',
        setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
    };


    return (
        <>
            <button onClick={showpopupwithdata} id="btnAgentAddEdit" className="btn btn-primary btn-sm mr-2"><i className="la la-edit"></i>&nbsp;Add/Edit</button>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                    <Modal.Title>Add / Edit Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <MUIDataTable
                        title={""}
                        data={getdata}
                        columns={columns}
                        options={options}
                    />
                </Modal.Body>
                <Modal.Footer className="p-2">
                    <Button variant="secondary" className="SaveData" onClick={handleClose}>
                        Ok
                    </Button>
                    <Button variant="secondary" className="closepopupbtn" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );


}

export default PluginAgent;
