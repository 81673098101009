import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as XrefTypesItemsStore from '../store/XrefTypesItems';
import * as QueryString from "query-string"
import $ from 'jquery';
import EditIcon from '@material-ui/icons/Edit';

import MUIDataTable from "mui-datatables";
import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';

// At runtime, Redux will merge together...
type XrefTypesItemsProps =
    XrefTypesItemsStore.XrefTypesItemsState // ... state we've requested from the Redux store
    & typeof XrefTypesItemsStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ x: string, xt: string }>; // ... plus incoming routing parameters


class XrefTypesItemsData extends React.PureComponent<XrefTypesItemsProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {

        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className='card-Label'>
                                {this.props.XrefTypesItems == null ? "" : this.props.XrefTypesItems.pageTitle}

                            </h3>
                        </div>
                        <div className="card-toolbar">
                            {this.renderButton()}
                        </div>
                    </div>
                    <div className="card-body">


                        {this.renderTable()}
                        {this.renderPagination()}

                    </div>
                </div>

            </React.Fragment>
        );
    }

    private ensureDataFetched() {

        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined) {

            var x = parseInt(parsed.x == null ? "0" : parsed.x.toString()) || 0;
            var xt = parseInt(parsed.xt == null ? "0" : parsed.xt.toString()) || 0;
            if (this.props.XrefTypesItems == undefined || this.props.XrefTypesItems.xrefID != x || this.props.XrefTypesItems.xrefTypeID != xt) {

                this.props.requestXrefTypesItems(x, xt);
            }
        }
    }
    private renderButton() {

        if (this != undefined && this.props.XrefTypesItems != undefined) {

            return (
                <div>
                    <div className=' d-flex '  >
                        <div className='align-self-center mr-1'>

                            <a className='text-white btn btn-primary mr-2' href={`/xref_Types_SetDefault?x=${this.props.XrefTypesItems.xrefID}&xt=${this.props.XrefTypesItems.xrefTypeID}&n=1`}>
                                Edit Mapping Errors
                            </a>
                            <a className='btn mr-2 btn-primary' href={`/xref_Types_SetDefault?x=${this.props.XrefTypesItems.xrefID}&xt=${this.props.XrefTypesItems.xrefTypeID}`}>
                                Edit
                            </a>

                            <a className='btn btn-danger  mr-2' href={`/xref_types?x=${this.props.XrefTypesItems.xrefID}`}>
                                Exit
                            </a>
                        </div>

                    </div>

                </div>
            );
        }
    }


    private renderTable() {
        const columns = [
            {
                name: " ",
                label: " ",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        ////console.log(tableMeta)
                        return (

                            this.props.XrefTypesItems.onlyOverride == true ?
                                <a className='btn btn-icon btn-light-primary btn-square btn-sm mr-2' href={`/xref_Types_SiteItems?x=${this.props.XrefTypesItems.xrefID}&xt=${this.props.XrefTypesItems.xrefTypeID}&ev=${this.props.XrefTypesItems.typeJson[tableMeta.rowIndex].ExtValue}`}>
                                    <EditIcon className="avoid-clicks" />
                                </a>
                                :
                                <a className='btn btn-icon btn-light-primary btn-square btn-sm mr-2' href={`/xref_types_Site_Override?x=${this.props.XrefTypesItems.xrefID}&xt=${this.props.XrefTypesItems.xrefTypeID}&ev=${this.props.XrefTypesItems.typeJson[tableMeta.rowIndex].ExtValue}`}>
                                    <EditIcon className="avoid-clicks" />
                                </a>
                        )
                    }
                }
            }, {
                name: "ExtValue",
                label: "External Value",

            },
            {
                name: "ExtDescription",
                label: "External Description",

            },

            {
                name: "IntDescription",
                label: "Agility Description",

            },

            {
                name: "SiteOverrides",
                label: "Site Overrides",

            },

        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this != undefined && this.props.XrefTypesItems != undefined && this.props.XrefTypesItems.typeJson != undefined && this.props.isLoading == false) {

            return (

                <MUIDataTable
                    title={''}
                    data={this.props.XrefTypesItems.typeJson}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {

        return (
            <div className="">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.xrefTypesItems,
    // Selects which state properties are merged into the component's props
    XrefTypesItemsStore.actionCreators // Selects which action creators are merged into the component's props
)(XrefTypesItemsData as any);
