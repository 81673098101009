import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface XrefTypesItemsState {
    isLoading: boolean;
    x: number;
    xt: number;
    XrefTypesItems: XrefTypesItemsItem;
}
export interface XrefTypesItemsItem {
    xrefID: number;
    xrefTypeID: number;
    pageTitle: string;
    onlyOverride: boolean;
    onlyGlobal: boolean;
    typeJson: XrefTypesItems[];
}

export interface XrefTypesItems {
    XrefDetailID: number;
    ExtValue: number;
    ExtDescription: string;
    IntDescription: string;
    SiteOverrides: number;
    IntValue: number;
    SiteNumber: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestXrefTypesItemsAction {
    type: 'REQUEST_DATA';
    x: number;
    xt: number;
}

interface ReceiveXrefTypesItemsAction {
    type: 'RECEIVE_DATA';
    x: number;
    xt: number;
    XrefTypesItems: XrefTypesItemsItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestXrefTypesItemsAction | ReceiveXrefTypesItemsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestXrefTypesItems: (x: number, xt: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
       
        if (appState && appState.xrefTypesItems && (x !== appState.xrefTypesItems.x || xt !== appState.xrefTypesItems.xt)) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`${ApiUrl}xref/xref_Types_Items?x=${x}&xt=${xt}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {

                        if (data.typeJson == null) {
                            data.typeJson = [];
                        } else {

                            data.typeJson = JSON.parse(data.typeJson);
                        }
                        dispatch({ type: 'RECEIVE_DATA', x: x, xt: xt, XrefTypesItems: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', x: x, xt: xt });
        } 
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: XrefTypesItemsState = { XrefTypesItems: { xrefID: 0, xrefTypeID: 0, typeJson: [], onlyGlobal: false, onlyOverride: false, pageTitle: "" },x: -1, xt: -1, isLoading: false };

export const reducer: Reducer<XrefTypesItemsState> = (state: XrefTypesItemsState | undefined, incomingAction: Action): XrefTypesItemsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                x: action.x,
                xt: action.xt,
                XrefTypesItems: state.XrefTypesItems,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.x === state.x && action.xt === state.xt) {
            //if (action.XrefTypesItems != undefined) {
                return {
                    x: action.x,
                    xt: action.xt,
                    XrefTypesItems: action.XrefTypesItems,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
