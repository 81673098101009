import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as CollectorPriceDetailStore from '../store/CollectorPriceDetail';
import * as QueryString from "query-string"
import $ from 'jquery';
import 'pc-bootstrap4-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import MUIDataTable from "mui-datatables";
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';
import { compareAsc, format } from 'date-fns';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type ListProps =
    CollectorPriceDetailStore.CollectorPriceDetailState // ... state we've requested from the Redux store
    & typeof CollectorPriceDetailStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ SiteID: string, ProductID: string, ProductName: string }>; // ... plus incoming routing parameters


class ListData extends React.PureComponent<ListProps> {
    constructor(props: Readonly<ListProps>) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
    }
    onSearch(event: any) {
        
        var txtFrom  = ($("#txtFrom").val()) == null ? "" : ($("#txtFrom").val()) as string;
        
        var to = ($("#txtTo").val()) == null ? "" : ($("#txtTo").val()) as string;
        this.setState({
            [event.target.name]: event.target.value
        });
        
        this.props.requestCollectorPriceDetail(this.props.CollectorPriceDetail.SiteID, this.props.CollectorPriceDetail.ProductID, this.props.CollectorPriceDetail.ProductName, txtFrom.toString(), to.toString());
        this.props.CollectorPriceDetail.dteFrom = txtFrom;
        this.props.CollectorPriceDetail.dteTo = to;
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        //this.ensureDataFetched();
    }

    public render() {
        ($('.datepicker') as any).datetimepicker({
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'far fa-trash-alt',
                close: 'far fa-times-circle'
            },
        });
        return (
            <React.Fragment>
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className='card-label'>
                                {this.props.CollectorPriceDetail != null ? this.props.CollectorPriceDetail.title : ""}

                            </h3>
                        </div>
                        <div className='card-toolbar'>
                            
                            <a href='/competitor_prices' className='btn btn-danger'>Exit</a>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-md-2'>
                                <div className='form-group mb-3'>
                                    <label>From</label>
                                    <input type='text' id="txtFrom" defaultValue={this.props.CollectorPriceDetail != null ? this.props.CollectorPriceDetail.dteFrom : ""} className='form-control datepicker' />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className='form-group mb-3'>
                                    <label>To</label>
                                    <input type='text' id="txtTo" defaultValue={this.props.CollectorPriceDetail != null ? this.props.CollectorPriceDetail.dteTo : ""} className='form-control datepicker' />
                                </div>
                            </div>
                            <div className='col-md-1 align-self-end mb-3'>
                                <div className='form-group mb-0'>
                                    <label><br/></label>
                                    <button className='btn btn-primary mr-2' onClick={e => this.onSearch(e)}><SearchIcon />Apply</button>
                                </div>
                            </div>
                        </div>
                        {this.renderTable()}
                       
                    </div>
                </div>

            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined) {

            var SiteID = parseInt(parsed.SiteID == null ? "0" : parsed.SiteID.toString()) || 0;
            var ProductID = parseInt(parsed.ProductID == null ? "0" : parsed.ProductID.toString()) || 0;
            var ProductName = parsed.ProductName == null ? "" : parsed.ProductName.toString() || "";
            var From = parsed.From == null ? "" : parsed.From.toString() || "";
            var To = parsed.To == null ? "" : parsed.To.toString() || "";
            this.props.requestCollectorPriceDetail(SiteID, ProductID, ProductName,From,To);
        }

    }

    private renderTable() {
        const columns = [
            {
                name: "dteCreated",
                label: "Created",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <label>
                                {this.props.CollectorPriceDetail.jsonType[tableMeta.rowIndex].dteCreated == undefined ? "" : format(new Date(this.props.CollectorPriceDetail.jsonType[tableMeta.rowIndex].dteCreated), 'MM/dd/yyyy kk:mm')}
                            </label>

                        )
                    }
                },

            }, {
                name: "CashPrice",
                label: "Price",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "CashPriceVar",
                label: "Var",
                options: {
                    filter: true,
                    sort: false,

                }
            },
            {
                name: "CreditPrice",
                label: "Credit",
                options: {
                    filter: true,
                    sort: false,
                }
            },

            {
                name: "CreditPriceVar",
                label: "Credit Var",
                options: {
                    filter: true,
                    sort: true,
                }
            },


        ];


        const options = {
            filterType: 'checkbox' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this != undefined && this.props.CollectorPriceDetail != undefined && this.props.CollectorPriceDetail.jsonType != undefined) {

            return (

                <MUIDataTable
                    title={''}
                    data={this.props.CollectorPriceDetail.jsonType}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    
}

export default connect(
    (state: ApplicationState) => state.collectorPriceDetail, // Selects which state properties are merged into the component's props
    CollectorPriceDetailStore.actionCreators // Selects which action creators are merged into the component's props
)(ListData as any);
