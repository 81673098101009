import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as XrefListSelectStore from '../store/XrefSelectList';
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import EditIcon from '@material-ui/icons/Edit';

import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';

// At runtime, Redux will merge together...
type XrefListSelectProps =
    XrefListSelectStore.XrefListSelectState // ... state we've requested from the Redux store
    & typeof XrefListSelectStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ Active: string }>; // ... plus incoming routing parameters


class XrefListSelectData extends React.PureComponent<XrefListSelectProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

  

    public render() {
     
        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <h3 className='card-title'>
                            Xref List

                        </h3>
                    </div>
                    <div className="card-body">
                       
                        {this.renderTable()}
                        {this.renderPagination()}
                     
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        const startIndex = parseInt(this.props.match.params.Active, 10) || 0;
        this.props.requestXrefListSelect(startIndex);
    }
    
    private renderTable() {
      const  columns = [
            {
                name: "",
                label: "",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Link className='btn btn-icon btn-light-primary btn-square btn-sm mr-2' to={{
                                pathname: '/xref_types',
                                search: `?x=${this.props.xrefListSelect[tableMeta.rowIndex].XrefID}`
                            }}> <EditIcon className="avoid-clicks" /> </Link>
                        )

                    },

                },
            },
            {
                name: "XrefName",
                label: "Name",
                options: {
                    filter: true,
                    sort: true,
                },

            }, {
                name: "XrefDesc",
                label: "Description",
                options: {
                    filter: true,
                    sort: true,
                },

            },


        ];


        const options = {
            filterType: 'checkbox' as any,

            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        
        if (this != undefined && this.props.xrefListSelect != undefined && this.props.isLoading == false ) {
            return (
                
                <MUIDataTable
                    title={""}
                    data={this.props.xrefListSelect}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {

        return (
            <div className="">

                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.xrefListSelect, // Selects which state properties are merged into the component's props
    XrefListSelectStore.actionCreators // Selects which action creators are merged into the component's props
)(XrefListSelectData as any);
