
/* eslint-disable no-console */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Redirectionapp from './history';

import fuel_login_newLogo from '../fuelloginnewLogo.jpg';
import "./login.css";
import $ from 'jquery';


class ResetPassword extends Component {
    constructor() {
        super();

        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            submitted: false,
            isAlreadyLoggedIn: false
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {
            match: {
                params: { token },
            },
        } = this.props;
        $('header').addClass('d-none');
    }
    onInputchange(event) {


        this.setState({
            [event.target.name]: event.target.value,
        });

    }
    handleChange = name => (event) => {

        this.setState({
            [name]: event.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ submitted: true });
        const { oldPassword,
            newPassword,
            confirmPassword } = this.state;
        const requestOptions = {
            method: 'POST',

            body: JSON.stringify('ok')
        };
        if (oldPassword == "" | confirmPassword == "" || newPassword == "" || newPassword != confirmPassword) {
            return false;
        }

        fetch('Signin/PasswordReset?OldPassword=' + oldPassword + '&NewPassword=' + newPassword, requestOptions).then(response => response.json())
            .then(

                data => {
                    if (data.message == "success") {
                        this.setState({
                            isAlreadyLoggedIn: true,
                        });
                    } else {
                        alert(data.message);
                    }

                }).catch(error => {
                    console.error('Error during request:', error);
                });
    };

    render() {



        const { oldPassword,
            newPassword,
            confirmPassword, submitted, isAlreadyLoggedIn } = this.state;
        //<Redirect to="/" /> : ""
        if (isAlreadyLoggedIn == true) {
            //<Redirect to="/" />
            return <Redirectionapp />;
        }
        return (
            <div className="h-100 addBackImg">
                <div className="loginpagedivwidthcontainer">
                    <div className='  p-3'>
                        <div className="text-center">
                            <div className="mb-16">
                                <a href="#">
                                    <img alt="logo" className='kt-header__brand-logo-default' width="80" src={fuel_login_newLogo} />
                                </a>
                            </div>
                            <div className="kt-login__head mb-12">
                                <h3 className="kt-login__title">Reset Password</h3>
                            </div>
                        </div>
                    </div>
                    <div className='p-3'>
                        <form name="form">
                            <div className={'form-group ' + (submitted && !oldPassword ? ' has-error' : '')}>
                                <input type="password" className='form-control rounded-sm form-control-solid form-control-lg' name="oldPassword" placeholder="Old Password" onBlur={this.onInputchange} />
                                {submitted && !oldPassword &&
                                    <div className="help-block text-danger">Required</div>
                                }
                            </div>
                            <div className={'form-group ' + (submitted && !newPassword ? ' has-error' : '')}>
                                <input type="password" className='form-control rounded-sm form-control-solid form-control-lg' placeholder="New Password" name="newPassword" onBlur={this.onInputchange} />
                                {submitted && !newPassword &&
                                    <div className="help-block text-danger">Required</div>
                                }
                            </div>
                            <div className={'form-group ' + (submitted && !confirmPassword ? ' has-error' : '')}>
                                <input type="password" className='form-control rounded-sm form-control-solid form-control-lg' placeholder="Confirm Password" name="confirmPassword" onBlur={this.onInputchange} />
                                {submitted && !confirmPassword &&
                                    <div className="help-block text-danger">Required</div>
                                }
                                {newPassword != confirmPassword &&
                                    <div className="help-block text-danger">Password does not match</div>
                                }
                            </div>
                            <div className='form-group text-center mt-10'>
                                <button onClick={this.handleSubmit} className="btn btn-primary btn-shadow rounded-sm px-10 py-4 mr-2">OK</button>
                                <a href="/Access_denied" className="btn btn-danger  btn-shadow rounded-sm px-10 py-4">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword;