import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button } from 'reactstrap';

import { Link, Route } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as XrefTypesSiteListStore from '../store/XrefTypeSiteList';
import * as QueryString from "query-string"
import $ from 'jquery';
import MUIDataTable from "mui-datatables";

import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
import EditIcon from '@material-ui/icons/Edit';

// At runtime, Redux will merge together...
type XrefTypeSiteListProps =
    XrefTypesSiteListStore.XrefTypeSiteListState // ... state we've requested from the Redux store
    & typeof XrefTypesSiteListStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ x: string, xt: string }>; // ... plus incoming routing parameters


class XrefTypeSiteListData extends React.PureComponent<XrefTypeSiteListProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {

        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {

        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className='card-label'>

                            </h3>
                        </div>
                        <div className='card-toolbar'>
                            {this.renderButton()}
                        </div>
                    </div>
                    <div className="card-body">


                        {this.renderTable()}
                        {this.renderPagination()}
                    </div>
                </div>

            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        const parsed = QueryString.parse(this.props.location.search)
        console.log(this.props.location.search)
        if (parsed != null && parsed != undefined) {
            
            var x = parseInt(parsed.x == null ? "0" : parsed.x.toString()) || 0;
            var xt = parseInt(parsed.xt == null ? "0" : parsed.xt.toString()) || 0;
            if (this.props.XrefTypeSiteList == undefined || this.props.XrefTypeSiteList.xrefID != x || this.props.XrefTypeSiteList.xrefTypeID != xt) {
                
                this.props.requestXrefTypeSiteList(x, xt);
            }
        }
    }
    private renderButton() {

        if (this != undefined && this.props.XrefTypeSiteList != undefined) {

            return (
                <a className='btn btn-danger' href={`/xref_Types?x=${this.props.XrefTypeSiteList.xrefID}`}>Exit</a>
            );
        }
    }

    private renderTable() {
        const columns = [
            {
                name: " ",
                label: " ",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        ////console.log(tableMeta)
                        return (
                            <Link className='btn btn-icon btn-outline-success btn-circle btn-sm mr-2' to={{
                                pathname: '/xref_Types_SiteList_Site',
                                search: `?x=${this.props.XrefTypeSiteList.xrefID}&xt=${this.props.XrefTypeSiteList.xrefTypeID}&s=${this.props.XrefTypeSiteList.typeJson[tableMeta.rowIndex].siteID}`,
                            }}> <EditIcon className="avoid-clicks" /> </Link>
                        )
                    }
                }
            }, {
                name: "siteDesc",
                label: "Site",

            },
            {
                name: "siteOverrides",
                label: "Overrides",

            },

        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this != undefined && this.props.XrefTypeSiteList != undefined && this.props.XrefTypeSiteList.typeJson != undefined && this.props.isLoading == false) {

            return (

                <MUIDataTable
                    title={""}
                    data={this.props.XrefTypeSiteList.typeJson}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {

        return (
            <div className="d-flex justify-content-between">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.xrefTypeSiteList,
    // Selects which state properties are merged into the component's props
    XrefTypesSiteListStore.actionCreators // Selects which action creators are merged into the component's props
)(XrefTypeSiteListData as any);
