
/* eslint-disable no-console */
import React, { Component } from 'react';
import $ from 'jquery';
import { authHeader } from '../components/AuthHeader';
import Apphistoryshow from './history';
import MUIDataTable from "../MUI";
import { ApiUrl } from "../components/Constant";
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';


class Agent extends Component {
    constructor() {
        super();

        this.state = {
            Agents: [
                //{
                //AgentID: '',
                //AgentName: '',
                //Address1: '',
                //City: '',
                //StateName: '',
                //Zip: '',
                //Selected: false
                //}
            ]
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

       
        fetch(ApiUrl +'Socket/AgentList')
            .then((response) => {
                
                if (response.status == 401) {
                    $("#btnLogout").click();
                    return false;
                }
                else if (!response.ok) {
                    alert(response.text);
                    return false;
                }
                else
                    return response.json();
            })
            .then(

                data => {
                  
                    if (data.message.includes("Error")) {
                        alert(data.message);
                        this.setState({
                            Agents: []
                        })
                    } else {
                        this.setState({
                            Agents: JSON.parse(data.message)
                        })
                    }

                }).catch(
                    data => {

                        this.setState({
                            Agents: []
                        })

                    }
                );
    }
    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.checked,
        });
        this.state.Agents[event.target.name].Selected = event.target.checked
    }


    handleSubmit = (e) => {
        e.preventDefault();

        const { Agents } = this.state;
        var data = Agents.filter(i => i.Selected == true).map(i => i.AgentID);
        const requestOptions = {
            method: 'POST',
            headers: authHeader(JSON.stringify(data)),
            body: JSON.stringify('ok')
        };


        fetch(ApiUrl+'Agent/AgentConnectorUpdate', requestOptions).then(response => response.json())
            .then(

                data => {
                    if (data.message == "success") {

                        $('.closepopupbtn').trigger('click');
                        window.location.reload(false);
                    } else {
                        alert(data.message);
                    }

                });
    };


    render() {


        const columns = [
            {
                name: "Selected",
                label: " ",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        
                        return (
                            <div>
                              
                                <input type='checkbox' name={tableMeta.rowIndex} defaultChecked={value} onChange={e => this.onInputchange(e)} />
                            </div>
                        )
                    },
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "AgentName",
                label: "Agent Name",
                options: {
                    filter: true,
                    sort: true,

                },

            },
            {
                name: "Address1",
                label: "Address",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "City",
                label: "City",
                options: {
                    filter: false,
                    sort: false,

                }
            },
            {
                name: "StateName",
                label: "State",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Zip",
                label: "Zip",
                options: {
                    filter: false,
                    sort: false,
                }
            }

        ];


        const options = {
            filter: true,
            pagination: false,
            selectableRows: 'none',

            filterType: "textField",
            fixedHeader: true,
            sort: true,
            search: true,
            //selectableRows: "multiple",
            responsive: "scrollMaxHeight",
            //rowsPerPage: 15,
            rowHove: true,
           // selectableRowsHeader: true,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),
          
        };

        return (

            <div className="">
                <div>
                    <div className='card card-custom gutter-b'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3 className='card-label'>
                                    FIOS Agents
                                </h3>
                            </div>
                            <div className='card-toolbar'>
                                <button className='btn btn-primary mr-2 pull-right' onClick={this.handleSubmit}>Add</button>

                            </div>
                        </div>
                        <div className="card-body">

                            <MUIDataTable
                                title={" "}
                                data={this.state.Agents}
                                columns={columns}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Agent;