import React from 'react';
import { useHistory } from 'react-router-dom';

const Apphistoryshow = (props) => {
    const history = useHistory();
    
    let def = props.def
    history.push(def == "" ? "/" : def);
    return (
        <></>
    );
}

export default (Apphistoryshow);