import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ApplicationState } from '../store';
import Apphistoryshow from './history';
import * as LoginStore from '../store/Login';
import fuel_login_newLogo from '../fuelloginnewLogo.jpg';
import "./login.css";
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// At runtime, Redux will merge together...
type LoginProps =
    LoginStore.LoginState // ... state we've requested from the Redux store
    & typeof LoginStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ x: string }>; // ... plus incoming routing parameters


class LoginData extends React.PureComponent<LoginProps> {
    // This method is called when the component is first added to the document
    userTheme = false;
    public componentDidMount() {
        this.ensureDataFetched();
        $(".headerName").addClass('d-none');
    }
    state = {
        submitted: false,
        isRestPassword: false,
        redirect: false,
    }



    constructor(props: Readonly<LoginProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        if (window.location.pathname == "/login") {
            require('./login.css')

        }


    }



    handleSubmit(event: { preventDefault: () => void; }) {


        event.preventDefault();
        this.setState({ submitted: true });
        const requestOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'data': JSON.stringify(this.props.LoginModel)
            },
            body: JSON.stringify('ok')
        };
        fetch(`${ApiUrl}Signin/authenticate`, requestOptions).then(response => response.json())
            .then(

                data => {

                    if (data && data.Token) {
                        //data.Header = false;
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('user', JSON.stringify(data));

                        $(".headerName").removeClass('d-none');
                        if (data.IsResetPwd == true) {

                            this.setState({ isRestPassword: true });
                        } else {
                            
                            this.setState({ redirect: true });
                        }

                    }

                }).catch(error => {
                    console.error('Error during request:', error);
                });
    }
    onInputchange(event: any) {


        switch (event.target.name) {

            case "UserName":
                this.props.LoginModel.UserName = event.target.value;
                break;
            case "Password":
                this.props.LoginModel.Password = event.target.value;
                break;
            case "IsSaveToCookie":
                this.props.LoginModel.IsSaveToCookie = event.target.checked;
                break;

            default:
        }

    }
    // This method is called when the route parameters change
    public componentDidUpdate() {
        //this.ensureDataFetched();
    }

    public render() {

        const { redirect } = this.state;
        if (redirect) {
            
            return <Apphistoryshow def="/" />
        }
        const { isRestPassword } = this.state;
        if (isRestPassword) {

            return <Apphistoryshow def="/PasswordReset" />
        }
        return (
            <React.Fragment>
                <div className="h-100 addBackImg">
                    {this.renderSiteViewElement()}
                </div>
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.requestLogin(0);
        //return <Redirect to={'/'} />;
        // return <Apphistoryshow />
    }

    private renderSiteViewElement() {

        if (this.props.LoginModel != undefined) {
            const submitted = this.state;
            return (

                <div className="loginpagedivwidthcontainer">

                    <div className='  p-3'>

                        <div className="text-center">
                            <div className="mb-16">
                                <a href="">
                                    <img alt="logo" className='kt-header__brand-logo-default' width="80" src={fuel_login_newLogo} />
                                </a>
                            </div>
                            <div className="kt-login__head mb-12">
                                <h3 className="kt-login__title">Sign In To FIOS</h3>
                            </div>
                        </div>
                    </div>
                    <div className='p-3'>

                        <div className='form-group mb-6'>
                            <input type='text' className='form-control rounded-sm form-control-solid form-control-lg' placeholder="User Name" onBlur={e => this.onInputchange(e)} defaultValue={this.props.LoginModel.UserName} id='UserName' name='UserName' />
                            {submitted && !this.props.LoginModel.UserName &&
                                <div className="help-block text-danger">Username is required</div>
                            }
                        </div>
                        <div className='form-group mb-6'>
                            <input type='password' className='form-control rounded-sm form-control-solid form-control-lg' placeholder="Password" onBlur={e => this.onInputchange(e)} defaultValue={this.props.LoginModel.Password} id='Password' name='Password' />
                            {submitted && !this.props.LoginModel.Password &&
                                <div className="help-block text-danger">Password is required</div>
                            }
                        </div>
                        <div className='form-group mb-6'>
                            <label className="checkbox checkbox-outline checkbox-primary">
                                <input type='checkbox' name='IsSaveToCookie' onChange={e => this.onInputchange(e)} defaultChecked={this.props.LoginModel.IsSaveToCookie} />
                                <span className='mr-3'></span>
                                        Remember me
                                     </label>
                        </div>
                        <div className='form-group text-center mt-10'>
                            <button className="btn btn-primary btn-shadow rounded-sm px-10 py-4" onClick={this.handleSubmit}>Sign in</button>

                        </div>

                    </div>
                </div>
            );
        }
    }


}

export default connect(
    (state: ApplicationState) => state.login,
    // Selects which state properties are merged into the component's props
    LoginStore.actionCreators // Selects which action creators are merged into the component's props
)(LoginData as any);
