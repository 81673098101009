import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface XrefTypeState {
    isLoading: boolean;
    x?: number;
    XrefType:   XrefTypeItem;
}
export interface XrefTypeItem {
    xrefID: number;
    pageTitle: string;
    onlyOverride: boolean;
    onlyGlobal: boolean;
    typeJson: XrefType[];
}

export interface XrefType {
    XrefTypeID: number;
    XrefGroupName: string;
    XrefTypeName: string;
    OnlyOverride: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestXrefTypeAction {
    type: 'REQUEST_DATA';
    x: number;
}

interface ReceiveXrefTypeAction {
    type: 'RECEIVE_DATA';
    x: number;
    XrefType: XrefTypeItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestXrefTypeAction | ReceiveXrefTypeAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestXrefType: (x: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.xrefType && x !== appState.xrefType.x) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`${ApiUrl}xref/xref_types?x=${x}`, requestOptions)
                .then((response) =>
                {
                    if (response.status == 401)
                    {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok)
                    {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                       debugger
                        if (data.typeJson == null) {
                            data.typeJson = [];
                        } else {

                            data.typeJson = JSON.parse(data.typeJson);
                        }
                        dispatch({ type: 'RECEIVE_DATA', x: x, XrefType: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', x: x });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: XrefTypeState = { XrefType: { xrefID: 0, typeJson: [], onlyGlobal: false, onlyOverride: false, pageTitle: "" }, isLoading: false, x:-1 };

export const reducer: Reducer<XrefTypeState> = (state: XrefTypeState | undefined, incomingAction: Action): XrefTypeState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                x: action.x,
                XrefType: state.XrefType,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.x === state.x) {
                return {
                    x: action.x,
                    XrefType: action.XrefType,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
