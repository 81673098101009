import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface XrefTypesSiteListSiteState {
    isLoading: boolean;
    x: number;
    xt: number;
    s: number;
    XrefTypesSiteListSite: XrefTypesSiteListSiteItem;
}
export interface XrefTypesSiteListSiteItem {
    xrefID: number;
    xrefTypeID: number;
    pageHeader: string;
    siteID: number;
    siteDescription: string;
    typeJson: XrefTypesSiteListSite[];
    sites_List:  Xref_Types_SiteList_Site_QueryInternal_List[];
}
export interface Xref_Types_SiteList_Site_QueryInternal_List {
    Value: string;
    ValueDesc: string;
}

export interface XrefTypesSiteListSite {
    intValue: string;
    extValue: string;
    extDescription: string;
    defaultValue: string;
    state: string;
    xrefDetailID?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestXrefTypesSiteListSiteAction {
    type: 'REQUEST_DATA';
    x: number;
    xt: number;
    s: number;
}

interface ReceiveXrefTypesSiteListSiteAction {
    type: 'RECEIVE_DATA';
    x: number;
    xt: number;
    s: number;
    XrefTypesSiteListSite: XrefTypesSiteListSiteItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestXrefTypesSiteListSiteAction | ReceiveXrefTypesSiteListSiteAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestXrefTypesSiteListSite: (x: number, xt: number, s: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.xrefTypesSiteListSite && (x !== appState.xrefTypesSiteListSite.x || xt !== appState.xrefTypesSiteListSite.xt || xt !== appState.xrefTypesSiteListSite.xt || s !== appState.xrefTypesSiteListSite.s)) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };

            fetch(`${ApiUrl}xref/xref_Types_SiteList_Site?x=${x}&xt=${xt}&s=${s}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(
                    
                    data => {
                        if (data.sites == null) {
                            data.sites = [];
                        } else {

                            data.sites_List = JSON.parse(data.sites);
                        }
                        data.sites = '';
                       
                        dispatch({ type: 'RECEIVE_DATA', x: x, xt: xt, s: s, XrefTypesSiteListSite: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });
            
            dispatch({ type: 'REQUEST_DATA', x: x, xt: xt, s: s });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: XrefTypesSiteListSiteState = { XrefTypesSiteListSite: { xrefID: -1,sites_List:[], xrefTypeID: -1, typeJson: [], pageHeader: "", siteDescription: "", siteID: -1 }, isLoading: false, x: -1, xt: -1, s: -1 };

export const reducer: Reducer<XrefTypesSiteListSiteState> = (state: XrefTypesSiteListSiteState | undefined, incomingAction: Action): XrefTypesSiteListSiteState => {
    if (state === undefined) {
        return unloadedState;
    }
    
    const action = incomingAction as KnownAction;
    
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                x: action.x,
                xt: action.xt,
                s: action.s,
                XrefTypesSiteListSite: state.XrefTypesSiteListSite,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.x === state.x && action.xt === state.xt && action.s === state.s) {
                return {
                    x: action.x,
                    xt: action.xt,
                    s: action.s,
                    XrefTypesSiteListSite: action.XrefTypesSiteListSite,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
