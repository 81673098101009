import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as GasBuddyStatusListStore from '../store/GasBuddyStatus';
import { Input } from 'reactstrap';
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type GasBuddyStatusListProps =
    GasBuddyStatusListStore.GasBuddyStatusState // ... state we've requested from the Redux store
    & typeof GasBuddyStatusListStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ Active: string }>; // ... plus incoming routing parameters


class GasBuddyStatusListData extends React.PureComponent<GasBuddyStatusListProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {
        
        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <h3 className='card-title'>
                            Fuel Price Status
                        </h3>
                    </div>
                    <div className="card-body">

                        {this.renderGasBuddyStatusElement()}
                        {this.renderGasBuddyStatusTable()}
                        {this.renderPagination()}
                       
                    </div>
                </div>
               
             
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        const startDateIndex = parseInt(this.props.match.params.Active, 10) || 0;
        this.props.requestGasBuddyStatus(startDateIndex);
    }
    private renderGasBuddyStatusElement() {

        if (this != undefined && this.props.GasBuddyStatus != undefined) {

            return (
                <div>

                    <b>Summary</b>
                    <table className="lstTbl">

                        <tr>
                            <td><b>Current Status:</b></td>
                            <td><label>{this.props.GasBuddyStatus.currentStatus}</label></td>
                        </tr>
                        <tr>
                            <td><b>Last Price Changed:</b></td>
                            <td><label>{this.props.GasBuddyStatus.lastPriceChanged} </label></td>
                        </tr>
                        <tr>
                            <td><b>Price Changes in Last Seven Days:</b></td>
                            <td><label>{this.props.GasBuddyStatus.priceChangesLastSevenDays} </label></td>
                        </tr>
                    </table>


                    <b>Price</b>
                    <table className="lstTbl">

                        <tr>
                            <td><b>Regular:</b></td>
                            <td><label>{this.props.GasBuddyStatus.reg}</label></td>
                        </tr>
                        <tr>
                            <td><b>Mid-Grade:</b></td>
                            <td><label>{this.props.GasBuddyStatus.mid}</label></td>
                        </tr>
                        <tr>
                            <td><b>Premium:</b></td>
                            <td><label>{this.props.GasBuddyStatus.prem}</label></td>
                        </tr>
                        <tr>
                            <td><b>Deisel:</b></td>
                            <td><label>{this.props.GasBuddyStatus.deisel}</label></td>
                        </tr>
                    </table >


                    <b>Last Batch</b>
                    <table className="lstTbl">
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><b>Export Batch ID:</b></td>
                            <td><label>{this.props.GasBuddyStatus.exportBatchID}</label></td>
                        </tr>
                        <tr>
                            <td><b>Start:</b></td>
                            <td><label>{this.props.GasBuddyStatus.dteBatchStart}</label></td>
                        </tr>
                        <tr>
                            <td><b>End:</b></td>
                            <td><label>{this.props.GasBuddyStatus.dteBatchEnd}</label></td>
                        </tr>
                        <tr>
                            <td><b>Success:</b></td>
                            <td><label>{this.props.GasBuddyStatus.success}</label></td>
                        </tr>
                    </table >


                </div>
            );
        }
    }
    //private loadtable() {

    //    setTimeout(function () {

    //        ($('.datatble') as any).DataTable(  );
    //    }, 1000);


    //}
    //private loadtablenoview() {

    //    setTimeout(function () {

    //        ($('.datatblezero') as any).DataTable();
    //    }, 1000);

    //}
    private renderGasBuddyStatusTable() {

      const  columns = [
            {
                name: "dteComplete",
                label: "Description",
                options: {
                    filter: true,
                    sort: true,
                },

            }, {
                name: "Success",
                label: "Overrides",
                options: {
                    filter: true,
                    sort: true,
                },

            },


        ];


        const options = {
            filterType: 'checkbox' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };

        if (this != undefined && this.props.GasBuddyStatus != undefined && this.props.GasBuddyStatus.typeJson != undefined) {

            return (
                //<table className='table table-bordered datatble' aria-labelledby="tabelLabel">
                //    <thead>
                //        <tr>
                //            <th></th>
                //            <th>Description</th>
                //            <th>Overrides</th>
                //        </tr>
                //    </thead>
                //    <tbody>
                //        {this.props.GasBuddyStatus.typeJson.map((item: GasBuddyStatusListStore.GasBuddyStatus) =>
                //            <tr key={item.StoreNumber}>
                //                <td>{item.dteComplete}</td>
                //                <td>{item.Success}</td>
                //            </tr>
                //        )}
                //    </tbody>
                //</table>
                <MUIDataTable
                    title={" Fuel Price Status"}
                    data={this.props.GasBuddyStatus.typeJson}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {
        const prevStartDateIndex = (this.props.Active || 0) - 5;
        const nextStartDateIndex = (this.props.Active || 0) + 5;

        return (
            <div className="">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.gasBuddyStatus,
    // Selects which state properties are merged into the component's props
    GasBuddyStatusListStore.actionCreators // Selects which action creators are merged into the component's props
)(GasBuddyStatusListData as any);
