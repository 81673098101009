import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface XrefListSelectState {
    isLoading: boolean;
    Active?: number;
    xrefListSelect: XrefSelectList[];
}

export interface XrefSelectList {
    XrefID: string;
    XrefName: string;
    XrefDesc: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestXrefListSelectAction {
    type: 'REQUEST_DATA';
    Active: number;
}

interface ReceiveXrefListSelectAction {
    type: 'RECEIVE_DATA';
    Active: number;
    xrefListSelect: XrefSelectList[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestXrefListSelectAction | ReceiveXrefListSelectAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestXrefListSelect: (Active: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.xrefListSelect && Active !== appState.xrefListSelect.Active) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`${ApiUrl}xref/xref_list_select`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                        if (data == null || data == "[]") {
                            dispatch({ type: 'RECEIVE_DATA', Active: Active, xrefListSelect: [] });
                        } else {
                            dispatch({ type: 'RECEIVE_DATA', Active: Active, xrefListSelect: JSON.parse(data) });
                        }
                        
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', Active: Active });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: XrefListSelectState = { xrefListSelect: [], isLoading: false };

export const reducer: Reducer<XrefListSelectState> = (state: XrefListSelectState | undefined, incomingAction: Action): XrefListSelectState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                Active: action.Active,
                xrefListSelect: state.xrefListSelect,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.Active === state.Active) {
                return {
                    Active: action.Active,
                    xrefListSelect: action.xrefListSelect,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
