import React from 'react';
import "./accessdenied.css";
import $ from 'jquery';
class Access_denied extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        $('header').addClass('d-none');
    }

    render() {
        return (
            <div className="container-fluid containeraccess">
                <div className="accesscontent">
                    <h1>4<div className="lock"><div className="top"></div><div className="bottom"></div>
                    </div>3</h1><p>Access denied</p>
                    <p>The user "" does not have proper rights to access FIOS. </p>
                </div>
            </div>
        )
    }
}


export default Access_denied;
