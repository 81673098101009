import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { authHeader } from '../components/AuthHeader';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as SiteCompetitorFuelGradesStore from '../store/SiteCompetitorFuelGrades';
import { Input } from 'reactstrap';
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type SiteCompetitorFuelGradesProps =
    SiteCompetitorFuelGradesStore.SiteCompetitorFuelGradesState // ... state we've requested from the Redux store
    & typeof SiteCompetitorFuelGradesStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ SID: string }>; // ... plus incoming routing parameters


class SiteCompetitorFuelGradesData extends React.PureComponent<SiteCompetitorFuelGradesProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {

        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }
    constructor(props: Readonly<SiteCompetitorFuelGradesProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });

    }
    handleSubmit(event: { preventDefault: () => void; }) {

        var data = this.props.SiteCompetitorFuelGrades;
        //data.sites_List = [];

        //data.typeJson = data.typeJson.filter(i => i.state == 'Modified');
        event.preventDefault();
        
        const requestOptions = {
            method: 'POST',
            //headers: {
            //    Accept: 'application/json',
            //    'Content-Type': 'application/json',
            //    'data': JSON.stringify(data)
            //},
            headers: authHeader(JSON.stringify(data)) as string[][],
            body: JSON.stringify("ok")
        };
        fetch('xref/xref_Types_SetDefault_Post', requestOptions).catch(error => {
            console.error('Error during request:', error);
        });
    }

  
    public render() {
  
        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                        <h3 id="tabelLabel" className='card-label'>
                                Fuel Price Status
                        </h3>
                        </div>
                        <div className='card-toolbar'>
                            <button className='btn btn-primary'>Done</button>
                            <button className='btn btn-danger'>Exit</button>
                        </div>
                    </div>
                    <div className="card-body">

                        {this.renderSiteCompetitorFuelGradesElement()}
                        {this.renderSiteCompetitorFuelGradesTable()}
                        {this.renderPagination()}
                     
                    </div>
                </div>

             
               
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        const startDateIndex = parseInt(this.props.match.params.SID, 10) || 0;
        this.props.requestSiteCompetitorFuelGrades(startDateIndex);
    }
    private renderSiteCompetitorFuelGradesElement() {

        if (this != undefined && this.props.SiteCompetitorFuelGrades != undefined) {

            return (
                <div>
                    <button type="submit" name="Done" onClick={this.handleSubmit} className='btn btn-success text-white'>
                        Done
                        </button>
                    <table className="lstTbl">

                        <tr>
                            <td><b>Competitor Name:</b></td>
                            <td><label>{this.props.SiteCompetitorFuelGrades.competitor}</label></td>
                        </tr>
                        <tr>
                            <td><b>Last Address:</b></td>
                            <td><label>{this.props.SiteCompetitorFuelGrades.address} </label></td>
                        </tr>

                    </table>


                    <table className="lstTbl">

                        <tr>
                            <td><b>Our Product:</b></td>
                            <td>
                                <select onChange={e => this.onInputchange(e)}>
                                    {this.props.SiteCompetitorFuelGrades.ourProduct.map((e, key) => {
                                        return <option key={key} value={e.ProductID}>{e.ProductID}</option>;
                                    })}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Comp Product:</b></td>
                            <td><input type="text" className="form-control" id="txtCompProdcuct" /></td>
                        </tr>
                        <tr>
                            <td>
                                <button type="submit" name="Add" onClick={this.handleSubmit} className='btn btn-success text-white'>
                                    Add
                        </button>
                            </td>
                        </tr>
                    </table >

                </div>
            );
        }
    }
    //private loadtable() {

    //    setTimeout(function () {

    //        ($('.datatble') as any).DataTable();
    //    }, 1000);


    //}
    //private loadtablenoview() {

    //    setTimeout(function () {

    //        ($('.datatblezero') as any).DataTable();
    //    }, 1000);

    //}
    private renderSiteCompetitorFuelGradesTable() {
      const  columns = [
            {
                name: "Our_Prod",
                label: "Our Prod",
                options: {
                    filter: true,
                    sort: true,
                },

            }, {
                name: "Comp_Prod_Name",
                label: "Comp Prod Name",
                options: {
                    filter: true,
                    sort: true,
                },

            },


        ];


        const  options = {
            filterType: 'checkbox' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };

        if (this != undefined && this.props.isLoading == false && this.props.SiteCompetitorFuelGrades != undefined && this.props.SiteCompetitorFuelGrades.site_Competitor_Grades_Select != undefined) {

            return (
                //<table className='table table-bordered datatble' aria-labelledby="tabelLabel">
                //    <thead>
                //        <tr>
                //            <th></th>
                //            <th>Our Prod</th>
                //            <th>Comp Prod Name</th>
                //        </tr>
                //    </thead>
                //    <tbody>
                //        {this.props.SiteCompetitorFuelGrades.site_Competitor_Grades_Select.map((item: SiteCompetitorFuelGradesStore.site_Competitor_Grades_Select) =>
                //            <tr key={item.CompGradeID}>
                //                <td>
                                 
                //                </td>
                //                <td>{item.Our_Prod}</td>
                //                <td>{item.Comp_Prod_Name}</td>
                //            </tr>
                //        )}
                //    </tbody>
                //</table>
                <MUIDataTable
                    title={"Fuel Price Status"}
                    data={this.props.SiteCompetitorFuelGrades.site_Competitor_Grades_Select}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {

        return (
            <div className="d-flex justify-content-between">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.siteCompetitorFuelGrades,
    // Selects which state properties are merged into the component's props
    SiteCompetitorFuelGradesStore.actionCreators // Selects which action creators are merged into the component's props
)(SiteCompetitorFuelGradesData as any);
