import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as XrefTypesSiteItemsStore from '../store/XrefTypesSiteItems';
import * as QueryString from "query-string"
import $ from 'jquery';
import { authHeader } from '../components/AuthHeader';
import MUIDataTable from "mui-datatables";
import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type XrefTypesSiteItemsProps =
    XrefTypesSiteItemsStore.XrefTypesSiteItemsState // ... state we've requested from the Redux store
    & typeof XrefTypesSiteItemsStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ x: string, xt: string, ev: string,ReturnUrl:string }>; // ... plus incoming routing parameters


class XrefTypesSiteItemsData extends React.PureComponent<XrefTypesSiteItemsProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        console.log('componentDidMount')
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {

        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <h3 className='card-title'>
                            {this.props.XrefTypesSiteItems == null ? "" : this.props.XrefTypesSiteItems.pageHeader}
                        </h3>
                    </div>
                    <div className="card-body">

                        {this.renderElement()}
                        {this.renderTable()}
                        {this.renderPagination()}
                    </div>
                </div>
             
            </React.Fragment>
        );
    }

    private ensureDataFetched() {

        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined) {

            var x = parseInt(parsed.x == null ? "0" : parsed.x.toString()) || 0;
            var xt = parseInt(parsed.xt == null ? "0" : parsed.xt.toString()) || 0;
            var s = parseInt(parsed.s == null ? "0" : parsed.s.toString()) || 0;
            var ReturnUrl = parsed.ReturnUrl == null ? "" : parsed.ReturnUrl.toString();
            this.props.requestXrefTypesSiteItems(x, xt, s, ReturnUrl);
        }
    }

    constructor(props: Readonly<XrefTypesSiteItemsProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });

        this.props.XrefTypesSiteItems.typeJson[event.target.name].intValue = event.target.value;
        this.props.XrefTypesSiteItems.typeJson[event.target.name].state = "Modified";
    }
    handleSubmit(event: { preventDefault: () => void; }) {

        var data = this.props.XrefTypesSiteItems;
        data.sites_List = [];

        data.typeJson = data.typeJson.filter(i => i.state == 'Modified');
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: authHeader(JSON.stringify(data)) as string[][],
            body: JSON.stringify("ok")
        };
        fetch('xref/xref_Types_SiteItems_Post', requestOptions).catch(error => {
            console.error('Error during request:', error);
        });
    }

    private renderElement() {

        if (this != undefined && this.props.XrefTypesSiteItems != undefined) {

            return (
                <div>
                    
                 
                    <div className='btn-group mb-3' role='group' >
                        <button type="submit" onClick={this.handleSubmit} className='btn btn-success text-white'>
                            Save
                        </button>
                        <button type="button" className='btn btn-danger text-white'>

                            <Link className='text-white' to={{
                                pathname: '/xref_Types_Items',
                                search: `?x=${this.props.XrefTypesSiteItems.xrefID}&xt=${this.props.XrefTypesSiteItems.xrefTypeID}`,
                            }}> Exit</Link>
                        </button>
                    </div>
                    <table className="lstTbl table table-bordered">

                        <tr>
                            <td><b>Site:</b></td>
                            <td><label>{this.props.XrefTypesSiteItems.siteDescription}</label></td>
                        </tr>
                        <tr>
                            <td><b>Xref Type:</b></td>
                            <td><label>{this.props.XrefTypesSiteItems.xrefTypeName} </label></td>
                        </tr>
                        
                    </table>
                   
                </div>
            );
        }
    }
    
    private renderTable() {
        const columns = [

            {
                name: "extValue",
                label: "External Value",

            },
            {
                name: "extName",
                label: "External Description",

            },

            {
                name: "",
                label: "Agility Description",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        //console.log(tableMeta)
                        return (
                           
                            <select onChange={e => this.onInputchange(e)} name={tableMeta.rowIndex.toString()} value={this.props.XrefTypesSiteItems.typeJson[tableMeta.rowIndex].intValue } >
                                        {this.props.XrefTypesSiteItems.sites_List.map((e, key) => {
                                            return <option key={key} value={e.Value}>{e.ValueDesc}</option>;
                                        })}
                                    </select>

                        )
                    }
                }

            },



        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this != undefined && this.props.XrefTypesSiteItems != undefined && this.props.XrefTypesSiteItems.typeJson != undefined && this.props.isLoading == false) {

            return (
                
                <MUIDataTable
                    title={this.props.XrefTypesSiteItems == null ? "" : this.props.XrefTypesSiteItems.pageHeader}
                    data={this.props.XrefTypesSiteItems.typeJson}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {

        return (
            <div className="">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.xrefTypesSiteItems,
    // Selects which state properties are merged into the component's props
    XrefTypesSiteItemsStore.actionCreators // Selects which action creators are merged into the component's props
)(XrefTypesSiteItemsData as any);
