
/* eslint-disable no-console */
import React, { Component } from 'react';
import $ from 'jquery';
import { authHeader } from '../components/AuthHeader';
import { ApiUrl } from '../components/Constant';
import Apphistoryshow from './history';
import MUIDataTable from "../MUI";
import EditIcon from '@material-ui/icons/Edit';
import { Link, Route } from 'react-router-dom';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';


class Plugins extends Component {
    constructor() {
        super();

        this.state = {
            PluginList: [
                
            ]
        };
    }

    componentDidMount() {
        
        const requestOptions = {
            method: 'GET',
            headers: authHeader('ok'),
        };
        
        fetch(ApiUrl+'Plugin/plugin_List', requestOptions)
            .then((response) => {
                
                if (response.status == 401) {
                    $("#btnLogout").click();
                    return false;
                }
                else if (!response.ok) {
                    alert(response.text);
                    return false;
                }
                else
                    return response.json();
            })
            .then(

                data => {
                    
                    if (data == null) {
                        this.setState({
                            PluginList: []
                        })
                    } else {

                        this.setState({
                            PluginList: JSON.parse(data)
                        })
                    }

                }).catch(
                    data => {

                        this.setState({
                            PluginList: []
                        })

                    }
                );
    }
   
    render() {


        const columns = [
            {
                name: " ",
                label: " ",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        ////console.log(tableMeta)
                        return (
                            <Link className='btn btn-icon btn-outline-success btn-circle btn-sm mr-2' to={{
                                pathname: '/configuration',
                                search: `?ID=${this.state.PluginList[tableMeta.rowIndex].PluginConfigID}`,
                            }}> <EditIcon className="avoid-clicks" /> </Link>
                        )
                    }
                }
            },
            {
                name: "PluginConfigID",
                label: "ID",
                options: {
                    filter: true,
                    sort: true,

                },

            },
            {
                name: "PluginConfigName",
                label: "Plugin Config",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "RuntimeTypeName",
                label: "Type",
                options: {
                    filter: true,
                    sort: true,

                }
            },
            {
                name: "PluginName",
                label: "Plugin",
                options: {
                    filter: true,
                    sort: true,
                }
            },{
                name: "SBMsgQueue",
                label: "MsgQueue",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Active",
                label: "Active",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {

                        return (
                            <label>
                                {value == true ? "Yes" : "No"}
                            </label>
                        )
                    },
                    filter: false,
                    sort: true,
                }
            }
        ];


        const options = {
            filter: true,
            fixedHeader: true,
            filterType: 'dropdown',
            //pagination: false,
            selectableRows: 'none',
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),

        };

        return (

            <div className="">
                <div>
                    <div className='card card-custom gutter-b'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3 className='card-label'>
                                    Plugins
                                </h3>
                            </div>
                            <div className='card-toolbar'>
                                <Link className='btn btn-primary' to={{
                                    pathname: '/configuration'
                                }}> New </Link>

                            </div>
                            
                        </div>
                        <div className="card-body">

                            <MUIDataTable
                                title={" "}
                                data={this.state.PluginList}
                                columns={columns}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Plugins;