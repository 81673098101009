import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as XrefTypeStore from '../store/XrefTypes';
import * as QueryString from "query-string"
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import EditIcon from '@material-ui/icons/Edit';

import Backdrop from '@material-ui/core/Backdrop';
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type XrefTypeProps =
    XrefTypeStore.XrefTypeState // ... state we've requested from the Redux store
    & typeof XrefTypeStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ x: string }>; // ... plus incoming routing parameters


class XrefTypeData extends React.PureComponent<XrefTypeProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {

        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <h3 className='card-title'>
                            {this.props.XrefType == null ? "" : this.props.XrefType.pageTitle}
                        </h3>
                    </div>
                    <div className="card-body">

                        {this.renderTable()}
                        {this.renderPagination()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined) {

            var x = parseInt(parsed.x == null ? "0" : parsed.x.toString()) || 0;
            
            this.props.requestXrefType(x);
        }
    }
   
    private renderTable() {
        const columns = [
            {
                name: " ",
                label: " ",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        //console.log(tableMeta)
                        return (
                            <Link className='btn btn-icon btn-light-primary btn-square btn-sm mr-2' to={{
                                        pathname: '/xref_Types_Items',
                                search: `?x=${this.props.XrefType.xrefID}&xt=${this.props.XrefType.typeJson[tableMeta.rowIndex].XrefTypeID}`
                            }}> <EditIcon className="avoid-clicks" /> </Link>
                        )
                    }
                }
            }, {
                name: " ",
                label: " ",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        //console.log(tableMeta)
                        return (
                            <Link className='btn btn-primary btn-sm' to={{
                                        pathname: '/xref_Types_SiteList',
                                search: `?x=${this.props.XrefType.xrefID}&xt=${this.props.XrefType.typeJson[tableMeta.rowIndex].XrefTypeID}`
                                    }}> Edit By Site </Link>
                        )
                    }
                }
            },  {
                name: "XrefTypeName",
                label: "xRef Name",

            },
            {
                name: "XrefGroupName",
                label: "Xref Group Name",

            },

        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this != undefined && this.props.XrefType != undefined && this.props.XrefType.typeJson != undefined && this.props.isLoading == false) {
            
            return (
                
                <MUIDataTable
                    title={""}
                    data={this.props.XrefType.typeJson}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {

        return (
            <div className="d-flex justify-content-between">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.xrefType,
    // Selects which state properties are merged into the component's props
    XrefTypeStore.actionCreators // Selects which action creators are merged into the component's props
)(XrefTypeData as any);
