import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PriceChangeHistoryState {
    isLoading: boolean;
    startIndex?: number;
    PriceChangeHistory: PriceChangeHistory[];
}

export interface PriceChangeHistory {
    FuelPriceID: number;
    lfpFuelPriceID: number;
    Num: number;
    SiteID: number;
    SiteNumber: number;
    ProductNumber: number;
    ProductName: string;
    ProductID: number;
    dteCreated: string;
    dteEffectiveRequest: string;
    fpdteEffectiveActual: string;
    CashPrice: string;
    LastCashPrice: string;
    CashPriceVar: string;
    RequestID: number;
    Implemented: boolean;
    RequestName: string;
    lfp_dteEffectiveActual: string;
    CreditPrice: string;
    CreditPriceVar: string;
    UserID: number;
    dteNotify: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestListsAction {
    type: 'REQUEST_DATA';
    startIndex: number;
}

interface ReceiveListsAction {
    type: 'RECEIVE_DATA';
    startIndex: number;
    PriceChangeHistory: PriceChangeHistory[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestListsAction | ReceiveListsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    requestLists: (startIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();

        if (appState && appState.priceChangeHistory && startIndex !== appState.priceChangeHistory.startIndex) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader('ok') as string[][],
            };
            fetch(ApiUrl + `collector/priceChangeHistory`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {



                        if (data == null || data == "[]") {
                            dispatch({ type: 'RECEIVE_DATA', startIndex: startIndex, PriceChangeHistory: [] });
                        } else {

                            dispatch({ type: 'RECEIVE_DATA', startIndex: startIndex, PriceChangeHistory: JSON.parse(data) });
                        }


                    }).catch(
                        data => {
                            dispatch({ type: 'RECEIVE_DATA', startIndex: startIndex, PriceChangeHistory: [] });
                        }
                    );

            dispatch({ type: 'REQUEST_DATA', startIndex: startIndex });
        }

    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PriceChangeHistoryState = { PriceChangeHistory: [], isLoading: false };

export const reducer: Reducer<PriceChangeHistoryState> = (state: PriceChangeHistoryState | undefined, incomingAction: Action): PriceChangeHistoryState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                startIndex: action.startIndex,
                PriceChangeHistory: state.PriceChangeHistory,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.PriceChangeHistory != undefined) {
                return {
                    startIndex: action.startIndex,
                    PriceChangeHistory: action.PriceChangeHistory,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
