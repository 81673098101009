
/* eslint-disable no-console */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Redirectionapp from './history';
import $ from 'jquery';
import { authHeader } from '../components/AuthHeader';
import AgentPopup from './AgentPopup';
import MUIDataTable from "../MUI";
import { ApiUrl } from "../components/Constant";
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';


class Agent extends Component {
    constructor() {
        super();

        this.state = {
            Agents: [{
                AgentID: '',
                AgentName: '',
                Address1: '',
                City: '',
                StateName: '',
                Zip: '',
                dteLastUpdate: Date
            }]
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.handleAgent = this.handleAgent.bind(this);
        this.handleAgentMessage = this.handleAgentMessage.bind(this);
    }



    componentDidMount() {

        const requestOptions = {
            method: 'GET',
            headers: authHeader('ok'),
        };


        fetch(ApiUrl +'Agent/AgentConnector', requestOptions)
            .then((response) => {

                if (response.status == 401) {
                    $("#btnLogout").click();
                    return false;
                }
                else if (!response.ok) {
                    alert(response.text);
                    return false;
                }
                else
                    return response.json();
            })
            .then(

                data => {
                    if (data == null || data == "[]") {
                        this.setState({
                            Agents: []
                        })
                    } else {
                        this.setState({
                            Agents: JSON.parse(data)
                        })
                    }

                }).catch(
                    data => {
                        this.setState({
                            Agents: []
                        })
                        
                    }
                );
    }
    onInputchange(event) {

        this.setState({
            [event.target.name]: event.target.checked,
        });
        //debugger
        //this.state.Agents.filter[event.target.name].Selected = event.target.checked
    }


    handleSubmit = (e) => {
        e.preventDefault();

        const { Agents } = this.state;
        var data = Agents.filter(i => i.Selected == true);
        const requestOptions = {
            method: 'POST',
            headers: authHeader(JSON.stringify(data)),
            body: JSON.stringify('ok')
        };


        fetch(ApiUrl +'Sites/AgentListConnector', requestOptions).then(response => response.json())
            .then(

                data => {
                    if (data.message == "success") {

                    } else {
                        alert(data.message);
                    }

                });
    };
    handleAgentMessage = (e) => {
        e.preventDefault();
        var data = e.target.name;

        if (data == "mul") {
            const { Agents } = this.state;
            data = Agents.filter(i => i.Selected == true).map(i => i.AgentID);
        }
        const requestOptions = {
            method: 'GET',
            headers: authHeader('ok'),
        };
        fetch(ApiUrl + 'Socket/AgentStatus?IDs=' + data, requestOptions).then(response => response)
            .then(

                data => {
                    if (data.ok) {

                    } else {

                    }

                });
    };
    
    //<td>
    //    <input type='checkbox' name={item.AgentID} defaultChecked={item.Selected} onChange={e => this.onInputchange(e)} />
    //</td>
    render() {
        
        const columns = [
           
            {
                name: " ",
                label: " ",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div>
                                <input type='checkbox' name={tableMeta.rowIndex} defaultChecked={this.state.Agents[tableMeta.rowIndex].Selected} onChange={e => this.onInputchange(e)} />
                            </div>
                        )
                    },
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "AgentName",
                label: "Agent Name",
                options: {
                    filter: true,
                    sort: true,

                },

            },
            {
                name: "dteLastUpdate",
                label: "Last Update",
                options: {
                    filter: false,
                    sort: false,

                },

            },
            {
                name: "Address1",
                label: "Address",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "City",
                label: "City",
                options: {
                    filter: false,
                    sort: false,

                }
            },
            {
                name: "StateName",
                label: "State",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Zip",
                label: "Zip",
                options: {
                    filter: false,
                    sort: false,
                }
            }

        ];


        const options = {
            filter: true,
            fixedHeader: true,
            filterType: 'dropdown',
            //pagination: false,
            selectableRows: 'none',
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),
            //draggableColumns: {
            //    enabled: true,
            //},
            
            //grouping: { columnIndexes: [1] },
            //tableBodyHeight: '68vh',

        };

        return (
            <div className="">
                <div>

                    <div className='card card-custom gutter-b'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3 className='card-label'>
                                    Agents
                                </h3>
                              
                            </div>
                            <div className='card-toolbar'>
                                <AgentPopup />
                                <button className='btn btn-primary mr-2' name="mul" onClick={this.handleAgentMessage}>Get Info</button>
                            </div>
                        </div>
                        <div className="card-body">
                          
                            <MUIDataTable
                                title={" "}
                                data={this.state.Agents}
                                columns={columns}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Agent;