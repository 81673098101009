import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import Login from '../components/Login';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ApiUrl } from "../components/Constant";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface LoginState {
    isLoading: boolean;
    startIndex?: number;
    LoginModel: LoginModel;
}
export interface LoginModel {
    UserName: string;
    Password: string;
    IsSaveToCookie: boolean;
    IsResetPwd: boolean;
    Token: string;
    TokenExpirationTime: string;
    Id: string;
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestListsAction {
    type: 'REQUEST_DATA';
    startIndex?: number;
}

interface ReceiveListsAction {
    type: 'RECEIVE_DATA';
    startIndex?: number;
    LoginModel: LoginModel;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestListsAction | ReceiveListsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    requestLogin: (startIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.login && startIndex !== appState.login.startIndex ) {

            fetch(`${ApiUrl}Signin/loginRequest`)
                .then(response => response.json())
                .then(

                    data => {
                       
                        if (data && data.Token) {
                            
                            // store user details and jwt token in local storage to keep user logged in between page refreshes
                            
                            localStorage.setItem('user', JSON.stringify(data));
                            dispatch({ type: 'RECEIVE_DATA', LoginModel: data, startIndex: startIndex });
                           
                        }
                    });

            dispatch({ type: 'REQUEST_DATA'});
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: LoginState = { isLoading: false, startIndex: -1, LoginModel: { IsSaveToCookie: false, Password: "", UserName: "", Token: "", Id:"",TokenExpirationTime:"",IsResetPwd:false } };

export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                startIndex: action.startIndex,
                LoginModel: state.LoginModel,

                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.LoginModel!=undefined) {
                return {
                    startIndex: action.startIndex,
                    LoginModel: action.LoginModel,

                    isLoading: false
                };
            }
            break;
    }

    return state;
};



