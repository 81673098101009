import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as XrefTypesSiteListSiteStore from '../store/XrefTypesSiteListSite';
import * as QueryString from "query-string"
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import Backdrop from '@material-ui/core/Backdrop';
import { authHeader } from '../components/AuthHeader';
import { ApiUrl } from "../components/Constant";
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type XrefTypesSiteListSiteProps =
    XrefTypesSiteListSiteStore.XrefTypesSiteListSiteState // ... state we've requested from the Redux store
    & typeof XrefTypesSiteListSiteStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ x: string, xt: string, s: string }>; // ... plus incoming routing parameters


class XrefTypesSiteListSiteData extends React.PureComponent<XrefTypesSiteListSiteProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        
        this.ensureDataFetched();
    }
    constructor(props: Readonly<XrefTypesSiteListSiteProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.props.XrefTypesSiteListSite.typeJson[event.target.name].intValue = event.target.value;
        this.props.XrefTypesSiteListSite.typeJson[event.target.name].state = "Modified";
    }
    handleSubmit(event: { preventDefault: () => void; }) {
       
        var data = this.props.XrefTypesSiteListSite;
        data.sites_List = [];
        
        data.typeJson = data.typeJson.filter(i => i.state == 'Modified');
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            //headers: {
            //    Accept: 'application/json',
            //    'Content-Type': 'application/json',
            //    'data': JSON.stringify(data)
            //},
            headers: authHeader(JSON.stringify(data)) as string[][],
            body: JSON.stringify("ok")
        };
        
        fetch(ApiUrl+'xref/XrefMap_BatchUpdate', requestOptions).catch(error => {
            console.error('Error during request:', error);
        });
    }
    
    // This method is called when the route parameters change
    public componentDidUpdate() {
        //console.log('componentDidUpdate')
        this.ensureDataFetched();
    }

    public render() {

        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                        <h3 className='card-label'>
                            {this.props.XrefTypesSiteListSite == null ? "" : this.props.XrefTypesSiteListSite.pageHeader}

                        </h3>
                        </div>
                        <div className='card-toolbar'>
                            {this.renderButton()}
                    </div>
                    </div>
                    <div className="card-body">

                       
                        {this.renderTable()}
                        {this.renderPagination()}
                    </div>
                </div>
              
            </React.Fragment>
        );
    }

    private ensureDataFetched() {

        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined ) {

            var x = parseInt(parsed.x == null?"0": parsed.x.toString()) || 0;
            var xt = parseInt(parsed.xt == null ? "0" :parsed.xt.toString()) || 0;
            var s = parseInt(parsed.s == null ? "0" :parsed.s.toString()) || 0;
            this.props.requestXrefTypesSiteListSite(x, xt, s);
        }
    }
    private renderButton() {

        if (this != undefined && this.props.XrefTypesSiteListSite != undefined) {
            
            return (
                <div>
                    <div className=''>
                        <button type="submit" onClick={this.handleSubmit} className='btn btn-success text-white mr-2'>
                            Save
                        </button>
                        <a className='btn btn-danger text-white' href={`/xref_Types_SiteList?x=${this.props.XrefTypesSiteListSite.xrefID}&xt=${this.props.XrefTypesSiteListSite.xrefTypeID}`}>Exit</a>
                     
                    </div>
                </div>
            );
        }
    }
    
    private renderTable() {
        const columns = [

            {
                name: "xrefDetailID",
                label: "Xref Detail ID",

            },
            {
                name: "extValue",
                label: "External Description",

            },
            {
                name: "extDescription",
                label: "Ext Description",

            },

            {
                name: "",
                label: "Agility Description",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        
                        ////console.log(tableMeta)
                        return (
                            
                            <select className="form-control w-50 h-auto" onChange={e => this.onInputchange(e)} name={tableMeta.rowIndex.toString()} value={this.props.XrefTypesSiteListSite.typeJson[tableMeta.rowIndex].intValue}>
                                        {this.props.XrefTypesSiteListSite.sites_List.map((e, key) => {
                                            return <option key={key} value={e.Value}>{e.ValueDesc}</option>;
                                        })}
                                    </select>

                        )
                    }
                }

            },

            {
                name: "defaultValue",
                label: "SiteOverrides",

            },


        ];


        const options = {
            filterType: 'dropdown' as any,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" })
        };
        if (this != undefined && this.props.XrefTypesSiteListSite != undefined && this.props.XrefTypesSiteListSite.typeJson != undefined && this.props.isLoading == false) {
            
            return (
                
                <MUIDataTable
                    title={""}
                    data={this.props.XrefTypesSiteListSite.typeJson}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {
        return (
            <div className="">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.xrefTypesSiteListSite,
    // Selects which state properties are merged into the component's props
    XrefTypesSiteListSiteStore.actionCreators // Selects which action creators are merged into the component's props
)(XrefTypesSiteListSiteData as any);
