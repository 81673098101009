import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SiteViewState {
    isLoading: boolean;
    ID?: number;
    SiteNum: string;
    SiteView: SiteView;
}
export interface SiteViewPostModel {

    siteID: number;
    siteNum: string;
    title: string;
    tSiteName: string;
    cActive: boolean;
    tAddress1: string;
    dteLastCheckin: string;
    tAddress2: string;
    tSiteID: string;
    tCity: string;
    tState: string;
    tZip: string;
    tSiteNumber: string;
    email: string;
    fpAlertLow: string;
    fpAlertMedium: string;
    fpAlertHigh: string;
}
export interface SiteView {

    siteID: number;
    siteNum: string;
    title: string;
    tSiteName: string;
    cActive: boolean;
    tAddress1: string;
    dteLastCheckin: string;
    tAddress2: string;
    tSiteID: string;
    tCity: string;
    tState: string;
    tZip: string;
    tSiteNumber: string;
    email: string;
    fpAlertLow: string;
    fpAlertMedium: string;
    fpAlertHigh: string;
    racbEmail: SiteEmailCacheSelectModel[];
    racbFpAlertLow: SiteEmailCacheSelectModel[];
    racbFpAlertMedium: SiteEmailCacheSelectModel[];
    racbFpAlertHigh: SiteEmailCacheSelectModel[];
    site_EmailCache_Select: SiteEmailCacheSelectModel[];
    site_CurrentPrices_Select: site_CurrentPrices_Select[];
    rblActiveAll: string;
    site_Competitors_TT: SiteCompetitorsTTModel[];
    site_Note_Select: SiteNoteSelectModel[];
    site_Products_Select: SiteProductsSelectModel[];
    site_Prices_Select: SitePricesSelectModel[];
    users_List: UsersListModel[];
    site_Users_Responsibility_List: SiteUsersResponsibilityListModel[];
    site_Users_AllUsers_Select: SiteUsersAllUsersSelectModel[];
}
export interface SiteUsersResponsibilityListModel {

    ResponsibilityTypeID: string;
    ResponsibilityType: string;
}
export interface UsersListModel {

    FullName: string;
    UserID: string;
}
export interface SiteEmailCacheSelectModel {

    emailAddr: string;
}
export interface SitePricesSelectModel {
    FuelPriceID: string;
    ProductName: string;
    ProductNumber: string;
    dteEffectiveRequest: Date;
    dteEffectiveActual: Date;
    CashPrice: string;
    CreditPrice: string;
    Implemented: boolean;
}
export interface site_CurrentPrices_Select {
    Store_Number: string;
    ProductNumber: string;
    ExtProductID: string;
    Item_Name: string;
    Date_Last_Price_Change: Date;
    Cash_Price: string;
    Cash_Price_Var: string;
    Credit_Price: string;
    Credit_Price_Var: string;
    Fuel_Type: string;
    Date_Batch_End: Date;
    Site_Active: string;
    Product_Active: string;
    dteLastCheckin: Date;
    SiteID: string;
}
export interface SiteCompetitorsTTModel {
    competitorID: number;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    last_Used: string;
    active: boolean;
    site_Competitors_Grades_TT: SiteCompetitorsGradesTTModel[];
}
export interface SiteCompetitorsGradesTTModel {
    competitorID: number;
    our_Prod: string;
    comp_Prod_Name: string;
    last_Check: string;
    price: string;

}
export interface SiteNoteSelectModel {
    noteID: number;
    name: string;
    created: Date;
    note: string;

}
export interface SiteProductsSelectModel {
    productID: number;
    productNumber: string;
    productName: string;
    dteCreated: Date;
    active: boolean;
    extProductID: string;
}
export interface SiteUsersAllUsersSelectModel {
    siteUserID: number;
    num: number;
    user_Name: string;
    type: string;
    usr: boolean;
    active: boolean;
    userID: number;
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestListsAction {
    type: 'REQUEST_DATA';
    ID?: number;
    SiteNum: string;
}

interface ReceiveListsAction {
    type: 'RECEIVE_DATA';
    ID?: number;
    SiteNum: string;
    SiteView: SiteView;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestListsAction | ReceiveListsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    requestLists: (ID: number, SiteNum: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.siteView && (ID !== appState.siteView.ID || SiteNum !== appState.siteView.SiteNum)) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`${ApiUrl}sites/site_View?ID=${ID}&SiteNum=${SiteNum}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                        
                        if (data == "[]") {
                            data = [];
                        } else {
                            if ( data.site_Prices_Select != null) {
                                data.site_Prices_Select = JSON.parse(data.site_Prices_Select);
                            }
                            else {
                                data.site_Prices_Select = [];
                            }
                            if ( data.users_List != null) {
                                data.users_List = JSON.parse(data.users_List);
                            }
                            else {
                                data.users_List = [];
                            }
                            
                            if ( data.site_Users_Responsibility_List != null) {
                                data.site_Users_Responsibility_List = JSON.parse(data.site_Users_Responsibility_List);
                            }
                            else {
                                data.site_Users_Responsibility_List = [];
                            }
                            if ( data.site_EmailCache_Select != null) {
                                data.site_EmailCache_Select = JSON.parse(data.site_EmailCache_Select);
                            }
                            else {
                                data.site_EmailCache_Select = [];
                            }
                            if (data.site_CurrentPrices_Select != null) {
                                data.site_CurrentPrices_Select = JSON.parse(data.site_CurrentPrices_Select);
                            }
                            else {
                                data.site_CurrentPrices_Select = [];
                            }
                            
                        }
                       
                        dispatch({ type: 'RECEIVE_DATA', ID: ID, SiteNum: SiteNum, SiteView: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', ID: ID, SiteNum: SiteNum });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SiteViewState = {
    SiteView: {
        siteID: 0,
        siteNum: "",
        title: "",
        tSiteName: "",
        cActive: false,
        tAddress1: "",
        dteLastCheckin: "",
        tAddress2: "",
        tSiteID: "",
        tCity: "",
        tState: "",
        tZip: "",
        tSiteNumber: "",
        email: "",
        fpAlertHigh: "",
        fpAlertLow: "",
        fpAlertMedium: "",
        racbEmail: [],
        racbFpAlertLow: [],
        racbFpAlertMedium: [],
        racbFpAlertHigh: [],
        site_EmailCache_Select: [],
        site_CurrentPrices_Select: [],
        rblActiveAll: "",
        site_Competitors_TT: [],
        site_Note_Select: [],
        site_Products_Select: [],
        site_Prices_Select: [],
        users_List: [],
        site_Users_Responsibility_List: [],
        site_Users_AllUsers_Select: []
    }, isLoading: false, ID: -1, SiteNum: ""
};

export const reducer: Reducer<SiteViewState> = (state: SiteViewState | undefined, incomingAction: Action): SiteViewState => {
    if (state === undefined) {
        return unloadedState;
    }
    
    const action = incomingAction as KnownAction;
    
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                SiteNum: action.SiteNum,
                ID: action.ID,
                SiteView: state.SiteView,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.ID === state.ID && action.SiteNum === state.SiteNum) {
                return {
                    ID: action.ID,
                    SiteNum: action.SiteNum,
                    SiteView: action.SiteView,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
