import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface XrefTypesSetDefaultState {
    isLoading: boolean;
    x: number;
    xt: number;
    n?: boolean;
    ReturnUrl: string;
    XrefTypesSetDefault: XrefTypesSetDefaultItem;
}
export interface XrefTypesSetDefaultItem {
    xrefID: number;
    xrefTypeID: number;
    pageHeader: string;
    returnUrl: string;
    showOnlyErrors: boolean;
    siteOverrideList: XrefTypesSetDefault[];
    sites_List: Xref_Site_QueryInternal_List[];
}
export interface Xref_Site_QueryInternal_List {
    Value: string;
    ValueDesc: string;
}

export interface XrefTypesSetDefault {
    intValue: string;
    extValue?: number;
    extName: string;
    state: string;
    xrefDetailID: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestXrefTypesSetDefaultAction {
    type: 'REQUEST_DATA';
    x: number;
    xt: number;
    n?: boolean;
    ReturnUrl: string;
}

interface ReceiveXrefTypesSetDefaultAction {
    type: 'RECEIVE_DATA';
    x: number;
    xt: number;
    n?: boolean;
    ReturnUrl: string;
    XrefTypesSetDefault: XrefTypesSetDefaultItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestXrefTypesSetDefaultAction | ReceiveXrefTypesSetDefaultAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestXrefTypesSetDefault: (x: number, xt: number, n: boolean, ReturnUrl: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.xrefTypesSetDefault && (x !== appState.xrefTypesSetDefault.x || xt !== appState.xrefTypesSetDefault.xt || n !== appState.xrefTypesSetDefault.n)) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
                        
            fetch(`${ApiUrl}xref/xref_Types_SetDefault?x=${x}&xt=${xt}&n=${n}&ReturnUrl=${ReturnUrl}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(
                    data => {
                        
                        if (data.sites == null) {
                            data.sites_List = [];
                        } else {

                            data.sites_List = JSON.parse(data.sites);
                        }
                        
                        dispatch({ type: 'RECEIVE_DATA', x: x, xt: xt, n: n, ReturnUrl: ReturnUrl, XrefTypesSetDefault: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', x: x, xt: xt, n: n, ReturnUrl: ReturnUrl });
        } 
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: XrefTypesSetDefaultState = { XrefTypesSetDefault: { xrefID: -1, xrefTypeID: -1, sites_List: [], returnUrl: "", showOnlyErrors: false, siteOverrideList: [], pageHeader: "" }, isLoading: false, x: -1, xt: -1, n: false, ReturnUrl: "" };

export const reducer: Reducer<XrefTypesSetDefaultState> = (state: XrefTypesSetDefaultState | undefined, incomingAction: Action): XrefTypesSetDefaultState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'REQUEST_DATA':
           
            return {
                x: action.x,
                xt: action.xt,
                n: action.n,
                ReturnUrl: action.ReturnUrl,
                XrefTypesSetDefault: state.XrefTypesSetDefault,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.x === state.x && action.xt === state.xt && action.n === state.n && action.ReturnUrl === state.ReturnUrl) {
                
                return {
                    x: action.x,
                    xt: action.xt,
                    n: action.n,
                    ReturnUrl: action.ReturnUrl,
                    XrefTypesSetDefault: action.XrefTypesSetDefault,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
