import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CompetitorPricesState {
    isLoading: boolean;
    db: number;
    CompetitorPrices: CompetitorPrices[];
}

export interface CompetitorPrices {
    SiteID: string
    SiteNumber: string
    ProductID: string
    Num: string
    ProductNumber: string
    ProductName: string
    dteLastPriceChange: string
    CashPrice: string
    CashPriceVar: string
    CreditPrice: string
    CreditPriceVar: string
    FuelType: string
    dteBatchEnd: string
    C1TT: string
    C1Price: string
    C2TT: string
    C2Price: string
    C3TT: string
    C3Price: string
    C4TT: string
    C4Price: string
    C5TT: string
    C5Price: string
    C6TT: string
    C6Price: string
    state: string
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestCompetitorPricesAction {
    type: 'REQUEST_DATA';
    db: number;
}

interface ReceiveCompetitorPricesAction {
    type: 'RECEIVE_DATA';
    db: number;
    CompetitorPrices: CompetitorPrices[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestCompetitorPricesAction | ReceiveCompetitorPricesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestCompetitorPrices: (db: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();



        if (appState && appState.competitorPrices && db !== appState.competitorPrices.db) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader('ok') as string[][]
            };
            
            fetch(ApiUrl +`collector/FuelPrices_Competitor_Prices`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                       
                        if (data == null || data == "[]") {
                            dispatch({ type: 'RECEIVE_DATA', db: db, CompetitorPrices: [] });

                        }
                        else {
                            dispatch({ type: 'RECEIVE_DATA', db: db, CompetitorPrices: JSON.parse(data) });
                        }
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', db: db });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CompetitorPricesState = { CompetitorPrices: [], isLoading: false, db:-1 };

export const reducer: Reducer<CompetitorPricesState> = (state: CompetitorPricesState | undefined, incomingAction: Action): CompetitorPricesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                db: action.db,
                CompetitorPrices: state.CompetitorPrices,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.CompetitorPrices!=undefined) {

                return {
                    db: action.db,
                    CompetitorPrices: action.CompetitorPrices,
                    isLoading: false
                };
            }

            break;
    }

    return state;
};
