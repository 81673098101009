import * as React from 'react';

import { ApiUrl } from "../components/Constant";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { NavLink as Link, Redirect } from 'react-router-dom';
import './NavMenu.css';
import Logoimg from '../fioslogo.png';


export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, IsLogOut: boolean, isloginpage: boolean }> {
    state = {
        isOpen: false,
        IsLogOut: false,
        isloginpage : false
    }
    componentDidMount() {
        if (window.location.pathname == "/login") {
            this.setState({
                isloginpage: true
            })
        } else {
            this.setState({
                isloginpage: false
            })
        }
    }
    componentDidUpdate() {
        
        if (window.location.pathname == "/login") {
            this.setState({
                isloginpage: true
            })
        } else {
            this.setState({
                isloginpage: false
            })
        }
    }
    
    OnLogOut(event: { preventDefault: () => void; }) {
        localStorage.removeItem('user');
        fetch(ApiUrl+'Signin/Signout')
            .then(response => response)
            .then(

                data => {
                   
                    if (data.ok === true) {
                        //this.props.history.push('/login');
                        //this.setState({ isloginpage: true });
                        window.location.reload();
                        //return <Redirect to={'/login'} />;
                    }
                }).catch(error => {
                    console.error('Error during request:', error);
                });
    }
  
    public render() {
        //const { IsLogOut } = this.state;
        //if (IsLogOut) {
        //    return <Redirect to={'/login'} />;
        //}
        // ${this.state.isloginpage === true ? "d-none" : ""}`
        return (
            <>
                <header className={`headerName`}>
                <Navbar id="kt_header" className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 pl-0 py-0" light>
                    <Container fluid={true} className='h-50px pl-0'>
                        <div className='kt-header__brand   kt-grid__item' id='kt_header_brand'>
                            <div className='kt-header__brand-logo'>
                                <NavbarBrand className='m-0 p-0' tag={Link} to="/">
                                    <img alt="logo" className='kt-header__brand-logo-default' src={Logoimg} />
                                </NavbarBrand>
                            </div>
                        </div>

                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex " isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow w-100">
                                <NavItem className='p-2'>
                                    <NavLink tag={Link} activeClassName="active" exact className="text-white" to="/">Home</NavLink>
                                </NavItem>

                                <NavItem className='p-2 position-relative navdrop'>
                                    <NavLink tag={Link} activeClassName="active" className="text-white" to="/price_list">Pricing</NavLink>
                                    <div className="menu-submenu menu-submenu-classic menu-submenu-left shadow-sm" >
                                        <ul className="menu-subnav">
                                            <li className="menu-item" aria-haspopup="true">
                                                <a href='/price_history' className='menu-link'>

                                                    <span className="menu-text">Price History</span>
                                                </a>
                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <a href='/site_Competitor_PriceCheck' className='menu-link'>

                                                    <span className="menu-text">Add Price Check</span>
                                                </a>
                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <a href='/competitor_prices' className='menu-link'>

                                                    <span className="menu-text">Competitor Pricing</span>
                                                </a>
                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <a href='/price_list' className='menu-link'>

                                                    <span className="menu-text">Current Prices</span>
                                                </a>
                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <a href='/fetch-price-mass-list' className='menu-link'>

                                                    <span className="menu-text">Price Mass Update</span>
                                                </a>
                                            </li>
                                           


                                        </ul>
                                    </div>
                                </NavItem>

                                <NavItem className='p-2 position-relative navdrop'>
                                    <NavLink tag={Link} className="text-white" activeClassName="active" to="/site-list">Setup </NavLink>
                                    <div className="menu-submenu menu-submenu-classic menu-submenu-left shadow-sm" >
                                        <ul className="menu-subnav">

                                            <li className="menu-item" aria-haspopup="true">
                                                <a href='/site-list' className='menu-link'>

                                                    <span className="menu-text">Site List</span>
                                                </a>
                                            </li>

                                            <li className="menu-item" aria-haspopup="true">
                                                <a href='/siteUsers_List' className='menu-link'>

                                                    <span className="menu-text">Users</span>
                                                </a>
                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <a href='/Agents' className='menu-link'>

                                                    <span className="menu-text">Agents</span>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </NavItem>
                                <NavItem className='p-2 position-relative navdrop'>
                                    <NavLink tag={Link} activeClassName="active" className="text-white" to="/xref_list_select">Xref</NavLink>
                                    <div className="menu-submenu menu-submenu-classic menu-submenu-left shadow-sm" >
                                        <ul className="menu-subnav">

                                            <li className="menu-item" aria-haspopup="true">
                                                <a href='/xref_list_select' className='menu-link'>

                                                    <span className="menu-text">All</span>
                                                </a>
                                            </li>

                                            <li className="menu-item" aria-haspopup="true">
                                                <Link className='menu-link' to={{
                                                    pathname: '/xref_Types_Items',
                                                    search: `?x=1&xt=1`
                                                }}> <span className="menu-text">Gas Buddy (by Product)</span> </Link>
                                               
                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <Link className='menu-link' to={{
                                                    pathname: '/xref_Types_SiteList',
                                                    search: `?x=1&xt=1`
                                                }}> <span className="menu-text">Gas Buddy (by Site)</span> </Link>
                                               
                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <Link className='menu-link' to={{
                                                    pathname: '/xref_Types_Items',
                                                    search: `?x=2&xt=2`
                                                }}> <span className="menu-text">Agility (by Product)</span> </Link>
                                               
                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <Link className='menu-link' to={{
                                                    pathname: '/xref_Types_SiteList',
                                                    search: `?x=2&xt=2`
                                                }}> <span className="menu-text">Agility (by Site)</span> </Link>
                                               
                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <Link className='menu-link' to={{
                                                    pathname: '/xref_Types_Items',
                                                    search: `?x=3&xt=3`
                                                }}> <span className="menu-text">Phase 2 (by Product)</span> </Link>

                                            </li>
                                            <li className="menu-item" aria-haspopup="true">
                                                <Link className='menu-link' to={{
                                                    pathname: '/xref_Types_SiteList',
                                                    search: `?x=3&xt=3`
                                                }}> <span className="menu-text">Phase 2 (by Site)</span> </Link>

                                            </li>

                                        </ul>
                                    </div>
                                </NavItem>

                                <NavItem className='p-2  position-relative navdrop'>
                                    <NavLink tag={Link} activeClassName="active" exact className="text-white" to="/plugin_List">Collectors</NavLink>
                                </NavItem>
                                
                                <NavItem className='p-2 ml-auto'>
                                    <button className="btn btn-primary" id="btnLogout" onClick={e => this.OnLogOut(e)}>Logout</button>
                                </NavItem>

                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
                </header>
            </>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
   
}
