import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface XrefTypeItemsSiteOverrideState {
    isLoading: boolean;
    x: number;
    xt: number;
    ev: string;
    XrefTypeItemsSiteOverride: XrefTypeItemsSiteOverrideItem;
}
export interface XrefTypeItemsSiteOverrideItem {
    xrefID: number;
    xrefTypeID: number;
    extValue: string;
    returnUrl: string;
    header: string;
    extName: string;
    intValue: string;
    agility_Desc: string;
    typeJson: XrefTypeItemsSiteOverride[];
    queryInternal: XrefTypeItemsSiteOverrideQueryInternal[];
    sites_List: XrefTypeItemsSiteOverrideSites_List[];
}
export interface XrefTypeItemsSiteOverrideSites_List {
    Value: string;
    ValueDesc: string;
}
export interface XrefTypeItemsSiteOverrideQueryInternal {
    SiteID: number;
    Description: string;
}
export interface XrefTypeItemsSiteOverride {
    intValue: string;
    state: string;
    siteID?: number;
    xrefDetailID?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestXrefTypeItemsSiteOverrideAction {
    type: 'REQUEST_DATA';
    x: number;
    xt: number;
    ev: string;
}

interface ReceiveXrefTypeItemsSiteOverrideAction {
    type: 'RECEIVE_DATA';
    x: number;
    xt: number;
    ev: string;
    XrefTypeItemsSiteOverride: XrefTypeItemsSiteOverrideItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestXrefTypeItemsSiteOverrideAction | ReceiveXrefTypeItemsSiteOverrideAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestXrefTypeItemsSiteOverride: (x: number, xt: number,ev:string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.xrefTypesItemsSiteOverride && (x !== appState.xrefTypesItemsSiteOverride.x || xt !== appState.xrefTypesItemsSiteOverride.xt || ev !== appState.xrefTypesItemsSiteOverride.ev)) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`${ApiUrl}xref/xref_types_Site_Override?x=${x}&xt=${xt}&ev=${ev}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                        debugger
                        if (data.sites == null) {
                            data.sites_List = [];
                        } else {

                            data.sites_List = JSON.parse(data.sites);
                        }
                        if (data.queryInternalJson == null) {
                            data.queryInternal = [];
                        } else {

                            data.queryInternal = JSON.parse(data.queryInternalJson);
                        }
                        dispatch({ type: 'RECEIVE_DATA', x: x, xt: xt,ev:ev, XrefTypeItemsSiteOverride: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', x: x, xt: xt, ev: ev });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: XrefTypeItemsSiteOverrideState = { XrefTypeItemsSiteOverride: { xrefID: 0, xrefTypeID: 0, typeJson: [], sites_List: [], queryInternal:[], agility_Desc: "", extName: "", extValue: "", header: "", intValue:"",returnUrl:"" }, x: 0, xt: 0,ev:"", isLoading: false };

export const reducer: Reducer<XrefTypeItemsSiteOverrideState> = (state: XrefTypeItemsSiteOverrideState | undefined, incomingAction: Action): XrefTypeItemsSiteOverrideState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                x: action.x,
                xt: action.xt,
                ev: action.ev,
                XrefTypeItemsSiteOverride: state.XrefTypeItemsSiteOverride,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.x === state.x && action.xt === state.xt && action.ev === state.ev) {
                return {
                    x: action.x,
                    xt: action.xt,
                    ev: action.ev,
                    XrefTypeItemsSiteOverride: action.XrefTypeItemsSiteOverride,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
