import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as SiteCompetitorPriceChkStore from '../store/SiteCompetitorPriceChk';
import * as QueryString from "query-string"
import $ from 'jquery';
import MUIDataTable from "../MUI";
import Backdrop from '@material-ui/core/Backdrop';
import 'pc-bootstrap4-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import { authHeader } from '../components/AuthHeader';
import { ApiUrl } from "../components/Constant";
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';

// At runtime, Redux will merge together...
type ListProps =
    SiteCompetitorPriceChkStore.SiteCompetitorPriceChkState // ... state we've requested from the Redux store
    & typeof SiteCompetitorPriceChkStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>; // ... plus incoming routing parameters
const cardtitle = {
    width: "15%",
}

class ListData extends React.PureComponent<ListProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {

        this.ensureDataFetched();
        console.log();

    }
    state = {
        redirect: false
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        //console.log('componentDidUpdate')
        //this.ensureDataFetched();
    }

    constructor(props: Readonly<ListProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.onChangeSite = this.onChangeSite.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSiteChange = this.onSiteChange.bind(this);

    }
    onChangeSite(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });

        this.props.requestSiteCompetitorPriceChk(event.target.value, this.props.SiteCompetitorPriceChk.db, this.props.SiteCompetitorPriceChk.msg, this.props.SiteCompetitorPriceChk.returnUrl);

    }
    onSiteChange(event: any) {
        //console.log(event.target.value)
        const requestOptions = {
            method: 'GET',
            headers: authHeader('ok') as string[][]
        };
        fetch(ApiUrl +'collector/Site_Competitor_PriceChk?siteID=' + event.target.value, requestOptions)
            .then(response => response.json())
            .then(

                data => {
                    
                    this.props.SiteCompetitorPriceChk.siteCompetitorPriceChk = data;
                    this.setState({
                        siteCompetitorPriceChk: data,
                    })
                }).catch(error => {
                    console.error('Error during request:', error);
                });
        //this.props.requestSiteCompetitorPriceChk(event.target.value, "1", "", "SelfRef");

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.placeholder == "Effective Date") {
            this.props.SiteCompetitorPriceChk.effectiveDate = event.target.value
        } else {
            this.props.SiteCompetitorPriceChk.siteCompetitorPriceChk[event.target.name].price = event.target.value
            this.props.SiteCompetitorPriceChk.siteCompetitorPriceChk[event.target.name].state = 'Modified'
        }
        $(event.target).focusout(function () {
            $(this).addClass('text-danger');
        });
    }
    handleSubmit(event: { preventDefault: () => void; }) {

        var data = this.props.SiteCompetitorPriceChk;
        data.siteList = [];

        data.siteCompetitorPriceChk = data.siteCompetitorPriceChk.filter(i => i.state == 'Modified');
        event.preventDefault();
        if (data.siteCompetitorPriceChk.length > 0) {

            const requestOptions = {
                method: 'POST',
                //headers: { 'Content-Type': 'application/json', 'data': JSON.stringify(data) },
                headers: authHeader(JSON.stringify(data)) as string[][],
                body: JSON.stringify("ok")
            };
            fetch(ApiUrl +'collector/SiteCompetitorPriceChkPost', requestOptions)
                .then(response => response)
                .then(

                    data => {
                        if (data.status == 401) {
                            $("#btnLogout").click();
                            return;
                        }
                        if (data.ok === true) {
                            if (this.props.SiteCompetitorPriceChk.returnUrl == "Setup") {
                                this.setState({ redirect: true })
                            } else {
                                $('.price').removeClass('text-danger');
                            }
                        }
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });
        }
    }


    public render() {
        ($('.datepicker') as any).datetimepicker({
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'far fa-trash-alt',
                close: 'far fa-times-circle'
            },
        });
        const { redirect } = this.state;

        if (redirect) {
            if (this.props.SiteCompetitorPriceChk.returnUrl == "Setup") {
                let url = '/site_View?ID=' + this.props.SiteCompetitorPriceChk.siteID.toString();
                return <Redirect to={url} />;
            }
        }
        return (
            <React.Fragment>
                <div className='card card-custom gutter-b adjustwidth'>
                    <div className='card-header'>
                        <div className='card-title' style={cardtitle}>
                            <h3 id="tabelLabel" className='card-label mr-0 w-25'>
                                Site :
                            </h3>
                            {this.props.SiteCompetitorPriceChk == undefined ? "" : this.titleDropdown()}
                        </div>
                        <div className='card-toolbar'>
                            <button className='btn btn-primary mr-2' type="submit" onClick={this.handleSubmit} >Save</button>
                            {this.props.SiteCompetitorPriceChk == undefined ? "" : this.props.SiteCompetitorPriceChk.returnUrl == "SelfRef" ? "" : <Link className='mr-2 btn btn-danger' to={{
                                pathname: '/site_View',
                                search: `?ID=${this.props.SiteCompetitorPriceChk == undefined ? "" : this.props.SiteCompetitorPriceChk.siteID}`
                            }}> Exit </Link>}

                        </div>
                    </div>
                    <div className="card-body">


                        {this.renderElement()}
                        {this.renderTable()}
                        {this.renderPagination()}

                    </div>
                </div>


            </React.Fragment>
        );
    }
    private titleDropdown() {

        if (this.props.SiteCompetitorPriceChk.returnUrl != "SelfRef") {
            return (

                this.props.SiteCompetitorPriceChk.siteID
            )
        } else {
            return (
                <select className="form-control" onChange={(e) => this.onSiteChange(e)}>
                    {this.props.SiteCompetitorPriceChk.siteList != null && this.props.SiteCompetitorPriceChk.siteList.map((e, key) => {
                        return <option key={key} value={e.SiteID}>{e.SiteNumber}</option>;
                    })}
                </select>

            )
        }

    }
    private ensureDataFetched() {
        if (this.props.location === undefined) {
            this.props.requestSiteCompetitorPriceChk(0, "1", "", "SelfRef");

        } else {

            const parsed = QueryString.parse(this.props.location.search)
            if (parsed != null && parsed != undefined) {

                var SID = parseInt(parsed.SID == null ? "0" : parsed.SID.toString()) || 0;
                var db = parsed.db == null ? "1" : parsed.db.toString() || "1";
                var Msg = parsed.Msg == null ? "" : parsed.Msg.toString() || "";
                var retUrl = parsed.r == null ? "SelfRef" : parsed.r.toString() || "SelfRef";
                this.props.requestSiteCompetitorPriceChk(SID, db, Msg, retUrl);
            }

        }
    }

    private renderElement() {
        let classes = "";

        if (this.props.location == undefined) {
            classes = "col-md-6"
        } else {
            classes = "col-md-2"
        }

        if (this != undefined && this.props.SiteCompetitorPriceChk != undefined) {

            return (
                <div>

                    <div className='row p-3'>

                        <div className={classes}>
                            <div className='form-group row mb-3'>
                                <label className='col-lg-5 col-form-label'>Effective Date</label>
                                <div className='col-lg-7 pl-0'>
                                    <input type="text" placeholder="Effective Date" onChange={e => this.onInputchange(e)} defaultValue={this.props.SiteCompetitorPriceChk.effectiveDate} className="form-control datepicker" />
                                </div>
                            </div>

                        </div>
                        <div className={classes}>

                            <div className='form-group mb-3 row'>
                                <label className='col-lg-5 pr-0 col-form-label'>Last Price Check</label>
                                <div className='col-lg-7 align-self-center'>
                                    {this.props.SiteCompetitorPriceChk.lastPriceCheck}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            );
        }
    }

    private renderTable() {
        const columns = [
            {
                name: "competitorName",
                label: "Competitor Name",
                options: {
                    filter: true,
                    sort: true,
                },

            }, {
                name: "comp_Prod_Name",
                label: "Comp Prod Name",
                options: {
                    filter: true,
                    sort: true,
                },

            },
            {
                name: "price",
                label: "Price",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <input type="text" placeholder="cashPrice" name={tableMeta.rowIndex.toString()} defaultValue={this.props.SiteCompetitorPriceChk.siteCompetitorPriceChk[tableMeta.rowIndex].price == undefined ? 0 : this.props.SiteCompetitorPriceChk.siteCompetitorPriceChk[tableMeta.rowIndex].price} onChange={e => this.onInputchange(e)} className="form-control price w-25" />
                        )
                    },
                },
            }
        ];


        const options = {
            filter: true,
            filterType: 'dropdown' as any,
            pagination: false,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),
            draggableColumns: {
                enabled: true,
            },
            grouping: { columnIndexes: [0] },

        };

        if (this != undefined && this.props.isLoading == false && this.props.SiteCompetitorPriceChk != undefined && this.props.SiteCompetitorPriceChk.siteCompetitorPriceChk != undefined) {
            if (this.props.SiteCompetitorPriceChk.siteCompetitorPriceChk.length > 0) {


                return (
                    <>
                        <MUIDataTable
                            title={""}
                            data={this.props.SiteCompetitorPriceChk.siteCompetitorPriceChk}
                            columns={columns}
                            options={options}
                        />
                    </>
                );
            } else {
                const optionsnew = {
                    filter: true,
                    fixedHeader: true,
                    filterType: 'dropdown' as any,
                    pagination: false,
                    selectableRows: 'none' as any,
                    setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),


                };
                return (
                    <>
                        <MUIDataTable
                            title={""}
                            data={this.props.SiteCompetitorPriceChk.siteCompetitorPriceChk}
                            columns={columns}
                            options={optionsnew}
                        />

                    </>
                );
            }
        }
    }

    private renderPagination() {

        return (
            <div className="d-flex justify-content-between">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.siteCompetitorPriceChk, // Selects which state properties are merged into the component's props
    SiteCompetitorPriceChkStore.actionCreators // Selects which action creators are merged into the component's props
)(ListData as any);
