import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SiteCompetitorFuelGradesState {
    isLoading: boolean;
    SID?: number;
    SiteCompetitorFuelGrades: SiteCompetitorFuelGradesItem;
}
export interface SiteCompetitorFuelGradesItem {
    siteID: number;
    competitor: string;
    title: string;
    address: string;
    dteLastCheckIn: string;
    compProduct: string;
    ourProduct: ourProduct[];
    site_Competitor_Grades_Select: site_Competitor_Grades_Select[];
}
export interface ourProduct {
    ProductID?: number;
    ItemName: string;
}
export interface site_Competitor_Grades_Select {
    CompGradeID?: number;
    ProductID?: number;
    Our_Prod: string;
    Comp_Prod_Name: string;
    CompFollow: string;
    Follow?: boolean;
    AlertLow: string;
    AlertMedium: string;
    AlertHigh: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestSiteCompetitorFuelGradesAction {
    type: 'REQUEST_DATA';
    SID: number;
}

interface ReceiveSiteCompetitorFuelGradesAction {
    type: 'RECEIVE_DATA';
    SID: number;
    SiteCompetitorFuelGrades: SiteCompetitorFuelGradesItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestSiteCompetitorFuelGradesAction | ReceiveSiteCompetitorFuelGradesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestSiteCompetitorFuelGrades: (SID: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.siteCompetitorFuelGrades && SID !== appState.siteCompetitorFuelGrades.SID) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`gasbuddy/SiteCompetitorFuelGrades`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                        if (data.ourProduct == null) {
                            data.ourProduct = [];
                        } else {

                            data.ourProduct = JSON.parse(data.ourProduct);
                        }
                        dispatch({ type: 'RECEIVE_DATA', SID: SID, SiteCompetitorFuelGrades: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', SID: SID });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SiteCompetitorFuelGradesState = { SiteCompetitorFuelGrades: { address: "", competitor: "", compProduct: "", dteLastCheckIn: "", ourProduct: [], siteID: 0, site_Competitor_Grades_Select:[],title:"" }, isLoading: false };

export const reducer: Reducer<SiteCompetitorFuelGradesState> = (state: SiteCompetitorFuelGradesState | undefined, incomingAction: Action): SiteCompetitorFuelGradesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                SID: action.SID,
                SiteCompetitorFuelGrades: state.SiteCompetitorFuelGrades,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.SID === state.SID) {
                return {
                    SID: action.SID,
                    SiteCompetitorFuelGrades: action.SiteCompetitorFuelGrades,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
