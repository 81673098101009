import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CollectorPriceDetailState {
    isLoading: boolean;
    SiteID: number;
    ProductID: number;
    ProductName: string;
    From: string;
    To: string;
    CollectorPriceDetail: CollectorPriceDetailItem;
}
export interface CollectorPriceDetailItem {
    SiteID: number;
    ProductID: number;
    dteFrom: string;
    dteTo: string;
    ProductName: string;
    title: string;
    jsonType: CollectorPriceDetail[];
}

export interface CollectorPriceDetail {
    FuelPriceID?: number;
    StoreNumber?: number;
    dteCreated: string;
    ItemCode?: number;
    CashPrice: string;
    CashPriceVar: string;
    CreditPrice: string;
    CreditPriceVar: string;
    FileID?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestCollectorPriceDetailAction {
    type: 'REQUEST_DATA';
    SiteID: number;
    ProductID: number;
    ProductName: string;
    From: string;
    To: string;
}

interface ReceiveCollectorPriceDetailAction {
    type: 'RECEIVE_DATA';
    SiteID: number;
    ProductID: number;
    ProductName: string;
    From: string;
    To: string;
    CollectorPriceDetail: CollectorPriceDetailItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestCollectorPriceDetailAction | ReceiveCollectorPriceDetailAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestCollectorPriceDetail: (SiteID: number, ProductID: number, ProductName: string, From: string , To: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
       
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.collectorPriceDetail &&( SiteID !== appState.collectorPriceDetail.SiteID || ProductID !== appState.collectorPriceDetail.ProductID || ProductName !== appState.collectorPriceDetail.ProductName || From !== appState.collectorPriceDetail.From || To !== appState.collectorPriceDetail.To)) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][]
            };
            fetch(`${ApiUrl}collector/price_detail?SiteID=${SiteID}&ProductID=${ProductID}&ProductName=${ProductName}&DteFrom=${From}&DteTo=${To}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                        
                        if (data.jsonType == null) {
                            
                            data.jsonType = [] as CollectorPriceDetail[];
                        } else {
                            data.jsonType = JSON.parse(data.jsonType);
                        }
                        
                        dispatch({ type: 'RECEIVE_DATA', SiteID: SiteID, ProductID: ProductID, ProductName: ProductName, From: From, To: To, CollectorPriceDetail: data });
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', SiteID: SiteID, ProductID: ProductID, ProductName: ProductName, From: From, To: To });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CollectorPriceDetailState = { CollectorPriceDetail: { ProductID: -1, dteTo: "", ProductName: "", jsonType: [], dteFrom: "", SiteID: -1, title: "" }, SiteID: -1, ProductID: -1, ProductName: "", From: "", To:"", isLoading: false };

export const reducer: Reducer<CollectorPriceDetailState> = (state: CollectorPriceDetailState | undefined, incomingAction: Action): CollectorPriceDetailState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                SiteID: action.SiteID,
                ProductID: action.ProductID,
                To: action.To,
                From: action.From,
                ProductName: action.ProductName,
                CollectorPriceDetail: state.CollectorPriceDetail,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.SiteID === state.SiteID && action.ProductName === state.ProductName && action.ProductID === state.ProductID && action.From === state.From && action.To === state.To) {
                return {
                    SiteID: action.SiteID,
                    To: action.To,
                    From: action.From,
                    ProductID: action.ProductID,
                    ProductName: action.ProductName,
                    CollectorPriceDetail: action.CollectorPriceDetail,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
