
import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import SiteList from './components/SiteList';
import CollectorPriceMassUpdateData from './components/CollectorPriceMassUpdateData';
import CollectorPriceDetail from './components/CollectorPriceDetail';
import './style.bundle.css';
import './custom.css';
import XrefTypes from './components/XrefTypes';
import Dashboard from './components/Dashboard';
import XrefTypesItems from './components/XrefTypesItems';
import XrefListSelect from './components/XrefSelectList';
import XrefTypeSiteList from './components/XrefTypeSiteList';
import XrefTypesSiteItems from './components/XrefTypesSiteItems';
import XrefTypesSetDefault from './components/XrefTypesSetDefault';
import XrefTypesSiteListSite from './components/XrefTypesSiteListSite';
import XrefTypeItemsSiteOverride from './components/XrefTypeItemsSiteOverride';
import CollectorPrice from './components/CollectorPrice';
import GasBuddyStatus from './components/GasBuddyStatus';
import CompetitorPrices from './components/CompetitorPrices';
import PriceHistory from './components/PriceHistory';
import SiteCompetitorPriceChk from './components/SiteCompetitorPriceChk';
import SiteUsersList from './components/SiteUsersList';
import SiteUsersView from './components/SiteUsersView';
import SiteView from './components/SiteView';
import PriceChangeHistory from './components/PriceChangeHistory';
import SiteCompetitorAddEdit from './components/SiteCompetitorAddEdit';
import SiteCompetitorFuelGrades from './components/SiteCompetitorFuelGrades';
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login";
import Plugin from "./components/Plugin";
import PluginList from "./components/Plugin_List";

import PasswordReset from "./components/PasswordReset";
import Access_denied from "./components/Access_denied";
import AgentList from "./components/AgentList";
import AgentConnector from "./components/AgentConnector";



export default () => (
    <Layout>
        <Route path='/PasswordReset' component={PasswordReset} />
        <Route path='/Access_denied' component={Access_denied} />
        <Route path='/Login' component={Login} />
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path='/Agents' component={AgentConnector} />
        <Route exact path='/AgentList' component={AgentList} />
        <PrivateRoute exact path='/site-list' component={SiteList} />
        <PrivateRoute exact path='/fetch-price-mass-list' component={CollectorPriceMassUpdateData} />
        <PrivateRoute exact path='/price_list' component={CollectorPrice} />
        <PrivateRoute exact path='/price_detail' component={CollectorPriceDetail} />
        <PrivateRoute exact path='/xref_list_select' component={XrefListSelect} />
        <PrivateRoute exact path='/xref_types' component={XrefTypes} />
        <PrivateRoute exact path='/xref_Types_SiteList' component={XrefTypeSiteList} />
        <PrivateRoute exact path='/xref_Types_SiteList_Site' component={XrefTypesSiteListSite} />
        <PrivateRoute exact path='/xref_types_Site_Override' component={XrefTypeItemsSiteOverride} />
        <PrivateRoute exact path='/xref_Types_SetDefault' component={XrefTypesSetDefault} />
        <PrivateRoute exact path='/xref_Types_SiteItems' component={XrefTypesSiteItems} />
        <PrivateRoute exact path='/xref_Types_Items' component={XrefTypesItems} />
        <PrivateRoute exact path='/gas_buddy_status' component={GasBuddyStatus} />
        <PrivateRoute exact path='/competitor_prices' component={CompetitorPrices} />
        <PrivateRoute exact path='/price_history' component={PriceHistory} />
        <PrivateRoute exact path='/site_Competitor_PriceCheck' component={SiteCompetitorPriceChk} />
        <PrivateRoute exact path='/siteUsers_List' component={SiteUsersList} />
        <PrivateRoute exact path='/siteUsers_View' component={SiteUsersView} />
        <PrivateRoute exact path='/site_View' component={SiteView} />
        <PrivateRoute exact path='/site_Competitor_AddEdit/:SID?' component={SiteCompetitorAddEdit} />
        <PrivateRoute exact path='/site_Competitor_FuelGrades' component={SiteCompetitorFuelGrades} />
        <PrivateRoute exact path='/priceChangeHistory' component={PriceChangeHistory} />
        <PrivateRoute exact path='/configuration' component={Plugin} />
        <PrivateRoute exact path='/plugin_List' component={PluginList} />

    </Layout>
);
//<Route path='/site-list' component={SiteList} />
//    <Route path='/fetch-price-mass-list' component={CollectorPriceMassUpdateData} />
//    <Route path='/price_list' component={CollectorPrice} />
//    <Route path='/price_detail' component={CollectorPriceDetail} />
//    <Route path='/xref_list_select' component={XrefListSelect} />
//    <Route path='/xref_types' component={XrefTypes} />
//    <Route path='/xref_Types_SiteList' component={XrefTypeSiteList} />
//    <Route path='/xref_Types_SiteList_Site' component={XrefTypesSiteListSite} />
//    <Route path='/xref_types_Site_Override' component={XrefTypeItemsSiteOverride} />
//    <Route path='/xref_Types_SetDefault' component={XrefTypesSetDefault} />
//    <Route path='/xref_Types_SiteItems' component={XrefTypesSiteItems} />
//    <Route path='/xref_Types_Items' component={XrefTypesItems} />
//    <Route path='/gas_buddy_status' component={GasBuddyStatus} />
//    <Route path='/competitor_prices' component={CompetitorPrices} />
//    <Route path='/price_history' component={PriceHistory} />
//    <Route path='/site_Competitor_PriceCheck' component={SiteCompetitorPriceChk} />
//    <Route path='/siteUsers_List' component={SiteUsersList} />
//    <Route path='/siteUsers_View' component={SiteUsersView} />
//    <Route path='/site_View' component={SiteView} />
//    <Route path='/site_Competitor_AddEdit/:SID?' component={SiteCompetitorAddEdit} />
//    <Route path='/site_Competitor_FuelGrades' component={SiteCompetitorFuelGrades} />
//    <Route path='/priceChangeHistory' component={PriceChangeHistory} />