import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as SiteUsersViewStore from '../store/SiteUsersView';
import $ from 'jquery';
import MUIDataTable from "../MUI";
import Tooltip from '@material-ui/core/Tooltip';
import * as QueryString from "query-string"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import RemoveIcon from '@material-ui/icons/Remove';
import Backdrop from '@material-ui/core/Backdrop';
import { authHeader } from '../components/AuthHeader';
import { ApiUrl } from "../components/Constant";
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';



// At runtime, Redux will merge together...
type ListProps =
    SiteUsersViewStore.SiteUsersViewState // ... state we've requested from the Redux store
    & typeof SiteUsersViewStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ startIndex: string }>; // ... plus incoming routing parameters


class ListData extends React.PureComponent<ListProps> {
    constructor(props: Readonly<ListProps>) {
        super(props);

        this.handleActive = this.handleActive.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    handleActive(event: any) {
        event.preventDefault();
        var index = event.currentTarget.accessKey as number;
        let acData = this.props.SiteUsersView.sites_SiteUser_Sites_Select[index] as SiteUsersViewStore.sites_SiteUser_Sites_Select;
        confirmAlert({
            title: 'Confirm to deactivate',
            message: 'Are you sure you wish to deactivate the user for this site/responsibility?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const requestOptions = {
                            method: 'GET',
                            headers: authHeader('ok') as string[][]
                        };
                        fetch(ApiUrl+'sites/Site_Users_ActivateDeactive_Operation?SiteUserID=' + acData.SiteUserID, requestOptions)
                            .then(response => response)
                            .then(

                                data => {
                                    if (data.status == 401)
                                    {
                                        $("#btnLogout").click();
                                        return;
                                    } 
                                    if (data.ok === true) {
                                        this.setState({
                                            sites_SiteUser_Sites_Select: this.props.SiteUsersView.sites_SiteUser_Sites_Select.splice(index, 1),
                                        })   
                                    }
                                }).catch(error => {
                                    console.error('Error during request:', error);
                                });
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('no')
                }
            ]
        });


    }
    handleSubmit(event: any) {

        var res = $("#ddlResponsibility").val();

        var site = $("#ddlSite").val() as number;
        event.preventDefault();
        if (site > 0) {
            const requestOptions = {
                method: 'POST',
                headers: authHeader('ok') as string[][]
            };

            fetch(ApiUrl +'sites/Site_Users_User_Insert?SiteUserID=' + site + '&UserID=' + this.props.SiteUsersView.userID + '&ResponsibilityTypeID=' + res, requestOptions)
                .then(response => response)
                .then(

                    data => {
                        if (data.status == 401)
                        {
                            $("#btnLogout").click(); return;
                        } 
                        if (data.ok === true) {

                            let data2 = new Object as SiteUsersViewStore.sites_SiteUser_Sites_Select
                            data2.ResponsibilityType = $("#ddlResponsibility option:selected").text() as string;
                            data2.SiteName = $("#ddlSite option:selected").text() as string;

                            data2.SiteUserID = site;
                            data2.SiteID = site;
                            data2.SiteNumber = site;

                            this.setState({
                                sites_SiteUser_Sites_Select: this.props.SiteUsersView.sites_SiteUser_Sites_Select.push(data2),
                            })
                            $("#ddlResponsibility").val('');

                            $("#ddlSite").val('');
                        }
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });
        }
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {

        return (

            <React.Fragment>
                {this.renderPage()}

            </React.Fragment>

        );
    }

    private ensureDataFetched() {
        const parsed = QueryString.parse(this.props.location.search)
        if (parsed != null && parsed != undefined) {

            var ID = parseInt(parsed.ID == null ? "0" : parsed.ID.toString()) || 0;
            this.props.requestLists(ID);
        }
    }

    private renderPage() {


        if (this != undefined && this.props.SiteUsersView != undefined) {
            return (
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className='card-label'>
                            Site Users ::  {this.props.SiteUsersView.fullname}

                            </h3>
                        </div>
                        <div className="card-toolbar">
                            <a className="btn btn-danger" href="/siteUsers_List">Exit</a>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Site:</label>
                                    <select className="form-control" id="ddlSite">
                                        {this.props.SiteUsersView.site_Select.map((e, key) => {
                                            return <option key={key} value={e.SiteID}>{e.Description}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Responsibility:</label>
                                    <select className="form-control" id="ddlResponsibility">
                                        {this.props.SiteUsersView.site_Users_Responsibility_List.map((e, key) => {
                                            return <option key={key} value={e.ResponsibilityTypeID}>{e.ResponsibilityType}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-4 align-self-center'>
                                <div className=''>

                                    <button onClick={this.handleSubmit} className='btn btn-primary'>Add</button>
                                </div>
                            </div>
                        </div>
                        {this.renderTable()}
                        {this.renderPagination()}

                    </div>
                </div>
            );
        }

    }
    private renderTable() {
        const columns = [
            {
                name: "",
                label: "",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip title="Deactivate">
                                <button onClick={this.handleActive} itemID={tableMeta.rowIndex} accessKey={tableMeta.rowIndex} className='btn btn-icon btn-outline-success btn-circle btn-sm mr-2'>
                                    <RemoveIcon />
                                </button>
                            </Tooltip>

                        )

                    },

                },
            },
            {
                name: "SiteNumber",
                label: "Site Number",
                options: {
                    filter: true,
                    sort: true,
                },

            }, {
                name: "ResponsibilityType",
                label: "Responsibility Type",
                options: {
                    filter: true,
                    sort: true,
                },

            },


        ];


        const options = {
            filter: true,
            filterType: 'dropdown' as any,
            pagination: false,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),
            draggableColumns: {
                enabled: true,
            },
            onTableChange: (action: any, state: any) => {
                //console.log(action);
                //console.dir(state);
            },
            onGroupExpansionChange: (group: any, expanded: any) => {
                //console.dir(group);
                //console.dir(expanded);
            },
            grouping: { columnIndexes: [2] },

        };
        if (this != undefined && this.props.isLoading == false) {
            return (

                <MUIDataTable
                    title={''}
                    data={this.props.SiteUsersView.sites_SiteUser_Sites_Select}
                    columns={columns}
                    options={options}
                />
            );
        }
    }

    private renderPagination() {

        return (
            <div className="">

                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}

            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.siteUsersView, // Selects which state properties are merged into the component's props
    SiteUsersViewStore.actionCreators // Selects which action creators are merged into the component's props
)(ListData as any);
