import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as CompetitorPricesStore from '../store/CompetitorPrices';
import * as QueryString from "query-string"
import $ from 'jquery';
import EditIcon from '@material-ui/icons/Edit';
import 'pc-bootstrap4-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import Tooltip from '@material-ui/core/Tooltip';
import { compareAsc, format } from 'date-fns';
import MUIDataTable from "../MUI";
import Backdrop from '@material-ui/core/Backdrop';
import { authHeader } from '../components/AuthHeader';
import { ApiUrl} from "../components/Constant";
// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
//import $ = require('jquery');

// At runtime, Redux will merge together...
type CompetitorPriceProps =
    CompetitorPricesStore.CompetitorPricesState // ... state we've requested from the Redux store
    & typeof CompetitorPricesStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ Active: string }>; // ... plus incoming routing parameters


class CompetitorPriceData extends React.PureComponent<CompetitorPriceProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    constructor(props: Readonly<CompetitorPriceProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    onInputchange(event: any) {

        if (this.props.CompetitorPrices[event.target.name].CashPrice != event.target.value) {
            this.setState({
                [event.target.name]: event.target.value
            });
            this.props.CompetitorPrices[event.target.name].CashPrice = event.target.value;
            this.props.CompetitorPrices[event.target.name].state = "Modified";

            //$(event).addClass('priceColor');
            //$(event.target).focusout(function () {
            $(event.target).addClass('text-danger');
            //});
        }

    }
    handleSubmit(event: { preventDefault: () => void; }) {

        event.preventDefault();
        var data = this.props.CompetitorPrices.filter(i => i.state == 'Modified');

        if (data.length > 0) {

            const requestOptions = {
                method: 'POST',
                //headers: { 'Content-Type': 'application/json', data: JSON.stringify(data), eDate: $("#txtEffectiveDate").val() as string },
                headers: authHeader(JSON.stringify(data)) as string[][],
                //body: $("#txtEffectiveDate").val() as string
            };
            fetch(ApiUrl+'collector/FuelPrices_PriceMassUpdate_Update?value=' + $("#txtEffectiveDate").val(), requestOptions)
                .then(response => response)
                .then(

                    data => {
                        if (data.status == 401) {
                            $("#btnLogout").click();
                            return;
                        }
                        if (data.ok === true) {

                            $('.price').removeClass('text-danger');
                        }
                    }).catch(error => {
                        console.error('Error during request:', error);
                    });
        }
    }

    public render() {
        ($('.datepicker') as any).datetimepicker({
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'far fa-trash-alt',
                close: 'far fa-times-circle'
            },
        });

        return (
            <React.Fragment>
                <div className='card card-custom gutter-b '>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className='card-label'>Competitor Pricing</h3>

                        </div>
                        <div className='card-toolbar'>
                            <button type="submit" onClick={this.handleSubmit} className='btn btn-primary'>Save</button>

                        </div>
                    </div>
                    <div className="card-body">
                        <div className='mb-3'>
                            <div className='col-md-2'>
                                <div className='form-group'>
                                    <label >Effective Date</label>
                                    <input type="text" id="txtEffectiveDate" value={new Date().toLocaleString()} className="form-control datepicker" />
                                </div>
                            </div>
                        </div>

                        {this.renderTable()}
                        {this.renderPagination()}

                    </div>
                </div>
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        if (this.props.location === undefined) {
            this.props.requestCompetitorPrices(1);

        } else {
            const parsed = QueryString.parse(this.props.location.search)
            if (parsed != null && parsed != undefined) {

                const db = parseInt(parsed.db == null ? "1" : parsed.db.toString()) || 1;
                this.props.requestCompetitorPrices(db);
            }
        }
    }

    private renderTable() {
        const columns = [
            {
                name: " ",
                label: " ",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <div>
                                {
                                    this.props.CompetitorPrices[tableMeta.rowIndex].ProductID == undefined ? "" :
                                        <Link className='btn btn-icon btn-outline-success btn-circle btn-sm mr-2'
                                            to={{
                                                pathname: '/price_detail',
                                                search: `?Ret=CP&SiteID=${this.props.CompetitorPrices[tableMeta.rowIndex].SiteID}&ProductID=${this.props.CompetitorPrices[tableMeta.rowIndex].ProductID}&ProductName=${this.props.CompetitorPrices[tableMeta.rowIndex].ProductName}`,
                                            }}> <EditIcon />
                                        </Link>
                                }
                            </div>
                        )
                    }
                }
            }, {
                name: "SiteNumber",
                label: "Site Number",
                options: {
                    filter: true,
                    sort: true,

                },

            }, {
                name: "Num",
                label: "Num",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "ProductNumber",
                label: "Product Number",
                options: {
                    filter: true,
                    sort: false,

                }
            },
            {
                name: "ProductName",
                label: "Product Name",
                options: {
                    filter: true,
                    sort: false,
                }
            },

            {
                name: "dteLastPriceChange",
                label: "Last Price Change",
                options: {
                    filter: true,
                    sort: false,

                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <label>
                                {this.props.CompetitorPrices[tableMeta.rowIndex].dteLastPriceChange == undefined ? "" : format(new Date(this.props.CompetitorPrices[tableMeta.rowIndex].dteLastPriceChange), 'MM/dd/yyyy kk:mm')}
                            </label>

                        )
                    }
                }
            },

            {
                name: "CashPrice",
                label: "Cash Price",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <div>
                                {
                                    this.props.CompetitorPrices[tableMeta.rowIndex].ProductID == undefined ? "" :
                                        <input type="text" placeholder="cashPrice" name={tableMeta.rowIndex.toString()} defaultValue={this.props.CompetitorPrices[tableMeta.rowIndex].CashPrice} onBlur={e => this.onInputchange(e)} className="form-control w-50 price" />
                                }
                            </div>
                        )
                    }
                }
            },

            {
                name: "C1Price",
                label: "Comp1",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip dangerouslySetInnerHTML={{ __html: this.props.CompetitorPrices[tableMeta.rowIndex].C1Price }} title={this.props.CompetitorPrices[tableMeta.rowIndex].C1TT}>
                                <div />
                            </Tooltip>

                        )
                    }
                }

            },
            {
                name: "C2Price",
                label: "Comp2",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip dangerouslySetInnerHTML={{ __html: this.props.CompetitorPrices[tableMeta.rowIndex].C2Price }} title={this.props.CompetitorPrices[tableMeta.rowIndex].C2TT}>
                                <div />
                            </Tooltip>

                        )
                    }
                }

            },
            {
                name: "C3Price",
                label: "Comp3",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip dangerouslySetInnerHTML={{ __html: this.props.CompetitorPrices[tableMeta.rowIndex].C3Price }} title={this.props.CompetitorPrices[tableMeta.rowIndex].C3TT}>
                                <div />
                            </Tooltip>
                        )
                    }
                }

            },
            {
                name: "C4Price",
                label: "Comp4",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip dangerouslySetInnerHTML={{ __html: this.props.CompetitorPrices[tableMeta.rowIndex].C4Price }} title={this.props.CompetitorPrices[tableMeta.rowIndex].C4TT}>
                                <div />
                            </Tooltip>
                        )
                    }
                }

            },
            {
                name: "C5Price",
                label: "Comp5",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip dangerouslySetInnerHTML={{ __html: this.props.CompetitorPrices[tableMeta.rowIndex].C5Price }} title={this.props.CompetitorPrices[tableMeta.rowIndex].C5TT}>
                                <div />
                            </Tooltip>
                        )
                    }
                }

            },
            {
                name: "C6Price",
                label: "Comp6",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip dangerouslySetInnerHTML={{ __html: this.props.CompetitorPrices[tableMeta.rowIndex].C6Price }} title={this.props.CompetitorPrices[tableMeta.rowIndex].C6TT}>
                                <div />
                            </Tooltip>
                        )
                    }
                }

            },

        ];


        const options = {
            filter: true,
            fixedHeader: true,
            filterType: 'dropdown' as any,
            pagination: false,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),
            draggableColumns: {
                enabled: true,
            },
            onTableChange: (action: any, state: any) => {
                //console.log(action);
                //console.dir(state);
            },
            onGroupExpansionChange: (group: any, expanded: any) => {
                //console.dir(group);
                //console.dir(expanded);
            },
            grouping: { columnIndexes: [1] },
            tableBodyHeight: '68vh',

        };
        if (this != undefined && this.props.isLoading == false) {
            if (this.props.CompetitorPrices.length > 0) {
                return (

                    <MUIDataTable
                        title={"Prices"}
                        data={this.props.CompetitorPrices}
                        columns={columns}
                        options={options}
                    />
                );
            } else {
                const optionsnew = {
                    filter: true,
                    fixedHeader: true,
                    filterType: 'dropdown' as any,
                    pagination: false,
                    selectableRows: 'none' as any,
                    setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),
                    

                };
                return (
                    <>
                    <MUIDataTable
                        title={"Prices"}
                        data={this.props.CompetitorPrices}
                        columns={columns}
                            options={optionsnew}
                    />
                        
                        </>
                );
            }
           
        }
    }

    private renderPagination() {

        return (
            <div className="">
                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.competitorPrices, // Selects which state properties are merged into the component's props
    CompetitorPricesStore.actionCreators // Selects which action creators are merged into the component's props
)(CompetitorPriceData as any);
