import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CollectorPriceListState {
    isLoading: boolean;
    startIndex?: number;
    CollectorPriceLists: CollectorPriceList[];
}

export interface CollectorPriceList {
    SiteID: string;
    SiteNumber: string;
    ProductID: string;
    ProductNumber: string;
    ProductName: string;
    dteLastPriceChange: Date;
    CashPrice: string;
    CashPriceVar: string;
    CreditPrice: string;
    CreditPriceVar: string;
    FuelType: string;
    dteBatchEnd: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestListsAction {
    type: 'REQUEST_DATA';
    startIndex: number;
}

interface ReceiveListsAction {
    type: 'RECEIVE_DATA';
    startIndex: number;
    CollectorPriceLists: CollectorPriceList[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestListsAction | ReceiveListsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    requestLists: (startIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.collectorPriceList && appState.collectorPriceList.startIndex !== startIndex || appState.collectorPriceList !== undefined) {
            const requestOptions = {
                method: 'GET',

                headers: authHeader('ok') as string[][],
            };
            fetch(`${ApiUrl}collector/collectorprice?isActive=${startIndex}`, requestOptions)
                //.then(response => response.json())
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(
                    
                    data => {
                        
                        if (data == null || data == "[]") {
                            dispatch({ type: 'RECEIVE_DATA', startIndex: startIndex, CollectorPriceLists: [] });

                        }
                        else {
                            dispatch({ type: 'RECEIVE_DATA', startIndex: startIndex, CollectorPriceLists: JSON.parse(data) });
                        }
                        
                    })
                .catch((err) => {
                        
                        console.log(err);
                    })

            dispatch({ type: 'REQUEST_DATA', startIndex: startIndex });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CollectorPriceListState = { CollectorPriceLists: [], isLoading: false,startIndex:-1 };

export const reducer: Reducer<CollectorPriceListState> = (state: CollectorPriceListState | undefined, incomingAction: Action): CollectorPriceListState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                startIndex: action.startIndex,
                CollectorPriceLists: state.CollectorPriceLists,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.startIndex === state.startIndex) {
                return {
                    startIndex: action.startIndex,
                    CollectorPriceLists: action.CollectorPriceLists,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
