import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { authHeader } from '../components/AuthHeader';
import $ from 'jquery';
import { ApiUrl } from "../components/Constant";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SiteCompetitorPriceChkState {
    isLoading: boolean;
    SiteID: number;
    r: string;
    Msg: string;
    db: string;
    SiteCompetitorPriceChk: SiteCompetitorPriceChkItem;
}
export interface SiteCompetitorPriceChkItem {
    siteID: number;
    returnUrl: string;
    msg: string;
    title: string;
    db: string;
    lastPriceCheck: string;
    effectiveDate: string;
    siteList: Sites[];
    siteCompetitorPriceChk: SiteCompetitorPriceChk[];
}
export interface Sites {
    SiteID: number;
    SiteNumber: string;
}
export interface SiteCompetitorPriceChk {
    competitorID?: number;
    competitorName: string;
    compGradeID?: number;
    comp_Prod_Name: string;
    direction: string;
    mAX_dteCheck: string;
    price: string;
    state: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestSiteCompetitorPriceChkAction {
    type: 'REQUEST_DATA';
    SiteID: number;
    r: string;
    Msg: string;
    db: string;
}

interface ReceiveSiteCompetitorPriceChkAction {
    type: 'RECEIVE_DATA';
    SiteID: number;
    r: string;
    Msg: string;
    db: string;
    SiteCompetitorPriceChk: SiteCompetitorPriceChkItem;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestSiteCompetitorPriceChkAction | ReceiveSiteCompetitorPriceChkAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestSiteCompetitorPriceChk: (SID: number, db: string, Msg: string, r: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
       
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.siteCompetitorPriceChk && (SID !== appState.siteCompetitorPriceChk.SiteID || db !== appState.siteCompetitorPriceChk.db || Msg !== appState.siteCompetitorPriceChk.Msg || r !== appState.siteCompetitorPriceChk.r)) {
            
            const requestOptions = {
                method: 'GET',
                headers: authHeader('ok') as string[][]
            };

            fetch(`${ApiUrl}collector/SiteCompetitorPriceChkTT?SID=${SID}&Msg=${Msg}&db=${db}&r=${r}`, requestOptions)
                .then((response) => {
                    if (response.status == 401) {
                        $("#btnLogout").click();
                        return false;
                    }
                    else if (!response.ok) {
                        alert(response.text);
                        return false;
                    }
                    else
                        return response.json();
                })
                .then(

                    data => {
                        
                        if (data.sites ==null || data.sites == '') {
                            data.siteList = [];
                        } else {

                            data.siteList = JSON.parse(data.sites);
                        }
                        dispatch({ type: 'RECEIVE_DATA', SiteID: SID, db: db, Msg: Msg, r: r, SiteCompetitorPriceChk: data });
                    }).catch(error => {
                        
                        console.error('Error during request:', error);
                    });

            dispatch({ type: 'REQUEST_DATA', SiteID: SID, db: db, Msg: Msg, r:r });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SiteCompetitorPriceChkState = { SiteCompetitorPriceChk: { siteList: [], siteCompetitorPriceChk: [], siteID: -1, db: "", msg: "", returnUrl: "", title: "", lastPriceCheck: "", effectiveDate: "" }, SiteID: -1, db: "1", Msg: "", r: "SelfRef", isLoading: false };

export const reducer: Reducer<SiteCompetitorPriceChkState> = (state: SiteCompetitorPriceChkState | undefined, incomingAction: Action): SiteCompetitorPriceChkState => {
   
    if (state === undefined) {

        return unloadedState;
    }
    
    const action = incomingAction as KnownAction;
   
    switch (action.type) {
        case 'REQUEST_DATA':
            return {
                SiteID: action.SiteID,
                db: action.db,
                r: action.r,
                Msg: action.Msg,
                SiteCompetitorPriceChk: state.SiteCompetitorPriceChk,
                isLoading: true
            };
        case 'RECEIVE_DATA':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.SiteCompetitorPriceChk!=undefined) {
               
                return {
                    SiteID: action.SiteID,
                    db: action.db,
                    r: action.r,
                    Msg: action.Msg,
                    SiteCompetitorPriceChk: action.SiteCompetitorPriceChk,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
