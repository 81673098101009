import React, { useState, useEffect, useCallback, useRef, constructor } from "react"
//import Tags from "./tagify/react.tagify"
//@ts-ignore
import Tags from "@yaireo/tagify/dist/react.tagify"
import "@yaireo/tagify/dist/tagify.css"
/////////////////////////////////////////////////

// Tagify settings object
const baseTagifySettings = {
    blacklist: ["xxx", "yyy", "zzz"],
    //backspace: "edit",
    placeholder: "Email",
    dropdown: {
        enabled: 0 // a;ways show suggestions dropdown
    }
}

// this example callback is used for all Tagify events
const callback = (e: any) =>
    console.log(`%c ${e.type}: `, "background:#222; color:#bada55", e.detail)

// callbacks props (for this demo, the same callback reference is assigned to every event type)
const tagifyCallbacks = {
    // add: callback,
    // remove: callback,
    // input: callback,
    // edit: callback,
    // invalid: callback,
    // click: callback,
    // keydown: callback,
    // focus: callback,
    // blur: callback,
    // "edit:input": callback,
    // "edit:updated": callback,
    // "edit:start": callback,
    // "edit:keydown": callback,
    // "dropdown:show": callback,
    // "dropdown:hide": callback,
    // "dropdown:select": callback
}

// this is an example React component which implemenets Tagify within
// itself. This example is a bit elaborate, to demonstrate what's possible.
const CrazyTags = (props: any) => {
   // console.log(props.def.length)
   // console.log("hehehe" + props.setval)
 
    

   
    const tagifyRef = useRef() || undefined || null;
    // just a name I made up for allowing dynamic changes for tagify settings on this component
    const [tagifySettings, setTagifySettings] = useState([])
    const [tagifyProps, setTagifyProps] = useState({})

    // on component mount
    useEffect(() => {
        setTagifyProps({ loading: true })

            setTagifyProps((lastProps) => ({
                ...lastProps,
               
                showFilteredDropdown: "a",
                loading: false
            }))
        

        // simulate setting tags value via server request
            //setTagifyProps((lastProps) => ({
            //    ...lastProps, value: props.def
            //}))
        

        // simulated state change where some tags were deleted
        //setTimeout(
        //    () =>
        //        setTagifyProps((lastProps) => ({
        //            ...lastProps,
        //            value: [""],
        //            showFilteredDropdown: false
        //        })),
        //    5000
        //)
    }, [])

    // merged tagify settings (static & dynamic)
    const settings = {
        ...baseTagifySettings,
        ...tagifySettings,
        callbacks: tagifyCallbacks
    }

    //const onchange = useCallback((e) => {
    //    e.persist()
    //    console.log("changed:", e.target.value)
    //    if (props.setval != undefined) {
    //        props.setval.push(e.target.value)
    //    }
    //}, [])

    // access Tagify internal methods example:
    //const clearAll = () => {
    //    if (tagifyRef != undefined && ) {
    //        //tagifyRef.current && tagifyRef.current.removeAllTags()
    //    }
    //}

    return (
        <>
         
          
            <Tags
                tagifyRef={tagifyRef}
                settings={settings}
                value={props.value}
                autoFocus={true}
                {...tagifyProps}
                whitelist={props.def}
                placeholder={props.name}
                onChange={props.setval}
            />
           
        </>
    )
}

export default CrazyTags
