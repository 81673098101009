import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as CollectorPriceStore from '../store/CollectorPrice';
import $ from 'jquery';
import MUIDataTable from "../MUI";
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { compareAsc, format } from 'date-fns';
import Backdrop from '@material-ui/core/Backdrop';

// @ts-ignore
import { LoopCircleLoading } from 'react-loadingg';
// At runtime, Redux will merge together...
type ListProps =
    CollectorPriceStore.CollectorPriceListState // ... state we've requested from the Redux store
    & typeof CollectorPriceStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ Active: string }>; // ... plus incoming routing parameters


class ListData extends React.PureComponent<ListProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }
    constructor(props: Readonly<ListProps>) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);

    }
    onInputchange(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });

        this.props.requestLists(event.target.value);

    }
    // This method is called when the route parameters change
    public componentDidUpdate() {
        //this.ensureDataFetched();
    }

    public render() {


        return (
            <React.Fragment>
                <div className='card card-custom gutter-b'>
                    <div className='card-header'>
                        <h3 className='card-title'>
                            Prices
                             
                        </h3>
                        <div className="d-flex justify-content-center my-3">
                            <label className="radio mr-4"><input type="radio" name="startIndex" defaultChecked={true} value="0" onChange={e => this.onInputchange(e)} /> <span className="mr-2"></span>  Active</label>
                            <label className="radio"><input type="radio" name="startIndex" defaultChecked={this.props.startIndex == 1 ? true : false} value="1" onChange={e => this.onInputchange(e)} />   <span className="mr-2"></span>  All</label>
                        </div>
                        <div className='card-toolbar'>

                       
                        </div>
                    </div>
                    <div className="card-body">
                        
                        {this.renderTable()}
                        {this.renderPagination()}

                    </div>
                </div>

            </React.Fragment>
        );
    }

    private ensureDataFetched() {

        this.props.requestLists(0);
    }

    private renderTable() {
        const columns = [
            {
                name: " ",
                label: " ",
                options: {
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip title="Price Detail">
                                <Link className='btn btn-icon btn-outline-success btn-circle btn-sm mr-2' to={{
                                    pathname: '/price_detail',
                                    search: `?SiteID=${this.props.CollectorPriceLists[tableMeta.rowIndex].SiteID}&ProductID=${this.props.CollectorPriceLists[tableMeta.rowIndex].ProductID}&ProductName=${this.props.CollectorPriceLists[tableMeta.rowIndex].ProductName}`,
                                }}><EditIcon /></Link>
                            </Tooltip>

                        )
                    }
                }
            }, {
                name: "SiteNumber",
                label: "Site",


            }, {
                name: "ProductNumber",
                label: "ID",

            },
            {
                name: "ProductName",
                label: "Item",

            },
            {
                name: "dteLastPriceChange",
                label: "Last Price Change",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <label>
                                {this.props.CollectorPriceLists[tableMeta.rowIndex].dteLastPriceChange==undefined?"": format(new Date(this.props.CollectorPriceLists[tableMeta.rowIndex].dteLastPriceChange), 'MM/dd/yyyy kk:mm')}
                            </label>

                        )
                    }
                }
            },

            {
                name: "CashPrice",
                label: "Price",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <Tooltip dangerouslySetInnerHTML={{ __html: this.props.CollectorPriceLists[tableMeta.rowIndex].CashPrice }} title={this.props.CollectorPriceLists[tableMeta.rowIndex].CashPrice}>
                                <div />
                            </Tooltip>

                        )
                    }
                }
            },

            {
                name: "CashPriceVar",
                label: "Var",
                options: {
                    filter: true,
                    sort: false,

                }
            },

            {
                name: "CreditPrice",
                label: "Credit",
                options: {
                    filter: true,
                    sort: false,

                }
            },
            {
                name: "CreditPriceVar",
                label: "Credit Var",
                options: {
                    filter: true,
                    sort: false,

                }
            },
            {
                name: "FuelType",
                label: "Export Type",
                options: {
                    filter: true,
                    sort: false,

                }
            },
            {
                name: "dteBatchEnd",
                label: "Exported",
                options: {
                    filter: true,
                    sort: false,

                }
            },
            {
                name: "SiteNumber",
                label: "Store",
                options: {
                    filter: true,
                    sort: false,

                }
            },


        ];


        const options = {
            filter: true,
            filterType: 'dropdown' as any,
            pagination: false,
            selectableRows: 'none' as any,
            setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),
            draggableColumns: {
                enabled: true,
            },
            
            grouping: { columnIndexes: [1] },//[1, 2, 3]

        };
        if (this != undefined && this.props.isLoading == false) {
            if (this != undefined && this.props.isLoading == false) {
                if (this.props.CollectorPriceLists!=undefined && this.props.CollectorPriceLists.length > 0) {
                    return (

                        <MUIDataTable
                            title={""}
                            data={this.props.CollectorPriceLists}
                            columns={columns}
                            options={options}
                        />
                    );
                } else {
                    const optionsnew = {
                        filter: true,
                        fixedHeader: true,
                        filterType: 'dropdown' as any,
                        pagination: false,
                        selectableRows: 'none' as any,
                        setTableProps: () => ({ className: "table table-striped table-bordered table-hover" }),


                    };
                    return (
                        <>
                            <MUIDataTable
                                title={""}
                                data={this.props.CollectorPriceLists}
                                columns={columns}
                                options={optionsnew}
                            />

                        </>
                    );
                }


            }
            
        }
    }

    private renderPagination() {

        return (
            <div className="">

                {this.props.isLoading && <Backdrop open={true}> <LoopCircleLoading color='#fff' size='large' /> </Backdrop>}

            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.collectorPriceList, // Selects which state properties are merged into the component's props
    CollectorPriceStore.actionCreators // Selects which action creators are merged into the component's props
)(ListData as any);
